import React from 'react';

const ForecastIcon = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="20.000000pt" height="20.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill="#707070" stroke="none">
   <path d="M170 5009 c-14 -5 -35 -20 -47 -31 l-23 -21 0 -2429 0 -2428 2429 0
   2429 0 26 30 c30 36 41 85 32 148 -7 55 -28 90 -62 102 -16 6 -877 10 -2295
   10 l-2269 0 0 2269 c0 1418 -4 2279 -10 2295 -5 14 -21 32 -35 42 -30 20 -140
   28 -175 13z"/>
   <path d="M3355 4855 c-138 -30 -236 -86 -333 -190 -176 -187 -210 -461 -89
   -701 99 -196 311 -323 539 -324 107 0 168 15 273 65 151 72 266 206 318 370
   33 107 31 262 -6 370 -100 294 -409 475 -702 410z m271 -135 c272 -132 208
   -536 -93 -585 l-43 -7 0 -64 0 -64 -66 0 -66 0 4 90 c3 85 5 91 34 120 24 24
   43 33 91 40 124 17 193 86 193 193 -1 116 -92 196 -212 185 -78 -8 -145 -74
   -163 -160 -5 -27 -8 -28 -66 -28 l-61 0 7 33 c26 128 92 215 195 256 73 28
   176 25 246 -9z m-118 -768 c9 -6 12 -33 10 -98 l-3 -89 -89 -3 c-65 -2 -92 1
   -98 10 -12 21 -10 162 4 176 14 14 155 16 176 4z"/>
   <path d="M2234 4093 c-6 -10 -27 -43 -47 -74 l-36 -57 55 -36 55 -37 49 38
   c27 21 49 41 50 44 0 9 -101 139 -109 139 -3 0 -11 -8 -17 -17z"/>
   <path d="M2421 3790 c-28 -22 -51 -43 -51 -46 0 -12 92 -124 102 -124 13 0 98
   68 98 79 0 6 -77 111 -95 129 -1 1 -25 -16 -54 -38z"/>
   <path d="M1997 3726 c-26 -40 -47 -76 -47 -79 0 -7 98 -67 109 -67 7 0 101
   133 101 144 0 4 -106 76 -112 76 -3 0 -26 -33 -51 -74z"/>
   <path d="M2893 3724 c-29 -25 -53 -51 -53 -58 0 -6 20 -32 45 -56 l44 -44 55
   50 c29 27 54 53 55 59 1 9 -79 95 -89 95 -3 0 -29 -21 -57 -46z"/>
   <path d="M2665 3539 c-17 -12 -42 -34 -57 -47 -27 -25 -27 -25 -8 -51 10 -14
   32 -42 48 -61 l29 -35 57 48 c31 27 56 53 56 59 0 5 -21 32 -48 59 l-47 50
   -30 -22z"/>
   <path d="M4017 3542 c-22 -24 -21 -54 1 -82 16 -19 33 -24 123 -31 57 -5 110
   -10 118 -12 12 -2 -1597 -1037 -1612 -1037 -3 0 -137 153 -297 340 -160 187
   -295 340 -299 340 -4 0 -257 -182 -562 -405 -305 -222 -557 -404 -560 -405 -3
   0 -57 62 -120 137 -63 76 -165 198 -226 271 l-113 134 0 -109 0 -108 206 -245
   c113 -135 211 -251 218 -259 12 -11 101 51 569 393 306 223 560 406 566 406 5
   0 139 -151 297 -335 158 -184 290 -335 295 -335 4 0 394 247 866 549 472 301
   859 547 861 546 1 -1 -14 -46 -33 -99 -19 -53 -35 -110 -35 -125 0 -54 68 -82
   104 -42 21 23 156 396 158 434 2 57 -19 65 -222 82 -244 20 -283 20 -303 -3z"/>
   <path d="M1789 3408 l-46 -73 31 -23 c17 -13 43 -30 58 -38 27 -14 27 -14 73
   56 25 39 45 75 45 79 0 10 -92 71 -106 71 -5 0 -30 -33 -55 -72z"/>
   <path d="M1591 3101 c-24 -38 -43 -73 -43 -77 0 -4 25 -22 55 -40 l55 -32 46
   69 c25 38 46 71 46 74 0 5 -105 75 -112 75 -2 0 -23 -31 -47 -69z"/>
   <path d="M1403 2775 c-40 -69 -66 -122 -59 -118 42 25 246 168 246 173 0 5
   -103 70 -112 70 -2 0 -36 -56 -75 -125z"/>
   <path d="M1110 2045 l-44 -45 56 -56 56 -57 46 43 c25 23 46 45 46 49 0 13
   -92 111 -104 111 -6 0 -32 -20 -56 -45z"/>
   <path d="M1337 1808 l-48 -43 54 -60 54 -59 51 43 c29 23 52 46 52 50 0 8
   -103 111 -111 111 -2 0 -26 -19 -52 -42z"/>
   <path d="M3677 1774 c-221 -53 -404 -243 -452 -470 -67 -315 132 -636 445
   -720 87 -23 236 -23 314 0 115 34 186 76 272 161 58 58 90 100 116 152 175
   351 -8 769 -382 872 -88 25 -224 27 -313 5z m295 -126 c62 -26 140 -111 162
   -174 9 -26 16 -78 16 -114 0 -124 -79 -236 -197 -280 -30 -11 -65 -20 -78 -20
   -24 0 -25 -3 -25 -65 l0 -65 -70 0 -70 0 0 70 c0 125 32 163 153 180 104 15
   169 89 169 191 -2 185 -254 253 -348 93 -13 -22 -24 -53 -24 -67 0 -26 -2 -27
   -60 -27 -59 0 -60 0 -60 28 0 82 80 201 162 243 84 43 181 45 270 7z m-104
   -862 c2 -65 -1 -92 -10 -98 -7 -4 -45 -8 -85 -8 -93 0 -103 9 -103 100 0 104
   -3 101 104 98 l91 -3 3 -89z"/>
   <path d="M1562 1566 l-47 -44 50 -55 c28 -30 55 -55 60 -56 6 -1 30 19 54 43
   l44 45 -48 55 c-26 31 -52 56 -57 56 -5 0 -30 -20 -56 -44z"/>
   <path d="M2992 1369 c-36 -27 -50 -44 -44 -52 87 -118 102 -122 169 -50 l44
   47 -48 48 c-26 26 -52 48 -58 47 -5 0 -34 -18 -63 -40z"/>
   <path d="M1792 1327 c-23 -23 -42 -47 -42 -53 0 -5 22 -32 49 -58 l50 -49 39
   31 c72 57 72 56 11 118 -29 29 -56 54 -59 54 -3 0 -25 -19 -48 -43z"/>
   <path d="M2757 1199 c-31 -22 -57 -43 -57 -46 0 -12 72 -103 82 -103 8 0 66
   40 102 72 6 4 -6 31 -28 63 -21 30 -39 55 -40 55 0 0 -27 -18 -59 -41z"/>
   <path d="M2013 1087 l-47 -44 49 -57 c27 -31 54 -56 60 -56 6 0 31 20 55 45
   l43 44 -49 53 c-27 29 -52 54 -56 56 -4 2 -29 -16 -55 -41z"/>
   <path d="M2514 1023 c-27 -20 -51 -38 -53 -40 -5 -3 69 -113 76 -113 2 0 29
   18 59 41 34 25 52 45 48 52 -24 41 -66 97 -73 97 -4 0 -30 -17 -57 -37z"/>
   <path d="M2236 841 l-46 -47 53 -51 52 -51 57 43 58 42 -31 46 c-53 80 -79 83
   -143 18z"/>
   </g>
   </svg>


  )
}

export default ForecastIcon;