import { createSlice } from "@reduxjs/toolkit";
export const loadersSlice = createSlice({
    name:'loaders',
    initialState:{
                  productLoader: false},
    reducers:{
         updateProductloader : (state,action) => {
           state.productLoader = action.payload
      }
    }
})

export const {updateProductloader} = loadersSlice.actions
export default loadersSlice.reducer