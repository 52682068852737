import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ProductList from './pages/ProductList';
import Settings from './pages/Settings';
import PresetSales from './pages/Preset';
import SalesProfit from './pages/ProfitPreset'
import Sales from './components/Sales/Sales';
import SimpleReactFileUpload from 'pages/FileUpload'
import BudgetUpload from './pages/UploadBudget';
import SuspiciousTable from './components/dashboard/SuspiciousTrans';
import ReportPage from './components/dashboard/ReportPage';
import SeasonalProductSales from './components/product/SeasonalProductSales';
import SeasonalForecast from './components/Forecast/SeasonalForcast';
import FileBrowse from './components/FileManager/FileManager';
import VoidTransactions from 'components/dashboard/Void';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'salesPreset', element: <PresetSales pageTitle='Sales'/> },
      { path: 'profitPreset', element: <SalesProfit/> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: 'fileUpload', element: <SimpleReactFileUpload /> },
      { path: 'newBudget', element: <BudgetUpload/> },
      { path: 'susTransactions', element: <SuspiciousTable/> },
      { path: 'voidTransactions', element: <VoidTransactions/> },
      { path: 'seasonalProductSales', element: <SeasonalProductSales/> },
      { path: 'seasonalForecast', element: <SeasonalForecast/> },
      { path: 'reports', element: <ReportPage/> },
      { path: 'downloads', element: <FileBrowse/> },

      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: 'sales', element: <Sales/>},
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
