import React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import ReportTable from "../table/ReportTable";

import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { usePromiseTracker } from "react-promise-tracker";
import {
  Button ,
  Box,
  Container,
  Grid,
  TextField
  } from '@material-ui/core'

import axios from 'axios';
import qs from 'qs';
import StoresDropDown from "../General/StoresDropDown";
import { trackPromise } from 'react-promise-tracker';
import {Bars} from 'react-loader-spinner';
import Backdrop from '@material-ui/core/Backdrop';
import RadioButton from "../General/RadioSelect";
import DistrictDropDown from "../General/DistrictsDropDown";

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    input: {
      width: 160,
      height: 40
    },
    stores: {
      width: 400,
      height: 40
    }
  }));

function ReportPage() {
  const [startDate,setStartDate] = React.useState(new Date())
  const [endDate,setEndDate] = React.useState(new Date())
  const [result,setResult] = React.useState([])
  const [storeselected, setStoreselected] = useState([])
  const [allStoresSelected, setAllStoresSelected] = useState(false);
  const [storeEnabled, setStoreEnabled] = useState(true);
  const [districtEnabled, setDistrictEnabled] = useState(false);

  const handleStartDateChange =(date) =>{
    setStartDate(date)
    }

   const handleEndDateChange =(date) =>{
      setEndDate(date)
      }

  const sendDataToParent =(storeselected,isAllStoresSelected) =>{
   setStoreselected(storeselected)
   setAllStoresSelected(isAllStoresSelected)
  }

  const RadioButtonReturn =(storeEnabled,districtEnabled,provinceEnabled) =>{
    setStoreEnabled(storeEnabled)
    setDistrictEnabled(districtEnabled)
   }

   const districtReturn =(storeEnabled,districtEnabled,provinceEnabled) =>{
    setStoreEnabled(storeEnabled)
    setDistrictEnabled(districtEnabled)
   }

   const { promiseInProgress } = usePromiseTracker();
  const handleSubmit = (event) =>{
    event.preventDefault();
    trackPromise(
        axios.get("/api/reports/sku_81_weight_300",
        {
          params: {
            startDate:startDate,
            endDate:endDate,
            stores:storeselected,
            allStores: allStoresSelected
          },
          paramsSerializer: params => {
            return qs.stringify(params,{ arrayFormat: 'repeat' })
          }
        })
        .then(res => {
          setResult(res.data);
        })
      .catch((error) => console.log(error)));

  }

  const classes = useStyles();
  console.log(result);
  return (
    <>
     {
      promiseInProgress &&
      <Backdrop style={{zIndex: 2}} open={true} >
      <Bars type="BallTriangle" color='#862D23' height="100" width="100" />
      </Backdrop>
      }
    <form noValidate onSubmit={handleSubmit}>
    <Box pt={3} pb={5} pl={2}>
    <Grid
    container
    spacing={1}
    >

        <Grid
            item
            md={3}
            xs={3}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker  variant="inline" inputVariant="outlined"
          label = "Start Date"
          value={startDate}
          onChange={handleStartDateChange}

          renderInput={(params) => <TextField {...params} />}
          >
          </DatePicker>

        </LocalizationProvider>
        </Grid>
            <Grid
              item
              md={3}
              xs={3}
            >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker  variant="inline" inputVariant="outlined"
          label = "End Date"
          value={endDate}
          onChange={handleEndDateChange}
          renderInput={(params) => <TextField {...params} />}
          >
          </DatePicker>

        </LocalizationProvider>

              </Grid>
        <Grid
          item
          md={3}
          xs={3}
        >
        <RadioButton RadioButtonReturn ={ RadioButtonReturn} />
        </Grid>

        <Grid
          item
          md={3}
          xs={3}
        >
          <Box sx={{pt:3} }>
          <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={false}
          >
          Submit
         </Button>
         </Box>
        </Grid>

        <Grid
          item
          md={6}
          xs={6}
        >
            <Box sx={{pt:2} }>
            <StoresDropDown sendDataToParent={sendDataToParent}/>
            </Box>
        </Grid>
        <Grid
          item
          md={3}
          xs={3}
        >
          <DistrictDropDown districtReturn={districtReturn}/>
          </Grid>

              </Grid>

              </Box>
        </form>
             <Grid
            item
            md={12}
            xs={12}
             >
            <ReportTable result={result}
            />

      </Grid>
    </>
  );
}

export default ReportPage;