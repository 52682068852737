import React from 'react';

const Icon = () => {
  return (

    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
     width="20.000000pt" height="20.000000pt" viewBox="0 0 512.000000 512.000000"
     preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill="#707070" stroke="none">
    <path d="M2080 5113 c-104 -17 -217 -79 -300 -162 -75 -76 -116 -151 -145
    -265 -19 -74 -22 -80 -50 -87 -17 -4 -73 -17 -125 -29 -205 -48 -457 -146
    -567 -221 -124 -85 -202 -183 -229 -289 -59 -233 121 -439 510 -585 101 -37
    362 -108 447 -120 l46 -7 7 -51 c14 -119 27 -104 -161 -195 -93 -44 -271 -130
    -396 -191 l-228 -111 152 -177 c137 -158 151 -178 134 -187 -253 -147 -411
    -345 -486 -611 -23 -80 -23 -80 -26 -952 l-4 -873 1901 0 1901 0 -4 868 c-3
    937 -1 899 -57 1050 -76 204 -231 388 -412 491 -32 18 -58 35 -58 37 0 3 68
    84 150 180 l151 174 -228 111 c-125 61 -303 147 -395 191 -189 91 -176 76
    -162 195 l7 51 46 7 c85 12 346 83 447 120 389 146 569 352 510 585 -27 106
    -105 204 -229 289 -110 75 -362 173 -567 221 -52 12 -108 25 -125 29 -28 7
    -31 13 -50 87 -29 114 -70 189 -145 265 -99 100 -213 154 -347 166 l-71 6
    -181 -92 -181 -92 -178 91 c-185 94 -206 99 -302 83z m693 -403 l212 108 48
    -15 c63 -18 128 -83 150 -148 13 -38 17 -86 17 -215 l0 -166 -102 -27 c-142
    -37 -185 -46 -318 -63 -247 -31 -478 -11 -757 63 l-103 27 0 166 c0 129 4 177
    17 215 22 65 87 130 150 148 l48 15 213 -108 212 -108 213 108z m-1153 -528
    l0 -118 153 -52 c296 -101 497 -135 787 -135 290 0 491 34 788 135 l152 52 0
    119 c0 108 2 118 18 113 9 -3 55 -14 102 -25 120 -28 281 -86 375 -134 89 -47
    175 -122 175 -154 0 -74 -195 -189 -450 -266 -728 -220 -1935 -185 -2521 72
    -146 64 -249 144 -249 194 0 55 162 162 345 227 73 26 288 86 318 89 4 1 7
    -52 7 -117z m665 -903 c209 -12 533 -7 734 12 62 6 116 8 119 5 3 -3 0 -70 -7
    -148 -19 -222 -66 -336 -186 -453 -92 -90 -254 -155 -385 -155 -67 0 -190 30
    -252 61 -120 60 -227 178 -274 304 -24 66 -54 260 -54 359 l0 39 53 -6 c29 -4
    142 -12 252 -18z m-544 -446 c40 -125 136 -271 240 -366 109 -99 221 -157 417
    -213 9 -3 12 -173 12 -811 l0 -808 -295 444 c-162 245 -292 448 -288 452 5 3
    85 55 178 114 94 59 171 108 173 110 1 1 -180 214 -403 474 l-405 472 172 84
    c95 46 176 84 180 84 3 1 12 -16 19 -36z m1833 -48 l176 -84 -405 -472 -405
    -472 88 -56 c48 -31 129 -83 179 -115 75 -48 91 -61 84 -75 -5 -9 -138 -209
    -295 -446 l-286 -430 0 808 c0 638 3 808 13 811 195 56 307 114 416 213 104
    95 200 241 240 366 7 20 14 37 16 37 1 0 82 -38 179 -85z m-2011 -770 c87
    -102 156 -189 153 -194 -3 -5 -75 -53 -160 -107 -85 -54 -152 -103 -148 -109
    3 -5 130 -197 281 -425 151 -228 343 -520 427 -647 l154 -233 -656 0 -655 0 4
    703 c3 780 0 738 74 887 49 101 168 220 272 273 40 20 78 37 85 37 6 0 82 -83
    169 -185z m2248 148 c101 -50 219 -169 272 -273 74 -147 71 -106 74 -887 l4
    -703 -655 0 -655 0 173 263 c95 144 287 435 428 647 140 212 257 390 260 395
    4 6 -61 54 -148 109 -85 54 -157 102 -160 107 -6 11 306 379 322 379 6 0 45
    -16 85 -37z"/>
    </g>
    </svg>

  )
}

export default Icon;