import React,{ useState } from 'react'
import axios, { post } from 'axios';
import {
    Box,
    Typography,
    Grid,
    Container,
    Button
  } from '@material-ui/core';

import EnhancedTable from './BudgetTable';


import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import BudgetTable from './BudgetTable'


const  BudgetUpload =()=> {

  const [uploadFile, setUploadFile] = React.useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [alert, setAlert] = useState(false);
  const [uploadResponse, setUploadResponse] = React.useState();
  const [budget, setBudget] = React.useState();
  const submitForm = (event) => {

    setIsLoaded(true)

    event.preventDefault();

    const dataArray = new FormData();

    dataArray.append("uploadFile", uploadFile);

    axios
      .post("/api/importStoresBudget/", dataArray, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        setUploadResponse('File uploaded successfully')
        setIsLoaded(false)
        setAlert(true)
        })
      .catch((error) => {
        setUploadResponse('Error in uploading file');
        setIsLoaded(false)
        setAlert(true)
      });
  };



  const onChange1 = (e) => {
    setUploadFile(e.target.files[0])
  }
    return (

      <Container maxWidth={false}>

      <form onSubmit={submitForm}>
        <Box pl={3} pt={3} >
        <Typography
            color="primary"
            gutterBottom
            variant="h6"
            >
            Budget file upload
          </Typography>
        <Box pt={4}>
        <Grid
              item
              md={3}
              xs={3}
            >
        <input type="file" onChange={onChange1} />
        <button type="submit" variant="contained"
            color="primary">Upload</button>
             </Grid>
            </Box>



        </Box>
        <Box pt={4} pl={2}>
        <Grid
              item
              md={3}
              xs={3}
            >
        {isLoaded && <CircularProgress color="secondary" /> }
        {alert && <Alert severity="info"> {uploadResponse} </Alert>}
        </Grid>
        </Box>
    </form>
</Container>

   )
  }
export default BudgetUpload