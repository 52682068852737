import React from 'react'

import {    
    Container   
  } from '@material-ui/core';
import BudgetTable from './BudgetTable'

const  SimpleReactFileUpload =()=> {  
    return (
      
      <Container maxWidth={false}>    
    
       <BudgetTable />  
       
      </Container>
      
   )
  }
export default SimpleReactFileUpload