import { createSlice } from "@reduxjs/toolkit";

export const DialogSlice = createSlice({
    name: "dialogEnable",
    initialState: {
      enabled: false
    },
    reducers: {
      dialogShow: state => {
        state.enabled = true;
      },
      dialogClose: state => {
        state.enabled = false;
      }
    }
  });

  export const {dialogShow,dialogClose} = DialogSlice.actions;
  export default DialogSlice.reducer;