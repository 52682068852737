import React from 'react'
import { Bar} from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";


import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme,
  Grid
} from '@material-ui/core';

import axios from 'axios';
import { useEffect,useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import {Bars} from 'react-loader-spinner';

const SalesByProvince = ({dayShow,weekShow,monthShow, threeMonthsShow}) => {
  const theme = useTheme();
  const [result,setResult] = useState({'sales':{'labels':[],'datasets':[]}})
  const [percentage,setPercentage] = useState( new Array(6).fill({'MB':0,'ON':0,'QC':0,'NL':0,
                                                 'NS':0,'SK':0,'NB':0}))


  useEffect(() => {
    trackPromise(
    axios.get("/api/provinceSales/periodic_province_sales",
    {
      params: {
        daily:dayShow,
        weekly:weekShow ,
        monthly:monthShow,
        threeMonths: threeMonthsShow
      }})
    .then(res => {
      setResult(res.data);
      setPercentage(res.data.percentage)
    }));
    },[weekShow,monthShow,threeMonthsShow]);


  const options = {
    animation: false,
    cutoutPercentage: 70,
    layout: { padding: 0 },
    legend: {
      display: true
    },
    maintainAspectRatio: false,
    responsive: true

  };

  const devices = [
    {
      title: 'MB',
      value2019: percentage[0].MB,
      value2020: percentage[1].MB,
      value2021: percentage[2].MB,
      value2022: percentage[3].MB,
      value2023: percentage[4].MB,
      value2024: percentage[5].MB,

    },
    {
      title: 'NB',
      value2019: percentage[0].NB,
      value2020: percentage[1].NB,
      value2021: percentage[2].NB,
      value2022: percentage[3].NB,
      value2023: percentage[4].NB,
      value2024: percentage[5].NB,

    },
    {
      title: 'NL',
      value2019: percentage[0].NL,
      value2020: percentage[1].NL,
      value2021: percentage[2].NL,
      value2022: percentage[3].NL,
      value2023: percentage[4].NL,
      value2024: percentage[5].NL,

    },
    {
      title: 'NS',
      value2019: percentage[0].NS,
      value2020: percentage[1].NS,
      value2021: percentage[2].NS,
      value2022: percentage[3].NS,
      value2023: percentage[4].NS,
      value2024: percentage[5].NS,

    },
    {
      title: 'ON',
      value2019: percentage[0].ON,
      value2020: percentage[1].ON,
      value2021: percentage[2].ON,
      value2022: percentage[3].ON,
      value2023: percentage[4].ON,
      value2024: percentage[5].ON,

    },
    {
      title: 'QC',
      value2019: percentage[0].QC,
      value2020: percentage[1].QC,
      value2021: percentage[2].QC,
      value2022: percentage[3].QC,
      value2023: percentage[4].QC,
      value2024: percentage[5].QC,
    },
    {
      title: 'SK',
      value2019: percentage[0].SK,
      value2020: percentage[1].SK,
      value2021: percentage[2].SK,
      value2022: percentage[3].SK,
      value2023: percentage[4].SK,
      value2024: percentage[5].SK,

    }
  ];
  const { promiseInProgress } = usePromiseTracker();

  return (
    <Card >
      <CardHeader title="Sales by province" ></CardHeader>

      <Divider />

      <CardContent>

        <Box
          sx={{
            height: 500,
            position: 'relative'
          }}
        >
           {
          promiseInProgress &&
          <Backdrop style={{zIndex: 2}} open >
          <Bars type="Watch" color='#862D23' height="100" width="100" />
         </Backdrop>
          }
          <Bar
            data={result.sales}
            options={options}

          />
        </Box>

        <Box
              sx={{
                p: 1,
                textAlign: 'left'
              }}>

        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'

          }}
        >
            {devices.map(({
            title,
            value2019,
            value2020,
            value2021,
            value2022,
            value2023,
            value2024
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >

              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                color = '#0D830F'
                variant="h4"
              >
                {value2019}
                %
              </Typography>
              <Typography
                color='#DD0815'
                variant="h4"
              >
                {value2020}
                %
              </Typography>
              <Typography
                color='blue'
                variant="h4"
              >
                {value2021}
                %
              </Typography>
              <Typography
                color='#86086D '
                variant="h4"
              >
                {value2022}
                %
              </Typography>

              <Typography
                color='#eead0e'
                variant="h4"
              >
                {value2023}
                %
              </Typography>

              <Typography
                color='#15EDF4'
                variant="h4"
              >
                {value2024}
                %
              </Typography>
            </Box>
          ))}
        </Box>

      </CardContent>
    </Card>
  );
};

export default SalesByProvince;
