import React from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditableTable from "../table/EditableTable";
import NumberOfStores from "../Card/GeneralCard";
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import {Bars} from 'react-loader-spinner';
import Backdrop from '@material-ui/core/Backdrop';
import ToExcel from "icons/Toexcel";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddSku from "./AddSku";
import { useDispatch } from "react-redux";
import {enable, disable} from "redux/modal";
import { useSelector } from "react-redux";
import {dialogClose} from 'redux/dialog'

import {
  Button ,
  Box,
  Grid,
  TextField,
  Typography,
  CardContent,
  Card
  } from '@material-ui/core'
  import { useFormik } from 'formik';
  import * as yup from 'yup';
  import axios from 'axios'

  const validationSchema = yup.object({
    objectiveSales: yup
      .number('Enter a number')
      .required('objective sales is required'),
    salesPercent: yup
      .number().
      positive('Enter a positive number').
      max(100,'Enter a number between 1 , 100')
      .required('Sales percent is required'),
  });

  const calculateSeasons =() =>{

    const today = new Date()
    var season =[String(new Date().getFullYear())+'-'+'Saint Valentine',
                 String(new Date().getFullYear())+'-'+'Easter',
                 String(new Date().getFullYear())+'-'+'Summer',
                 String(new Date().getFullYear())+'-'+'Halloween',
                 String(new Date().getFullYear())+'-'+'Christmas',
                 String(new Date().getFullYear()+1)+'-'+'Saint Valentine',
                 String(new Date().getFullYear()+1)+'-'+'Easter',
                 String(new Date().getFullYear()+1)+'-'+'Summer',
                 String(new Date().getFullYear()+1)+'-'+'Halloween',
                 String(new Date().getFullYear()+1)+'-'+'Christmas',]

    if (today > new Date(new Date().getFullYear(),'02','13')){
       season.shift()
    }
    if (today > new Date(new Date().getFullYear(),'04','9')){
      season.shift()
     }
     if (today > new Date(new Date (new Date().getFullYear(),'05','01'))){
      season.shift()
     }
     if (today > new Date(new Date (new Date().getFullYear(),'10','01'))) {
      season.shift()
     }
     if (today > new Date(new Date (new Date().getFullYear(),'12','01'))){
      season.shift()
     }
     return season

  }

  const Seasons = calculateSeasons()
  {/* const Seasons = ['2023-Saint Valentine','2023-Easter','2023-Summer',
'2023-Halloween','2023-Christmas'] */}

  const head =['','Id','Sku','Description En','Description Fr','Sold(#)', 'Sold($)','SR %','Forecast(#)','Forecast($)',
               'SR %','Forecast(#)','Forecast($)','Edit']
  const contentText = ['Before-Critical-Date','After-Critical-Date']



function SeasonalForecast() {
  const [result,setResult] = React.useState({'skus_before_critical_date':[{'store_data':[]}],
                                             'skus_after_critical_date':[{'store_data':[]}],
                                            'past_data_used': false})

  const [toExcelDisabled,setToExcelDisabled] = React.useState(true)
  const [saveDisabled,setSaveDisabled] = React.useState(true)
  const [dialogOpen,setDialogOpen] = React.useState(false)
  const [deleteDialogOpen,setDeleteDialogOpen] = React.useState(false)
  const [deleteId,setDeleteId] = React.useState(null)
  const [infoOpen, setInfoOpen] = React.useState(false)

  const dispatcher = useDispatch()
  const confirmation = useSelector(state => state.dialogEnable.enabled)
  const period = useSelector(state => state.dropDown)


  React.useEffect(() => {
    if (result.past_data_used){
      setInfoOpen(true)
    }
  },
  [result]);

  const downloadExcel = (event, permanentSaved) => {
    event.preventDefault();
    axios.get('/api/productSales/download_excel',
    {   params: {
      beforeOrAfter:period.duration.beforeOrAfter,
      season:season,
      permanentSaved:permanentSaved
    },
       responseType: 'arraybuffer',
    }
).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    const contentDisposition = response.headers['content-disposition'];
    link.href = url;
    link.setAttribute('download', contentDisposition);
    document.body.appendChild(link);
    link.click();
    setDialogOpen(false)
})
    .catch((error) => console.log(error));
}

  const rowToUpdate =(row) =>{
    trackPromise(
      axios.post("/api/productSales/seasonal_sku_sales_ratio_update/",
      {
        row: row,
        season:season,
        beforeOrAfter:period.duration.beforeOrAfter
      })
      .then(res => {
        setResult(res.data)
      }));
    }

    const deleteRow =() => {
      trackPromise(
        axios.post("/api/productSales/seasonal_sku_delete/",
        {
          total: result,
          row_id: deleteId,
          season:season,
          beforeOrAfter:period.duration.beforeOrAfter
        })
        .then(res => {
          setResult(res.data)
        }));
    }

    const rowToDelete =(row_id) =>{
       setDeleteId(row_id)
       setDeleteDialogOpen(true)
      }

    const storeToUpdate =(row,row_id,storeRow) =>{
      trackPromise(
        axios.post("/api/productSales/seasonal_store_sales_count_update/",
        {
          total: result,
          row_id: row_id,
          storeRow:storeRow,
          season:season,
          beforeOrAfter:period.duration.beforeOrAfter
        })
        .then(res => {
          if (period.duration.beforeOrAfter == 'Before-Critical-Date'){
                const received = [...res.data.skus_before_critical_date]
                const secondPart = [...res.data.skus_after_critical_date]

                const finalResult = {'skus_before_critical_date':received,'skus_after_critical_date':secondPart}
                setResult(finalResult)
          }
          else{
                const received = [...result.skus_after_critical_date]
                const secondPart = [...result.skus_before_critical_date]

                const finalResult = {'skus_before_critical_date':secondPart,'skus_after_critical_date':received}
                setResult(finalResult)
          }

        }));
      }

      const save =() =>{
        trackPromise(
          axios.post("/api/productSales/seasonal_sku_sales_manual_save/",
          {
            total: result,
            beforeOrAfter:period.duration.beforeOrAfter,
            season:season
          })
          .then(res => {
            setSaveDisabled(true)
            setDialogOpen(true)
        }).catch(err => {
            console.log(err);
        }));
        }

  const { promiseInProgress } = usePromiseTracker();
  console.log(result)
  const formik = useFormik({
    initialValues: {
      objectiveSales: '',
      salesPercent: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      trackPromise(
      axios.get("/api/productSales/seasonal_product_forecast",
      {
         params: {
             season:season,
             objectiveSales: values.objectiveSales,
             percentage:values.salesPercent
           }
      })
      .then(res => {
        setResult(res.data);
      }));
      setToExcelDisabled(false)
      setSaveDisabled(false)
    },
  });
  const [season, setSeason] = React.useState('2023-Saint Valentine');
  const handleChange = (event,item) => {
    setSeason(item);
  };

    const renderForecast = () => {
      if (result.skus_before_critical_date.length >1 && period.duration.beforeOrAfter == 'Before-Critical-Date'){
        return <Box sx={{pt:6,pl:1}}>
        <NumberOfStores title ='Seasonal Products Forecast'  contentText={contentText}/>
        <Card>
          <CardContent>
        <EditableTable headerCells={head}  data={result.skus_before_critical_date} rowToUpdate={rowToUpdate}
          storeToUpdate={storeToUpdate} rowToDelete={rowToDelete} />
        </CardContent>
        </Card>
        </Box>
      }

      else if (result.skus_after_critical_date.length >1 && period.duration.beforeOrAfter == 'After-Critical-Date'){
        return <Box sx={{pt:6,pl:1}}>
        <NumberOfStores title ='Seasonal Products Forecast'  contentText={contentText}/>
        <EditableTable headerCells={head}  data={result.skus_after_critical_date} rowToUpdate={rowToUpdate}
                storeToUpdate={storeToUpdate} rowToDelete={rowToDelete} />
        </Box>
    }
  }

  const handleClose =() =>{
    setDialogOpen(false)
  }

  const handle2019Close =() =>{
    setInfoOpen(false)
  }

  const handleCloseDelete = () =>{
    setDeleteDialogOpen(false)
  }

  const handleDelete = () =>{
    setDeleteDialogOpen(false)
    deleteRow()
  }

  const ShowSkuAdd = () =>{
    dispatcher(enable())
  }
 const modalEnabled = useSelector(state => state.modalEnable)

 const handleCloseConfirmation = () =>{
  dispatcher(dialogClose())
  dispatcher(disable())
}

  return (
      <>
    {
      promiseInProgress &&
      <Backdrop style={{zIndex: 2}} open={true} >
      <Bars type="Watch" color='#862D23' height="100" width="100" />
      </Backdrop>
      }
    <Box pl={6} pt={4}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid
          item
          md={3}
          xs={3}
        >
        <Typography variant="h6">
        Season
        </Typography>
        </Grid>
        <Grid
          item
          md={2}
          xs={2}
        >
        <Typography  variant="h6">
        Objective Sales
        </Typography>
        </Grid>
        <Grid
          item
          md={3}
          xs={3}
        >
        <Typography  variant="h6">
        Before Season Sales %
        </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>

        <Grid
          item
          md={3}
          xs={3}
        >
            <FormControl sx={{ pt:2, minWidth: 250 }} size="small">
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={season}
                    label="Season"
                >
                {Seasons.map((item,index) =>
                <MenuItem key={index} value={item} onClick={event=>handleChange(event,item)}> {item} </MenuItem>)}
                </Select>
            </FormControl>
        </Grid>

        <Grid
          item
          md={2}
          xs={2}
        >
            <TextField sx={{ pt:2, maxWidth: 150 }}
            id="tb-objective"
            name="objectiveSales"
            size="small"
            value={formik.values.objectiveSales}
            onChange={formik.handleChange}
            error={formik.touched.objectiveSales && Boolean(formik.errors.objectiveSales)}
            helperText={formik.touched.objectiveSales && formik.errors.objectiveSales}
            />
          </Grid>

            <Grid
            item
            md={2}
            xs={2}
          >
              <TextField sx={{ pt:2, maxWidth: 150 }}
              id="tb-sales-percentage"
              name="salesPercent"
              size="small"
              value={formik.values.salesPercent}
              onChange={formik.handleChange}
              error={formik.touched.salesPercent && Boolean(formik.errors.salesPercent)}
              helperText={formik.touched.salesPercent && formik.errors.salesPercent}
              />
            </Grid>

            <Grid
            item
            md={1}
            xs={1}
          >
            <Button
                sx={{ mt:2}}
                variant="contained"
                color="primary"
                type="submit"
                disabled={false}
              >
                Submit
            </Button>
            </Grid>
            <Grid
            item
            md={1}
            xs={1}
          >
            <Button
                sx={{ mt:2}}
                variant="contained"
                startIcon={<ToExcel />}
                disabled={toExcelDisabled}
                onClick={(event) =>downloadExcel(event,false)}
              >
            </Button>
            </Grid>
            <Grid
            item
            md={1}
            xs={1}
          >

            <Button
                sx={{ mt:2}}
                size="medium"
                variant="contained"
                color="primary"
                disabled={saveDisabled}
                onClick={save}
              >
               Save
            </Button>
          </Grid>
          <Grid
            item
            md={1}
            xs={1}
          >

            <Button
                sx={{ mt:2}}
                size="medium"
                variant="contained"
                color="primary"
                disabled={false}
                onClick={ShowSkuAdd}
              >
               Edit SKUs
            </Button>
          </Grid>
        </Grid>
        <Dialog
           PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "500px!important",
            },
          }}
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="save-dialog-title"
          aria-describedby="save-dialog-description"
    >
        <DialogTitle id="saved alert">
          {"save confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="saved-dialog-description">
                Saved with sucess
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary"  variant= "contained" autoFocus> Ok </Button>
              <Button onClick={(event) =>downloadExcel(event, true)}>
                Download
              </Button>
            </DialogActions>
         </Dialog>


         <Dialog
        open={infoOpen}
        onClose={handle2019Close}
        aria-labelledby="alert-2019-data-used"
        aria-describedby="alert-2019-data-used"
      >
        <DialogTitle id="alert-2019-data">
          {"2019 Data used"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            That season sales on 2023 is not completed, sales data of year 2019 has been used
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handle2019Close} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

         <Dialog
           PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "500px!important",
            },
          }}
          open={deleteDialogOpen}
          onClose={handleCloseDelete}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
    >
        <DialogTitle id="delete alert">
          {"delete confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-dialog-description">
                Are you sure to delete this sku from the season?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDelete} color="primary"  variant= "contained" autoFocus> Ok </Button>
              <Button onClick={handleCloseDelete}>
                cancel
              </Button>
            </DialogActions>
         </Dialog>
        { modalEnabled.enabled && <AddSku Season={season} /> }
        <Grid  item xs={12}>
    <Dialog
        open={confirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="confirm"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Confirm"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Season skus modification successful
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation}>OK</Button>
        </DialogActions>
      </Dialog>
      </Grid>

    </form>
    { renderForecast()}

  </Box>
</>
  );
}

export default SeasonalForecast;