import { createSlice } from "@reduxjs/toolkit";

export const multipleSelectSlice = createSlice({
    name: "multipleSelect",
    initialState: {
      selectedItems: []
    },
    reducers: {
      setItems: (state, action) => {
        state.selectedItems = action.payload
      },
    }
  });

  export const {setItems} = multipleSelectSlice.actions;
  export default multipleSelectSlice.reducer;