import React, {useState,useEffect} from 'react';
import {Grid,
  Box,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Container,
  CardContent,
Card} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Input from '@material-ui/core/Input';
import axios from 'axios';
import qs from 'qs';
import {  Line } from 'react-chartjs-2';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme();

const useStyle = makeStyles(theme=>({

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 200,

  },
  DatePicker: {
    width: "80%",
    minWidth:50,
    marginRight:'auto'
    },

    selectEmpty: {
      marginTop: theme.spacing(2),
      minWidth:100
    },

    selectMultiple: {
      marginTop: theme.spacing(2),
      minWidth:300
    },

  }))



const ProvinceSales = ({startDate,endDate,province,provinceDefaultStore,getProvinceData})=>{

  const classes = useStyle();
  const [provinceStores,setProvinceStores]= useState([])
  const [transactions,setTransactions]= useState([])
  const [provinceSales,setProvinceSales]= useState([])
  const [storeSales,setStoreSales]= useState([])
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [storePaymentMethod, setStorePaymentMethod] = useState([]);
  const paymentTypes =  ['Cash',
                         'Debit',
                         'Visa',
                         'Master',
                         'American Express',
                         'Gift Card'
                        ]
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };
  const isAllPaymentSelected =  paymentTypes.length > 0 && paymentMethod.length === paymentTypes.length;
  const isAllStorePaymentSelected =  paymentTypes.length > 0 && storePaymentMethod.length === paymentTypes.length;
  const isAllProvinceStoresSelected =  transactions.length > 0 && provinceStores.length === transactions.length;

  const handlePaymentType = (event) => {
    const value = event.target.value;
      if (value[value.length - 1] === "all") {
        setPaymentMethod(paymentMethod.length === paymentTypes.length ? [] : paymentTypes);
        return;
      }
      setPaymentMethod(value);
  };

  const handleStorePaymentType = (event) => {
    const value = event.target.value;
      if (value[value.length - 1] === "all") {
        setStorePaymentMethod(storePaymentMethod.length === paymentTypes.length ? [] : paymentTypes);
        return;
      }
      setStorePaymentMethod(value);
  };

  useEffect(async () => {
    const result = await axios
      .get("/api/provinceSales/",
      {
        params: {
          province:province,
          startDate:startDate,
          endDate:endDate,
          paymentType:paymentMethod
        },
        paramsSerializer: params => {
          return qs.stringify(params,{arrayFormat: 'repeat'})
        }
      })
      setProvinceSales(result.data);
  }, [province,startDate,endDate,paymentMethod]);

  useEffect(() => {
     axios.get("/api/provinceStores/",
      {
        params: {
          province:province
        },
        paramsSerializer: params => {
          return qs.stringify(params,{arrayFormat: 'repeat'})
        }
      })
      .then(res => {
        setTransactions(res.data);
      });
  }, [province]);


  useEffect(async () => {
    const result = await axios.get("/api/provinceSales/",
    {
      params: {
        storeId:provinceDefaultStore,
        startDate:startDate,
        endDate:endDate,
        paymentType:storePaymentMethod

      },
      paramsSerializer: params => {
        return qs.stringify(params,{arrayFormat: 'repeat'})
      }
    })
      setStoreSales(result.data);
  },[provinceDefaultStore,storePaymentMethod]);

  useEffect(async () => {
    const result = await axios.get("/api/purchase/",
    {
      params: {
        storeId:provinceStores,
        startDate:startDate,
        endDate:endDate,
        paymentType:storePaymentMethod
      },
      paramsSerializer: params => {
        return qs.stringify(params,{arrayFormat: 'repeat'})
      }
    })
      setStoreSales(result.data);
  },[provinceStores,storePaymentMethod,startDate,endDate]);

  console.log(transactions)

  const handleProvinceStores = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setProvinceStores(provinceStores.length === transactions.length ? [] : transactions);
      return;
    }
    setProvinceStores(value);
  };

  return (
    <Container maxWidth={false}>
    <Grid
    container
      spacing={1}
    >
      <Grid
        item
        md={12}
        xs={12}
      >

        <Box ml={3}>

          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Box pt={1} pb={3} >

            <FormControl className={classes.formControl} >
              <InputLabel id="payment-mutiple-checkbox-label">Payment type</InputLabel>
                <Select
                  labelId="paymentMethod-mutiple-checkbox-label"
                  id="paymentType-mutiple-checkbox"
                  className={classes.selectMultiple}
                  multiple
                  value={paymentMethod}
                  onChange={handlePaymentType}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      value="all"
                      classes={{
                        root: isAllPaymentSelected ? classes.selectedAll : ""
                      }}
                  >
                      <ListItemIcon>
                        <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllPaymentSelected}
                          indeterminate={
                            paymentMethod.length > 0 && paymentMethod.length < paymentTypes.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                  {paymentTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={paymentMethod.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
             </Box>

            <Grid
                item
                md={12}
                xs={12}
              >
               < Line data={provinceSales.sales} options={{
                title:{
                  display:true,
                  text:'ProvinceSales',
                  fontSize:16
                },
                legend:{
                  display:true,
                  position:'top'
                }
              }}/>
            </Grid>
    </CardContent>
      </Card>
        </Box>
          </Grid>
            </Grid>
            <Box pt={3} pl={3}>
            <Grid
                item
                md={12}
                xs={12}
              >
    <Card>

    <CardContent>
    <Box pt={3} pl={3}>
            <Grid
    container
      spacing={1}
    >

              <Grid
              item
              md={6}
              xs={6}
            >
              <FormControl className={classes.formControl} >
              <InputLabel id="province-stores-mutiple-checkbox-label">Province Stores</InputLabel>
                <Select
                  labelId="province-stores-mutiple-checkbox-label"
                  id="provincestores-mutiple-checkbox"
                  className={classes.selectMultiple}
                  multiple
                  value={provinceStores}
                  onChange={handleProvinceStores}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      value="all"
                      classes={{
                        root: isAllProvinceStoresSelected ? classes.selectedAll : ""
                      }}
                  >
                      <ListItemIcon>
                        <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllProvinceStoresSelected}
                          indeterminate={
                            provinceStores.length > 0 && provinceStores.length < transactions.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                  {transactions.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={provinceStores.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                </Grid>

             <Box pt={1} pb={3} >

              <Grid
              item
              md={3}
              xs={3}
            >
               <FormControl className={classes.formControl} >
              <InputLabel id="payment-stores-checkbox-label">Payment type</InputLabel>
                <Select
                  labelId="payment-mutiple-checkbox-label"
                  id="paymentStores-mutiple-checkbox"
                  className={classes.selectMultiple}
                  multiple
                  value={storePaymentMethod}
                  onChange={ handleStorePaymentType}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      value="all"
                      classes={{
                        root: isAllStorePaymentSelected ? classes.selectedAll : ""
                      }}
                  >
                      <ListItemIcon>
                        <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllStorePaymentSelected}
                          indeterminate={
                            storePaymentMethod.length > 0 && storePaymentMethod.length < paymentTypes.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                  {paymentTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={storePaymentMethod.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                </Grid>

             </Box>

            <Grid
                item
                md={12}
                xs={12}
              >
              <Box pt={2}>

               < Line data={storeSales} options={{
                title:{
                  display:true,
                  text:'ProvinceSales',
                  fontSize:16
                },
                legend:{
                  display:true,
                  position:'top'
                }
              }}/>

              </Box>
              </Grid>




          </Grid>
         </Box>
         </CardContent>
         </Card>
        </Grid>
        </Box>
    </Container>
  );
}
export default ProvinceSales;
