import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Details {
 itemName: string,
 itemId: string,
 itemPrice: string,
 description: string
}

interface Row {
  transactionId: string,
  date: string,
  total: string,
  store: string,
  comment: string,
  status: string,
  statusOptions:
  {
    clearedEnabled: boolean,
    suspiciousEnabled: boolean,
    fraudEnabled: boolean
  }
  time: string,
  cashierName: string,
  phone: string,
  reason: string,
  details: Details []
}

interface TableRowProperties {
    rows : Row [],
    currentRow: Row,
    comment : string,
    status: string
}

const initialState: TableRowProperties = {
    rows :[],
    currentRow: {
           transactionId: '',
           date: '',
           total: '',
           store: '',
           comment: '',
           status: '',
           statusOptions:
            {
              clearedEnabled: false,
              suspiciousEnabled: false,
              fraudEnabled: false

            },
           time: '',
           cashierName: '',
           phone: '',
           reason: '',
           details: [{
                        itemName :'',
                        itemId:'',
                        itemPrice:'',
                        description:''
                    }]
    },
    comment:'',
    status:''
  }

  const initialStates = new Array(2).fill(initialState)

export const RowGenericSlice = (sliceName: string) => createSlice({
    //name:'selectedStores',
    name : sliceName,
    initialState: initialStates,
    reducers:{
        addRow :(state, { payload }: PayloadAction<{ id: number,row: Row, comment: string, status: string }>) => {
            const {id,row, comment, status } = payload;
              state[id].currentRow = row
              state[id].comment= comment
              state[id].status= status
            },
        removeRow :(state, { payload }: PayloadAction<{ id: number}>) => {
              const {id} = payload
              state[id] = initialState
            },
        setRows :(state, { payload }: PayloadAction<{ id: number,rows : Row[] }>) => {
          const {id,rows} = payload;
            state[id].rows = rows
          },

}})

export default RowGenericSlice

