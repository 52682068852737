import React, {useState,useEffect} from 'react';
import { CardContent,
         Container,
         Grid,
         Card,
         Box,
        CardHeader} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import { Divider } from '@material-ui/core';
import EnhancedTable from '../table/CollapsibleSelectable';

const CommercialSales = ({startDate,endDate,storeId,isAllStoresSelected})=>{

    const TableHeader = [
        {
          id: "collapse",
          label: " "
        },
        {
          id: "id",
          label: "Id"
        },
        {
          id: "Transaction_number",
          label: "Transaction"
        },
        {
          id: "store",
          label: "Store"
        },
        {
          id: "date",
          label: "Date"
        },
        {
          id: "time",
          label: "Time"
        },
        {
          id: "subTotal",
          label: "Sub Total"
        },
        {
          id: "total",
          label: "Total"
        }
      ];

      const collapseHeader = [
      {
        id: "name",
        label: "Name"
      },
      {
        id: "sku",
        label: "Sku"
      },
      {
        id: "price",
        label: "Price"
      }
    ];

  const [transactions,setTransactions]= useState([])
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [data, setData] = useState('1');
  const paymentTypes =  ['Cash',
                         'Debit',
                         'Visa',
                         'Master',
                         'American Express',
                         'Gift Card'
                        ]
  const isAllPaymentSelected =  paymentTypes.length > 0 && paymentMethod.length === paymentTypes.length;
  useEffect(async () => {
    const result = await axios
      .get("/api/purchase/commercial_sales",
      {
        params: {
          storeId:storeId,
          startDate:startDate,
          endDate:endDate,
          allStores:isAllStoresSelected
        },
        paramsSerializer: params => {
          return qs.stringify(params,{arrayFormat: 'repeat'})
        }
      })
      setTransactions(result.data);
  }, [storeId,startDate,endDate]);

  return (
    <Container maxWidth={false}>
      <Box pt={1} pl={1}>
            <Grid
                item
                md={12}
                xs={12}
              >
    <Card>
    <CardHeader title='Commercial Sales'>
    </CardHeader>
    <Divider/>
    <CardContent>

    <Grid
        container
          spacing={1}
       >
      <Grid
          item
          md={12}
          xs={12}
        >
          <Box pt={1}>
          <EnhancedTable rows={transactions}
                        columns={TableHeader}
                        collapseHeader = {collapseHeader}
                        checkBoxEnabled = {false}
                        collapseKey= {'items'}/>
          </Box>
        </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>
        </Box>
   </Container>
  );
}
export default CommercialSales;
