import React from 'react';

const Profit = () => {
return (

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="20.000000pt" height="20.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#707070" stroke="none">
<path d="M4600 5095 c-289 -24 -316 -31 -369 -91 -74 -84 -66 -189 22 -281
l51 -54 -352 -352 -352 -351 -218 216 c-238 237 -269 259 -398 281 -79 14
-168 0 -251 -37 -51 -24 -134 -103 -713 -681 l-655 -655 -130 130 c-145 146
-198 180 -313 200 -87 16 -171 3 -262 -41 -57 -27 -99 -65 -338 -308 -151
-152 -283 -294 -295 -316 -28 -53 -30 -164 -3 -215 63 -120 193 -176 317 -135
49 16 76 39 284 246 l230 228 135 -133 c100 -98 150 -140 192 -159 111 -52
254 -53 366 -1 50 23 143 112 715 683 l657 656 218 -216 c119 -119 233 -225
252 -237 65 -38 146 -55 240 -50 74 3 98 9 156 37 62 31 108 72 474 437 l405
402 45 -40 c109 -98 220 -103 306 -13 54 56 58 77 85 420 24 314 22 338 -33
394 -67 69 -72 70 -468 36z m344 -154 c6 -9 -31 -517 -40 -559 -9 -45 -33 -37
-107 35 -77 76 -103 93 -138 93 -17 0 -140 -116 -459 -434 -491 -491 -494
-493 -614 -484 -34 3 -79 15 -101 26 -22 11 -132 113 -245 227 -113 114 -222
215 -242 226 -41 21 -101 25 -145 8 -15 -5 -320 -302 -683 -664 -503 -502
-665 -659 -700 -674 -60 -27 -150 -27 -210 0 -28 13 -91 66 -175 149 -160 159
-211 184 -298 146 -18 -8 -129 -111 -247 -231 -118 -119 -227 -223 -242 -231
-40 -21 -66 -17 -99 15 -57 57 -56 58 238 354 179 180 281 275 308 288 65 29
116 33 181 14 54 -16 69 -28 230 -186 162 -160 173 -169 210 -169 38 0 54 15
724 684 495 495 696 689 725 702 24 10 67 17 105 17 103 1 135 -22 370 -258
113 -113 218 -213 233 -221 37 -20 117 -20 153 -1 16 9 210 197 432 419 369
370 402 406 402 437 0 28 -12 46 -82 118 -69 70 -81 87 -72 101 9 14 51 20
270 38 320 26 312 26 318 15z"/>
<path d="M4085 3393 c-193 -41 -381 -162 -505 -326 -52 -70 -115 -205 -141
-302 -29 -113 -30 -299 0 -410 42 -158 125 -298 243 -411 l64 -62 -58 -16
c-158 -44 -236 -230 -155 -369 l26 -43 -30 -59 c-22 -44 -29 -72 -29 -115 0
-43 7 -71 29 -115 l30 -59 -26 -44 c-18 -31 -27 -62 -30 -110 -4 -60 -1 -73
25 -126 l31 -60 -26 -43 c-17 -30 -27 -64 -31 -108 -4 -59 -2 -71 28 -131 29
-60 31 -67 16 -83 -23 -25 -46 -98 -46 -145 0 -95 58 -188 145 -231 38 -19 67
-20 604 -23 486 -2 570 -1 610 13 147 48 214 216 143 357 l-27 55 27 52 c44
84 39 185 -14 263 -18 26 -18 27 7 63 47 71 48 194 1 265 -25 38 -26 43 -12
59 61 67 61 235 0 303 -15 15 -14 19 3 45 33 49 43 82 43 147 0 128 -80 221
-214 251 l-28 6 72 71 c314 309 345 787 75 1138 -116 151 -300 264 -497 304
-96 20 -259 19 -353 -1z m347 -172 c232 -59 409 -226 485 -456 23 -70 27 -96
26 -205 0 -107 -3 -136 -26 -205 -94 -285 -352 -475 -646 -475 -116 0 -206 21
-309 71 -121 59 -242 179 -301 299 -55 114 -74 194 -74 310 0 116 19 196 74
310 59 120 180 240 300 299 46 22 111 47 144 55 89 21 237 20 327 -3z m402
-1540 c33 -31 35 -85 4 -118 l-21 -23 -549 0 -549 0 -24 25 c-41 40 -26 113
29 135 11 5 260 8 553 7 l531 -2 26 -24z m0 -340 c36 -34 36 -88 0 -122 l-26
-24 -545 0 -545 0 -24 28 c-13 15 -24 41 -24 56 0 35 33 78 63 84 12 3 259 4
549 3 l526 -1 26 -24z m3 -343 c33 -31 31 -87 -3 -119 l-26 -24 -545 0 -545 0
-24 28 c-31 37 -31 81 1 112 l24 25 547 0 548 0 23 -22z m-2 -343 c25 -24 32
-65 19 -100 -17 -44 -34 -45 -594 -45 l-532 0 -29 29 c-37 38 -39 82 -4 116
l24 25 546 0 546 0 24 -25z m0 -340 c31 -30 33 -83 6 -116 l-19 -24 -547 -3
-546 -3 -30 30 c-37 37 -38 78 -4 115 l24 26 546 0 546 0 24 -25z"/>
<path d="M4211 3049 c-12 -11 -24 -34 -28 -50 -4 -22 -16 -35 -43 -49 -48 -25
-95 -81 -115 -139 -54 -158 51 -314 227 -335 45 -6 62 -13 78 -33 36 -46 18
-113 -36 -134 -31 -11 -88 10 -103 39 -17 32 -65 47 -101 32 -85 -35 -61 -144
45 -206 27 -16 45 -33 45 -44 0 -10 12 -32 26 -49 20 -24 34 -31 60 -31 42 0
84 37 84 73 0 18 8 28 30 37 42 18 114 100 128 148 26 87 9 178 -45 241 -47
55 -91 80 -165 92 -77 14 -104 30 -113 67 -8 40 9 78 45 97 39 20 65 13 112
-33 49 -47 99 -50 134 -8 28 31 30 56 10 95 -18 33 -79 89 -112 101 -17 7 -24
17 -24 35 0 63 -91 99 -139 54z"/>
<path d="M2385 2713 c-216 -46 -410 -177 -533 -362 -97 -145 -142 -294 -142
-472 0 -233 82 -431 247 -599 46 -46 83 -86 83 -87 0 -2 -13 -3 -28 -3 -15 0
-52 -12 -81 -26 -133 -66 -179 -240 -96 -361 21 -32 23 -39 11 -52 -65 -75
-70 -202 -11 -291 22 -35 22 -36 4 -59 -20 -25 -49 -111 -49 -146 0 -83 65
-186 144 -228 41 -22 44 -22 626 -22 582 0 585 0 626 22 79 42 144 145 144
228 0 35 -29 121 -49 146 -18 23 -18 24 4 59 59 89 54 216 -11 291 -12 13 -10
20 11 52 83 121 37 295 -96 361 -29 14 -66 26 -81 26 -15 0 -28 1 -28 3 0 1
38 41 83 87 156 159 235 337 245 554 6 136 -7 223 -49 340 -99 272 -343 481
-631 540 -95 20 -250 19 -343 -1z m313 -169 c147 -30 300 -122 389 -233 108
-135 159 -295 150 -470 -9 -184 -68 -314 -202 -447 -135 -134 -282 -195 -475
-195 -193 0 -339 60 -476 196 -133 131 -192 262 -201 446 -15 285 134 535 392
655 132 61 279 78 423 48z m421 -1534 c31 -16 46 -65 31 -100 -25 -61 -18 -60
-590 -60 -572 0 -565 -1 -590 60 -14 34 0 83 29 100 22 13 1096 13 1120 0z
m16 -362 c31 -36 29 -79 -6 -113 -19 -20 -33 -20 -569 -20 -536 0 -550 0 -569
20 -35 34 -37 77 -6 113 l27 32 548 0 548 0 27 -32z m-9 -332 c21 -18 28 -32
28 -61 0 -29 -7 -43 -28 -61 l-27 -24 -539 0 -539 0 -27 24 c-21 18 -28 32
-28 61 0 29 7 43 28 61 l27 24 539 0 539 0 27 -24z"/>
<path d="M2513 2375 c-13 -9 -28 -34 -34 -55 -7 -22 -18 -40 -25 -40 -24 0
-112 -90 -129 -132 -41 -100 -10 -230 72 -297 39 -32 116 -61 163 -61 45 0 80
-35 80 -80 0 -55 -27 -85 -79 -85 -34 0 -46 6 -75 38 -42 46 -93 51 -131 12
-49 -48 -21 -124 65 -177 33 -20 53 -40 57 -57 22 -95 144 -95 166 0 4 14 17
30 30 36 43 20 88 66 114 116 31 63 35 150 8 215 -32 79 -137 152 -219 152
-34 0 -82 24 -90 45 -24 64 13 125 75 125 28 0 43 -8 74 -40 47 -49 97 -55
133 -17 36 39 30 89 -18 141 -22 24 -53 49 -69 56 -20 8 -32 23 -40 50 -13 44
-43 70 -81 70 -14 0 -35 -7 -47 -15z"/>
<path d="M745 2044 c-134 -21 -295 -85 -395 -159 -72 -53 -183 -175 -228 -251
-114 -191 -150 -433 -96 -644 38 -152 134 -313 250 -419 64 -59 66 -61 38 -61
-73 0 -176 -77 -209 -157 -38 -92 -5 -228 71 -291 77 -63 72 -63 695 -60 535
3 566 4 603 22 95 47 141 125 141 237 0 67 -4 81 -30 125 -37 59 -109 110
-170 119 l-44 7 73 72 c237 230 321 572 217 880 -56 167 -170 325 -306 421
-77 56 -208 117 -295 139 -64 17 -257 29 -315 20z m225 -178 c302 -55 522
-288 560 -594 18 -139 -17 -304 -92 -430 -45 -76 -159 -189 -238 -235 -112
-65 -207 -90 -340 -90 -136 -1 -209 16 -322 75 -181 94 -315 274 -354 475 -29
153 -10 286 62 433 39 77 62 109 128 175 164 163 375 231 596 191z m455 -1552
c34 -37 33 -78 -4 -115 l-30 -30 -546 3 -547 3 -19 24 c-27 33 -25 86 6 116
l24 25 546 0 546 0 24 -26z"/>
<path d="M825 1701 c-26 -11 -45 -33 -53 -65 -4 -14 -28 -39 -57 -58 -136 -91
-155 -278 -40 -393 44 -45 114 -75 173 -75 42 0 92 -46 92 -85 0 -38 -50 -85
-90 -85 -25 0 -41 9 -71 42 -31 35 -44 42 -75 42 -44 0 -84 -40 -84 -84 0 -39
56 -109 107 -134 31 -16 42 -27 43 -44 0 -13 14 -37 30 -54 26 -25 36 -29 63
-24 37 7 77 48 77 79 0 15 10 26 30 35 40 18 86 65 113 116 28 54 30 165 3
216 -41 79 -116 134 -198 146 -91 12 -118 34 -118 94 0 41 39 80 80 80 22 0
43 -12 76 -42 35 -32 54 -42 79 -42 63 0 101 64 71 122 -17 34 -85 97 -114
106 -13 4 -22 19 -26 40 -10 54 -64 86 -111 67z"/>
</g>
</svg>


  )}
  export default Profit;