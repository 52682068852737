import { createSlice } from "@reduxjs/toolkit";
export const dropDownSlice = createSlice({
    name:'dropDown',
    initialState:{loaders:{
                     productLoader: false},
                  duration:{slice:0,
                            index:0,
                            selected: false,
                            submited: false,
                            startDate: new Date().toISOString(),
                            criticalDate: new Date().toISOString(),
                            season:'',
                            showDurationDialog:false,
                            beforeOrAfter:'Before-Critical-Date'
                          }},
    reducers:{
         dropDownItem : (state,action) => {
           state.duration.slice = action.payload
      },
         dropDownIndex: (state,action) => {
         state.duration.index = action.payload
      },
         setSeason : (state,action) => {
          state.duration.season = action.payload
      },
         showDialog : (state,action) => {
         state.duration.showDurationDialog = action.payload
      },
         selected : (state,action) => {
         state.duration.selected = action.payload
      },
         setStartDate : (state,action) => {
         state.duration.startDate = action.payload
      },
         setEndDate : (state,action) => {
         state.duration.criticalDate = action.payload
      },
         setSubmited: (state,action) => {
         state.duration.submited= action.payload
      },
         setBeforeOrAfter: (state,action) => {
         state.duration.beforeOrAfter= action.payload
      }
    }
})

export const {dropDownItem, dropDownIndex,
              setSeason, showDialog,
              setStartDate,setEndDate,
              setSubmited,selected,
              setBeforeOrAfter} = dropDownSlice.actions
export default dropDownSlice.reducer