import React from 'react';

const ReportIcon = () => {
return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="20.000000pt" height="20.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">

   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill="#707070" stroke="none">
   <path d="M57 5109 c-50 -29 -47 120 -47 -2549 0 -2680 -3 -2522 49 -2550 13
   -7 627 -10 1891 -10 2080 0 1919 -6 1939 68 7 24 11 363 11 997 l0 960 605
   605 c567 567 605 608 605 640 0 32 -20 55 -263 297 -254 254 -264 263 -302
   263 -37 0 -48 -9 -342 -302 l-303 -302 0 441 c0 242 -3 456 -7 474 -5 28 -91
   119 -462 492 -251 253 -468 465 -483 473 -23 12 -248 14 -1450 14 -948 -1
   -1429 -4 -1441 -11z m2773 -594 l0 -426 25 -24 24 -25 421 0 420 0 0 -497 0
   -498 -453 -455 -453 -455 -63 -192 -62 -193 -979 -2 -978 -3 -26 -24 c-44 -41
   -33 -111 21 -139 16 -9 266 -12 962 -12 l940 0 -30 -87 c-35 -103 -36 -122 -8
   -157 l20 -26 -941 0 -941 0 -24 -25 c-35 -34 -34 -93 1 -126 l26 -24 1218 0
   1218 0 26 24 c32 30 35 87 7 122 -19 24 -22 24 -253 29 l-233 5 350 115 350
   115 153 149 152 150 0 -827 0 -827 -1765 0 -1765 0 0 2380 0 2380 1320 0 1320
   0 0 -425z m475 -5 l290 -290 -293 0 -292 0 0 290 c0 160 1 290 3 290 1 0 133
   -130 292 -290z m1423 -1073 l172 -172 -135 -135 -135 -135 -175 175 -175 175
   132 132 c73 73 135 133 138 133 3 0 83 -78 178 -173z m-783 -1127 c-308 -308
   -564 -560 -570 -560 -6 0 -87 77 -180 170 l-170 170 565 565 565 565 175 -175
   175 -175 -560 -560z m-867 -523 l132 -132 -192 -64 c-106 -35 -198 -66 -205
   -68 -6 -3 18 86 55 196 37 111 69 201 72 201 3 0 65 -60 138 -133z"/>
   <path d="M705 2165 c-33 -32 -33 -84 -1 -122 l24 -28 624 -3 c342 -2 643 0
   667 3 85 13 120 93 65 149 l-27 26 -664 0 -664 0 -24 -25z"/>
   <path d="M740 853 c-63 -23 -81 -104 -34 -148 l27 -25 1217 0 1217 0 27 25
   c36 33 36 92 0 126 l-26 24 -1206 2 c-664 1 -1214 -1 -1222 -4z"/>
   </g>
   </svg>
)}
 export default ReportIcon;
