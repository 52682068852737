import * as React from "react";
import PropTypes from "prop-types";
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';

import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount,columns,checkBoxEnabled } = props;
  return (
    <TableHead>
      <TableRow>

        {columns.map((headCell) => (
          <TableCell key={headCell.id} align={"left"} padding={"normal"}>
            {headCell.label}
          </TableCell>
        ))}
        { checkBoxEnabled &&  <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts"
            }}
          />
        </TableCell> }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns:PropTypes.array.isRequired
};

export default function EnhancedTable(props) {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const detail = props.collapseKey
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [open, setOpen] = React.useState([]);

  React.useEffect(() => {
    if (props.sendProductsToParent){
    props.sendProductsToParent(selected)}
      },[selected]);

  React.useEffect(() => {
    setOpen(new Array(props.rows.length).fill(false))
      },[props.rows]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.sku);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleCollapse =(event,row) =>{
    const list = open.map((item, j) => {
      if (j === row -1 ) {
        return !item;
      } else {
        return item;
      }
    })
    setOpen(list)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  return (
    <React.Fragment>
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={props.rows.length}
              columns={props.columns}
              checkBoxEnabled={props.checkBoxEnabled}
            />
            <TableBody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.sku);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.sku}
                    >
                       <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={(event) => handleCollapse(event,row.id)}
                            >
                              {open[index+ (page * rowsPerPage)] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                       </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="left"
                      >
                        {row.id}
                      </TableCell>
                      { Object.keys(row).map((key) =>
                          { if (key != detail & key != 'id') {
                              return(
                                    <TableCell align="left" key={key}> {row[key]} </TableCell> )}})}
                    { props.checkBoxEnabled && <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.sku)}
                          inputProps={{
                            "aria-labelledby": labelId
                          }}
                        />
                      </TableCell> }
                    </TableRow>
                    <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open[index+ (page * rowsPerPage)]} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Store Sales
              </Typography>
              <Table size="medium" aria-label="purchases">
                <TableHead>
                  <TableRow>
                  {props.collapseHeader.map((headCell) => (
                    <TableCell key={headCell.id} align={"left"} padding={"normal"}>
                      {headCell.label}
                    </TableCell>
                  ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row[detail].map((sales) => (
                    <TableRow key={sales.storeId}>
                       { Object.keys(sales).map((key) => {
                               return(<TableCell align="left" key={key}> {sales[key]} </TableCell> )})}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
                    </>

                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25,50]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
    </React.Fragment>
  );
}
