import React , { useState,useEffect} from 'react';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem
  } from '@material-ui/core'

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Input from '@material-ui/core/Input';
import { createTheme } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import {selectBoxContents} from '../redux/selectBox'

const theme = createTheme({
  spacing: 4,
});

const formControl ={
  margin: theme.spacing(2),
  minWidth: 160,
  maxWidth: 800
}

const selectEmpty ={
  marginTop: theme.spacing(2),
  minWidth:300,
  width:300
}

const useStyle = makeStyles(({
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
 export default function SelectBox({options, defaultStatus}){
   const [allSelected, setAllSelected] = useState(false);

   const [selected, setSelected] = useState(defaultStatus)
   const isAllSelected =  options.length > 0 && selected.length === options.length;

   const dispatch = useDispatch()

   const classes = useStyle()
   const handleChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      setAllSelected(true)
      return;
    }
    setSelected(value);
    setAllSelected(false)
  };

  useEffect(() => {
    dispatch(selectBoxContents({selectedValue:selected}))
 }, [selected]);


return(
<>
<FormControl sx={formControl} >
<InputLabel id="mutiple-checkbox-label">Status</InputLabel>
  <Select
    labelId="mutiple-checkbox-Id"
    id="mutiple-checkbox"
    sx ={selectEmpty}
    multiple
    value={selected}
    onChange={handleChange}
    input={<Input />}
    renderValue={(selected) => selected.join(', ')}
    MenuProps={MenuProps}
  >
    <MenuItem
        value="all"
        classes={{
          root: isAllSelected ? classes.selectedAll : ""
        }}
    >
        <ListItemIcon>
          <Checkbox
            checked={isAllSelected}
            indeterminate={
              selected.length > 0 && selected.length < options.length
            }
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.selectAllText }}
          primary="Select All"
        />
      </MenuItem>
    {options.map((item) => (
      <MenuItem key={item} value={item}>
        <Checkbox checked={selected.indexOf(item) > -1} />
        <ListItemText primary={item} />
      </MenuItem>
    ))}
  </Select>
  </FormControl>
  </>
)
    }