
import React , { useState,useEffect} from 'react';
import {
  FormControl,
  Container,
  } from '@material-ui/core'

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';

import { createTheme, ThemeProvider } from "@mui/material/styles";
const muiTheme = createTheme({
  spacing: 2,
});

export default function RadioButton({RadioButtonReturn}) {

 const [districtEnabled, setDistrictEnabled] = useState(false)
 const [storeEnabled, setStoreEnabled] = useState(true)
 const [provinceEnabled, setProvinceEnabled] = useState(false)

 const [storeselected, setStoreselected] = useState([])
 const [district, setDistrict] = useState([]);
 const [province, setProvince] = useState([]);
 const [salesOption, setSalesOption] = useState('Store');

 useEffect(() => {
    RadioButtonReturn(storeEnabled,districtEnabled,provinceEnabled)
      },[storeEnabled,districtEnabled,provinceEnabled]);

  const handleRadio = (event) => {
    setSalesOption(event.target.value);
    if (event.target.value == 'Province'){
      setProvinceEnabled(true)
      setDistrictEnabled(false)
      setStoreEnabled(false)
    }
    if (event.target.value == 'District'){
      setProvinceEnabled(false)
      setDistrictEnabled(true)
      setStoreEnabled(false)
    }
    if (event.target.value == 'Store'){
      setProvinceEnabled(false)
      setDistrictEnabled(false)
      setStoreEnabled(true)
    }
    setStoreselected([])
    setDistrict([])
    setProvince([])
  };


 return (
  <Container maxWidth={false}>
        <FormControl component="fieldset">
            <FormLabel component="legend">Sales by:</FormLabel>
            <RadioGroup row aria-label="searchOption" name="searchOption" value={salesOption} onChange={handleRadio}>
                <FormControlLabel value="Store" control={<Radio />} label="Store" />
                <FormControlLabel value="District" control={<Radio />} label="District" />
                <FormControlLabel value="Province" control={<Radio />} label="Province" />

            </RadioGroup>
        </FormControl>
</Container>
 )
}



