import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Divider,
  CardHeader,
  Typography
} from '@material-ui/core';

import { useEffect,useState } from 'react';
import { SelectChangeEvent } from "@mui/material";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import MoneyIcon from '@material-ui/icons/Money';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import { red,green } from '@material-ui/core/colors';
import ProfitGreen from 'icons/Profit';
import ProfitMargin from 'icons/Margin';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import { useDispatch } from 'react-redux';
import { callCompleted } from 'redux/salesPromise';

export interface dailyDurationSales {
  durationSales: number;
  totalSales: number;
  change: number[];
}

interface BudgetParams {
  avgItemTr: string;
  text: string;
  isSales: number;
  dayShow: boolean;
  weekShow: boolean;
  monthShow: boolean,
  threeMonthsShow: boolean;
  dataToAsk: string;
  GetTotalSales?:(newType: dailyDurationSales) => any;
}

const Budget = ({avgItemTr  ,text ,isSales , dayShow ,weekShow , monthShow, threeMonthsShow, GetTotalSales,
                 dataToAsk}: BudgetParams) => {

const dispatch = useDispatch()
const [result,setResult] = useState({'durationSales':0,'totalSales':0,'change':[]})
const [yearsChange, setYearsChange] = useState(1)
const { promiseInProgress } = usePromiseTracker();

useEffect(()=>{

       trackPromise(axios.get("/api/purchase/periodic_sales",
       {
          params: {
              allStores: true,
              avgItemTr: avgItemTr,
              daily:dayShow,
              weekly: weekShow,
              monthly:monthShow,
              threeMonths: threeMonthsShow
            }
       }).then((response) => setResult(response.data)))
    },[weekShow,monthShow,threeMonthsShow])


  if (avgItemTr =='1') {
    GetTotalSales?.(result)
  }

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setYearsChange(event.target.value as number);
  };


  switch(dataToAsk){
    case 'totalSales': dispatch(callCompleted({totalSales:promiseInProgress})); break;
    case 'totalTransactions': dispatch(callCompleted({totalTransactions:promiseInProgress})); break;
    case 'totalItems': dispatch(callCompleted({totalItems:promiseInProgress}));break;
    case 'averageItemsPerTransaction': dispatch(callCompleted({averageItemsPerTransaction:promiseInProgress})); break;
}

  return(
    <Card >
    <CardHeader title={text}>
    </CardHeader>

    <Divider />
    <CardContent>

      <Box
         sx={{ display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between' ,
            pt:2,
            height:160,
            maxWidth : 200 }}>
          <Typography
            color="textPrimary"
            variant="h3"
          >
           {result.totalSales.toLocaleString()}
          </Typography>

          { isSales === 1 ? <Avatar
            sx={{
              backgroundColor: red[600],
              height: 40,
              width: 40
            }}
          >
            <MoneyIcon />
          </Avatar> :
          isSales ===2 ?
          <Avatar
          sx={{
            ml:5,
            backgroundColor: green[600],
            height: 40,
            width: 40
          }}
        >
          <PeopleIcon />
        </Avatar> :
         isSales ===3 ?
         <Avatar
         sx={{
           backgroundColor: green[600],
           height: 40,
           width: 40
         }}
       >
         <ProfitGreen />
       </Avatar>:
        <Avatar
        sx={{
          backgroundColor: red[600],
          height: 40,
          width: 40,
          ml:5
        }}
      >
        <ProfitMargin />
        </Avatar>
        }

        <FormControl style={{width:120}} >
        <InputLabel  id="changed from last years"> Changed</InputLabel>
        <Select
          labelId="changed-from-last-years"
          id="change"
          value={yearsChange}
          label="Change"
          onChange={handleChange}
          size="small"
        >
          <MenuItem value={1} sx ={{color:'#0D830F'}}>  2019 </MenuItem>
          <MenuItem value={2}  sx ={{color:'#DD0815'}} > 2020 </MenuItem>
          <MenuItem value={3}  sx ={{color:'#100985'}} > 2021  </MenuItem>
          <MenuItem value={4} sx ={{color:'#eead0e'}} > 2022  </MenuItem>
          <MenuItem value={5} sx ={{color:' #15EDF4'}} > 2023  </MenuItem>

        </Select>
      </FormControl>


      <Box
        sx={{
          pt:2,
          display: 'flex',
          alignItems: 'left'
        }}>

       { (yearsChange ===1 && result.change[0] < 0) ||(yearsChange ===2 && result.change[1] < 0)  ||
         (yearsChange ===3 && result.change[2] < 0) ||  (yearsChange ===4 && result.change[3] < 0)  ||
          (yearsChange ===5 && result.change[4] < 0)?
           <ArrowDownwardIcon sx={{ color: red[900] }}  />:
           <ArrowUpwardIcon sx={{ color: green[900] }}  />   }
         <Typography
          variant="body2"
          sx={{
            mr: 1
          }}
        >
        {yearsChange ===1 ?
          result.change[0] :
          yearsChange ===2 ?
          result.change[1]:
          yearsChange ===3 ?
          result.change[2]:
          yearsChange ===4 ?
          result.change[3]:
          result.change[4]}
          %
        </Typography>
        </Box>
      </Box>


    </CardContent>
  </Card>
);}

export default Budget;
