import React, {useState} from 'react';

import { CardContent,
         Container,
         Grid,
         Card,
         Box,
         MenuItem,
         InputLabel,
         Select,
        FormControl,
        CardHeader} from '@material-ui/core';
import { props } from 'redux/loading';

import {  Line } from 'react-chartjs-2';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Input from '@material-ui/core/Input';
import { Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {setSalesParams} from 'redux/searchOptions';
import { useTypedSelector } from 'redux/store';
import { selectStatus, transactionsStatus } from 'redux/loading';

import {Bars} from 'react-loader-spinner';
import Backdrop  from '@material-ui/core/Backdrop';

import { RootState, AppDispatch } from 'redux/store';
import { SelectChangeEvent } from '@mui/material';
import { fetchSales } from 'redux/loading';
const StoreSales = ()=>{
  const salesData = useSelector((state:RootState) => state.salesParams.values)
  const transactions = useTypedSelector(transactionsStatus)
  const loadingData = useTypedSelector(selectStatus)
  const status = JSON.parse(JSON.stringify(salesData));
  const dispatcher = useDispatch<AppDispatch>()
  const [data, setData] = useState ('1');

  const dataToPass: props ={
    salesOptions: salesData,
    data: data
  }
  const paymentTypes =  ['Cash',
                         'Debit',
                         'Visa',
                         'Master',
                         'American Express',
                         'Gift Card'
                        ]
  const isAllPaymentSelected =
    paymentTypes.length > 0 &&
    salesData.paymentMethod.length === paymentTypes.length;

  const formControl ={
    margin: 1,
    minWidth: 120,
    maxWidth: 200
  }
  const  selectEmpty ={
      marginTop: 2,
      minWidth:120
    }
  const  selectMultiple ={
      marginTop: 2,
      minWidth:200
    }
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 400,
      }
    },
  };

  const handlePaymentType = (event:SelectChangeEvent<string []>) => {
    const value = event.target.value;
      if (value[value.length - 1] === "all") {
        status.paymentMethod = (salesData.paymentMethod.length === paymentTypes.length ? [] : paymentTypes)
        dispatcher(setSalesParams(status));
        return;
      }
      status.paymentMethod = value;
      dispatcher(setSalesParams(status));
  };

  const handleData = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
      setData(value);
  };

    React.useEffect(() => { dispatcher (fetchSales(dataToPass));},
  [salesData.selectedStores,
    salesData.startDate,
    salesData.endDate,
    salesData.paymentMethod,
    data, dispatcher]);

  return (
    <Container maxWidth={false}>
      <Box pt={3} pl={1}>
            <Grid
                item
                md={12}
                xs={12}
              >
    <Card>
    <CardHeader title='Store Sales'>
    </CardHeader>
    <Divider/>
    <CardContent>
    <Box pt={3} pl={3}>
            <Grid
    container
      spacing={1}
    >

              <Grid
              item
              md={6}
              xs={6}
            >
                <FormControl sx={formControl} >
              <InputLabel id="payment-mutiple-checkbox-label">Payment type</InputLabel>
                <Select
                  labelId="paymentMethod-mutiple-checkbox-label"
                  id="paymentType-mutiple-checkbox"
                  sx ={selectMultiple}
                  multiple
                  value={salesData.paymentMethod}
                  onChange={handlePaymentType}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      value="all"
                  >
                      <ListItemIcon>
                        <Checkbox

                          checked={isAllPaymentSelected}
                          indeterminate={
                            salesData.paymentMethod.length > 0 &&
                            salesData.paymentMethod.length < paymentTypes.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText

                        primary="Select All"
                      />
                    </MenuItem>
                  {paymentTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={salesData.paymentMethod.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                </Grid>
              <Grid
              item
              md={3}
              xs={3}
            >
              <FormControl  variant="standard" sx={formControl}>
        <InputLabel id="simple-select"> Data</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          sx ={selectEmpty}
          value={data}
          onChange={handleData}
        >
          <MenuItem value="">
            <em>Sales</em>
          </MenuItem>
          <MenuItem value={1}>Sales</MenuItem>
          <MenuItem value={2}>Total units sold</MenuItem>
          <MenuItem value={3}>Total processed transactions</MenuItem>
          <MenuItem value={4}>Avg Items per transaction</MenuItem>
          <MenuItem value={5}>Avg $ per transaction</MenuItem>
          <MenuItem value={6}>Items per transaction std</MenuItem>
          <MenuItem value={7}>Amount per transaction std</MenuItem>
        </Select>
      </FormControl>
                </Grid>

            <Grid
                item
                md={12}
                xs={12}
              >

          {
            loadingData &&
            <Backdrop style={{zIndex: 2}} open={true} >
              <Bars color='#862D23' height="100" width="100" />
            </Backdrop>
            }

              <Box pt={6}>

               < Line data={transactions} />

              </Box>
              </Grid>

          </Grid>
         </Box>
         </CardContent>
         </Card>
        </Grid>
        </Box>

   </Container>
  );
}
export default StoreSales;
