import React from "react";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios'
import qs from 'qs'
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';


import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import {
  Button ,
  Box,
  Container,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel
  } from '@material-ui/core'

const headCells = [
  { id: 'number', numeric: false, disablePadding: false, label: 'Store Number' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'budget', numeric: false, disablePadding: false, label: 'Budget' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' },

];

const  input ={
  width: 160,
  height: 40
}

const stores ={
  width: 400,
  height: 40
}

const  visuallyHidden ={
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
}

const  root ={
  width: '100%',
}



const createData = (id,number,name,date,budget) => ({
  id,
  number,
  name,
  date,
  budget,
  isEditMode: false
});

const CustomTableCell = ({ row, name, onChange }) => {

  const { isEditMode } = row;
  return (
    <TableCell align="left" >
      {isEditMode ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          sx={input}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy,  onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span  sx ={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,

  onRequestSort: PropTypes.func.isRequired,

  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,

};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

function BudgetTable() {

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [startDate,setStartDate] = React.useState(new Date())
  const [options,setOptions] = React.useState([])
  const [storeselected, setStoreselected] = React.useState(options)
  const [allStoresSelected, setAllStoresSelected] = React.useState(false);
  const [deleteConfirm,setDeleteConfirm] =  React.useState(false);
  const [rowToDelete, setRowToDelete] = React.useState();
  const [deleteSuccess, setDeleteSuccess] = React.useState();
  const [saveSuccess, setSaveSuccess] = React.useState();

  const isAllStoresSelected =  options.length > 0 && storeselected.length === options.length;

  const handleExport = (event) => {
    event.preventDefault();
    axios.get('/api/storeBudget/download_file',
    {
        responseType: 'arraybuffer',
    }
).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.xls');
    document.body.appendChild(link);
    link.click();
})
    .catch((error) => console.log(error));
}


  React.useEffect(() => {
    axios.get('/api/storeBudget/month_budget',
    {params:
      {
        Month:startDate,
        store:storeselected
      },

    paramsSerializer: params => {
      return qs.stringify(params,{arrayFormat: 'repeat'})
    }
    }
    )
      .then(res => {
      var  element =[]
        if (res.data.length >0)  {
        element = res.data.map((rowNum) => createData(rowNum.id,rowNum.store_number.store_number,
                                                    rowNum.store_number.store_name,
                                                    rowNum.date, rowNum.budget));
        setRows(element)
    }
       setRows(element)
      });

    },[startDate,storeselected]);

    React.useEffect(() => {
      axios.get('/api/storesDetail',
      {
          params: {
              'storeNumber':true
          }
      })
        .then(res => {
          setOptions(res.data.nameNumber);

        });
      },[]);

  const [rows, setRows] = React.useState([]);
  const [previous, setPrevious] = React.useState({});


  const onToggleEditMode = id => {
    setRows(state => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };


  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setStoreselected(storeselected.length === options.length ? [] : options);
      setAllStoresSelected(true)
      return;
    }
    setStoreselected(value);
    setAllStoresSelected(false)
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSave = (e,row) => {

    const send ={store_number:row.number,date:row.date,budget:row.budget}
    const name = e.target.name;
    axios.put('/api/storeBudget/'+ row.id+'/', {
      store_number:{store_number:row.number,store_name:row.name},date:row.date,budget:row.budget
    })
    .then((response) => {
      setSaveSuccess(true)
      console.log(response);
    }, (error) => {
      console.log(error);
    });

  };

  const handleToCloseSuccess =()=>{
    setDeleteSuccess(false)
  }

  const handleStartDateChange =(date) =>{
    setStartDate(date)}

  const handleToCloseSave =() =>{
    setSaveSuccess(false)
  }

  const onConfirm =() =>{
    setDeleteConfirm(false)
    axios.delete('/api/storeBudget/'+ rowToDelete)
    .then((response) => {
      setDeleteSuccess(true)
      axios.get('/api/storeBudget/month_budget',
      {params:
        {
          Month:startDate,
          store:storeselected
        },

      paramsSerializer: params => {
        return qs.stringify(params,{arrayFormat: 'repeat'})
      }
      }
      )
        .then(res => {
        var  element =[]
          if (res.data.length >0)  {
          element = res.data.map((rowNum) => createData(rowNum.id,rowNum.store_number.store_number,
                                                      rowNum.store_number.store_name,
                                                      rowNum.date, rowNum.budget));
          setRows(element)
      }
         setRows(element)
        });
      console.log(response);
    }, (error) => {
      console.log(error);
    });
  }

  const onDismiss =() =>{
    setDeleteConfirm(false)
  }

  const handleDelete =(e,row) =>{
    setDeleteConfirm(true)
    setRowToDelete(row.id)
  }

  return (
    <>
    <Box pt={3} pb={5}>
    <Grid container>

      <Grid
        item
        md={3}
        xs={3}
      >
      <Dialog open={deleteConfirm} onClose={onDismiss}>
        <DialogTitle>Attention</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you want to delete this entry?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onDismiss}>Cancel</Button>
          <Button   onClick={onConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
        <Dialog open={deleteSuccess} onClose={handleToCloseSuccess}>
        <DialogTitle>Notification</DialogTitle>
        <DialogContent>
          <DialogContentText>The enty deleted with success</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleToCloseSuccess}
                    color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={saveSuccess} onClose={handleToCloseSave}>
        <DialogTitle>Notification</DialogTitle>
        <DialogContent>
          <DialogContentText>Saved successfully</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleToCloseSave}
                    color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker  variant="inline" inputVariant="outlined"
          label = "Month"
          value={startDate}
          onChange={handleStartDateChange}
          openTo="month"
          views={["year", "month"]}
          format="MM/yyyy"

          renderInput={(params) => <TextField {...params} />}
          >
          </DatePicker>

        </LocalizationProvider>
        </Grid>


            <Grid
              item
              md={4}
              xs={4}
            >

        <FormControl  >
              <InputLabel id="all-stores-mutiple-checkbox-label">Stores</InputLabel>
                <Select
                  labelId="all-stores-mutiple-checkbox-label"
                  id="allstores-mutiple-checkbox"
                  sx ={stores}
                  multiple
                  value={storeselected}

                  onChange={handleChange}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      value="all"

                  >
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllStoresSelected}
                          indeterminate={
                            storeselected.length > 0 && storeselected.length < options.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Select All"
                      />
                    </MenuItem>
                  {options.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={storeselected.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
              </Grid>
              <Box pt={3}>
              <Grid
              item
              md={2}
              xs={2}
            >
              <Button variant="contained" onClick={handleExport}>
                  Export
              </Button>
              </Grid>
              </Box>
              </Grid>
              </Box>
    <Paper sx={root}>
    <Box pt={2}>
    <TableContainer>
      <Table aria-label="caption table">
        <caption>Stores monthly budget</caption>
        <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}

            />
        <TableBody>
              {
                stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}

                    >
                      <TableCell> {row.number}</TableCell>
                      <TableCell> {row.name}</TableCell>
                       <CustomTableCell {...{ row, name: "date", onChange }} />
                       <CustomTableCell {...{ row, name: "budget", onChange }} />
                      <TableCell align="left">
                            <IconButton aria-label="edit" onClick={() => onToggleEditMode(row.id)}>
                            <EditIcon/>
                            <Button aria-label="save" onClick={(e) => handleSave(e,row)}>
                            <SaveIcon />
                            </Button>
                            <Button aria-label="delete" onClick={(e) => handleDelete(e,row)}>
                            <DeleteIcon/>
                            </Button>
                            </IconButton>


                      </TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
      </Table>
      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Box>
    </Paper>

    </>
  );
}

export default BudgetTable;