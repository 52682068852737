const VoidIcon = () => {
    return (

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="20.000000pt" height="30.000000pt" viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#707070" stroke="none">
        <path d="M748 4426 c-57 -20 -85 -42 -116 -88 -26 -40 -27 -46 -30 -206 l-3
        -164 -33 -13 c-51 -21 -97 -65 -117 -110 -18 -38 -19 -92 -19 -1073 0 -997 1
        -1034 19 -1070 25 -48 66 -87 113 -106 32 -14 101 -16 467 -16 l429 0 6 -48
        c19 -129 88 -309 168 -433 125 -194 328 -354 552 -435 479 -171 1008 2 1294
        422 84 123 158 311 178 447 l6 47 429 0 c366 0 435 2 467 16 47 19 88 58 113
        106 18 36 19 73 19 1070 0 981 -1 1035 -19 1073 -20 45 -66 89 -117 110 l-33
        13 -3 165 -3 165 -30 44 c-17 24 -51 54 -75 68 l-45 25 -1790 2 c-1475 2
        -1797 0 -1827 -11z m3432 -391 l0 -65 -1620 0 -1620 0 0 65 0 65 1620 0 1620
        0 0 -65z m-3240 -414 c0 -20 -74 -103 -114 -126 -23 -14 -46 -25 -49 -25 -4 0
        -7 36 -7 80 l0 80 85 0 c47 0 85 -4 85 -9z m2904 -58 c55 -217 235 -407 454
        -478 l52 -17 0 -308 0 -308 -33 -6 c-51 -9 -165 -68 -233 -119 -105 -80 -188
        -198 -228 -327 l-24 -75 -91 -3 -90 -3 -11 43 c-63 244 -205 469 -383 606 -49
        38 -56 48 -72 108 -34 127 -134 234 -247 269 -25 7 -82 17 -127 21 l-81 7 0
        48 0 49 118 0 c92 0 126 4 158 19 130 59 132 239 2 307 -19 10 -64 14 -153 14
        -123 0 -125 0 -125 23 0 31 -40 96 -73 121 -42 31 -150 30 -195 -2 -34 -25
        -72 -88 -72 -121 0 -17 -9 -19 -92 -23 -123 -5 -196 -37 -272 -119 -141 -152
        -139 -400 3 -549 l39 -41 -48 -24 c-72 -37 -200 -136 -270 -212 -130 -138
        -220 -305 -270 -501 l-11 -43 -81 3 -82 3 -11 46 c-56 219 -243 409 -468 474
        l-57 16 0 322 0 322 74 23 c104 33 189 86 276 172 76 75 140 179 170 275 7 22
        15 43 17 48 2 4 570 7 1262 7 l1259 0 16 -67z m506 -34 l0 -101 -27 17 c-48
        30 -84 68 -114 120 -16 28 -29 53 -29 58 0 4 38 7 85 7 l85 0 0 -101z m-1960
        -509 l0 -50 -53 0 c-56 0 -77 12 -77 43 0 43 18 57 75 57 l55 0 0 -50z m356
        -570 c217 -56 391 -192 493 -390 62 -118 85 -213 85 -350 0 -143 -19 -223 -81
        -350 -40 -81 -61 -109 -142 -190 -106 -106 -192 -158 -331 -202 -63 -19 -96
        -23 -210 -23 -114 0 -147 4 -210 23 -139 44 -225 96 -331 202 -81 81 -102 109
        -142 190 -62 127 -81 207 -81 350 0 137 23 232 85 350 101 196 274 333 489
        389 105 28 270 28 376 1z m-1914 -380 c40 -26 118 -121 118 -143 0 -4 -40 -7
        -90 -7 l-90 0 0 90 c0 50 4 90 8 90 5 0 29 -14 54 -30z m3518 -65 l0 -85 -80
        0 -80 0 19 39 c19 37 114 131 132 131 5 0 9 -38 9 -85z"/>
        <path d="M2294 2100 c-49 -11 -94 -49 -115 -94 -38 -83 -16 -147 84 -245 l66
        -65 -74 -77 c-80 -84 -102 -131 -91 -192 10 -52 46 -99 96 -124 84 -43 146
        -23 250 81 l64 66 76 -74 c52 -51 88 -78 117 -86 108 -33 215 47 215 159 0 63
        -11 82 -95 168 l-71 72 76 80 c61 63 78 88 85 123 27 133 -72 232 -205 205
        -35 -7 -60 -24 -123 -84 l-79 -76 -78 76 c-71 69 -89 79 -152 92 -8 1 -29 -1
        -46 -5z"/>
        </g>
        </svg>



    )
  }

  export default VoidIcon;