import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme
} from '@material-ui/core';

import NumberOfStores from '../Card/GeneralCard';
import { startLoading,  hasError,  endLoading} from "redux/budgetRate"

import axios from 'axios'
import React from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
Chart.register(...registerables);

const BudgetRates = ({dayShow,weekShow,monthShow, threeMonthsShow}) => {
  const theme = useTheme();
  const [data, setData]= React.useState({rate:{'labels':[],'datasets':[]},
                                         sale:{'labels':[],'datasets':[]}})
  const storePartition =['1-30','31-60','61-90','All']
  const sliceChanged = useSelector((state) => state.dropDown.duration.index);
  //const data =JSON.parse(JSON.stringify(useSelector(state=>state.budgetRates.budget)));

  const dispatch = useDispatch();
  const fetchData = () =>async dispatch => {
    dispatch(startLoading());
      try{
            const res = await axios.get("/api/purchase/sales_to_budget_rank",
              {
                params: {
                  daily:dayShow,
                  weekly:weekShow ,
                  monthly:monthShow,
                  threeMonths: threeMonthsShow,
                  storesToReport:sliceChanged
                }})
                dispatch(endLoading());
                setData(res.data)
          }
      catch(e){
        dispatch(hasError(e.message))
      }
     }


  React.useEffect(() => { dispatch(fetchData());},
                          [weekShow,monthShow,threeMonthsShow,sliceChanged, dispatch]);


  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: true},
    maintainAspectRatio: false,
    responsive: true,

    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
      <>
    <Card >
      <NumberOfStores title ={'Sales To Budget Rates'}
         contentText={storePartition} />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 600,
            position: 'relative',
            marginBottom:5
          }}
        >
          <Bar
            data={data.rate}
            options={options}
          />

        </Box>
        <Divider />
        <CardHeader title = "Stores sales">
          </CardHeader>
          <Divider />
        <Box
          sx={{
            height: 600,
            position: 'relative',
            marginTop:5
          }}
        >
          <Bar
            data={data.sale}
            options={options}
          />

        </Box>
      </CardContent>
    </Card>
    </>
  );
};

export default BudgetRates;
