import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useDispatch } from 'react-redux';
import { logedin } from 'redux/user';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';


export interface Usercredential {
  username: string,
  password: string
}

export interface credential {
  values: Usercredential
}



async function loginUser(credentials: credential) {
const requestOptions = {
      method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({username :credentials.values.username, password:credentials.values.password})
};
    return fetch('/api/login/', requestOptions)
  .then(data => data.json())
  .catch((error) => {
    console.error('Error:', error);
  });
 }

 function setToken(userToken : string) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

const Login = () => {
  const [error,setError] = React.useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Login | Nutriart Stores</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
        pt ={2}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required('username is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const token = await loginUser({
                values
              });
              if(typeof(token) !== 'undefined') {
                dispatch(logedin((values.username)))
                 navigate('/app/salesPreset')
              }
              else{
                setError(true)
                navigate('/')
              }

              setToken(token)

console.log(token)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the stores platform
                  </Typography>
                </Box>


                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in
                  </Button>
                  <Box pt={5}>
                  {error &&  <Alert severity="error">
                          <AlertTitle>Error</AlertTitle>
                            Invalid username or password. Please contact administrator
                           </Alert>}
                  </Box>
                </Box>

              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
