
import React , { useState,useEffect} from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Container,
  Select,
  CardContent} from '@material-ui/core'

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Input from '@material-ui/core/Input';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  spacing: 2,
});
const useStyle = makeStyles(theme=>({

formControl: {
  margin: theme.spacing(2),
  minWidth: 160,
  maxWidth: 800,

},
DatePicker: {
  width: "80%",
  maxWidth:50,
  marginRight:'auto'
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth:150
  },

}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function DistrictDropDown(districtEnabled,districtReturn) {

 const [district, setDistrict] = useState([]);
 const [enableSubmit, setEnableSubmit] = useState();

  const districts = ['1','2','5','22','23','24','25','26']
  const isAllDistrictSelected = districts.length > 0 &&  district.length === districts.length;
  const classes = useStyle();

const handleDistrict = (event) => {
  const value = event.target.value;
  setEnableSubmit(true)
  if (value[value.length - 1] === "all") {
    setDistrict(district.length === districts.length ? [] : districts);
    return;
  }
  setDistrict(value);
};

useEffect (()=>{
   districtReturn(district,isAllDistrictSelected,enableSubmit);
},[district, isAllDistrictSelected,enableSubmit])

 return (
  <Container maxWidth={false}>
    <FormControl className={classes.formControl} disabled = {!districtEnabled}>
    <InputLabel id="payment-mutiple-checkbox-label">Districts</InputLabel>
    <Select
        labelId="districts-mutiple-checkbox-label"
        id="districts-mutiple-checkbox"
        className={classes.selectEmpty}
        multiple
        value={district}
        onChange={handleDistrict}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
    >
        <MenuItem
            value="all"
            classes={{
            root: isAllDistrictSelected ? classes.selectedAll : ""
            }}
        >
            <ListItemIcon>
            <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllDistrictSelected}
                indeterminate={
                district.length > 0 && district.length < districts.length
                }
            />
            </ListItemIcon>
            <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
            />
        </MenuItem>
        {districts.map((item) => (
        <MenuItem key={item} value={item}>
            <Checkbox checked={district.indexOf(item) > -1} />
            <ListItemText primary={item} />
        </MenuItem>
        ))}
    </Select>
    </FormControl>


</Container>
 )
}



