import {
  CardHeader
   } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';

import Menu from "@material-ui/core/Menu";
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { dropDownItem,dropDownIndex, selected,setBeforeOrAfter} from 'redux/dropDown';

import React from 'react'
const NumberOfStores = ({title,contentText}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch()
  const [cardText, setCardText] = React.useState(contentText[0])

  const handleClick= (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose= () => {
    setAnchorEl(null);}

  const handleItem =(event,item,index) =>{
    if (item == 'Before-Critical-Date' || item == 'After-Critical-Date' ){
      setAnchorEl(null)
      setCardText(item)
      dispatch(setBeforeOrAfter(item))
      return true
    }
    else{
      dispatch(selected(true))
    }
    setAnchorEl(null)
    setCardText(item)
    dispatch(dropDownItem(item))
    dispatch(dropDownIndex(index))
  }

  return (
    <>
      <CardHeader title={title}
      action={(
          <Button
            id="thisYear"
            endIcon={<ArrowDropDownIcon />}
            size="small"
            variant="text"
            onClick={handleClick}
             >
            {cardText}
          </Button>)}/>
          <Menu
            id="this-year"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {contentText.map((item,index) =>
            <MenuItem key={index} value={item}
             onClick={event=>handleItem(event,item,index)}> {item} </MenuItem>)}
          </Menu>



    </>
  );
};

export default NumberOfStores;