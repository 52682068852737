import React from 'react';

const ProfitMargin = () => {
return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="20.000000pt" height="20.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">

   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill="#FFFFFF" stroke="none">
   <path d="M998 5025 c-61 -22 -103 -61 -137 -125 -48 -90 -99 -115 -174 -85
   -57 24 -143 22 -201 -3 -59 -27 -151 -118 -177 -178 -26 -57 -27 -143 -4 -198
   32 -75 6 -128 -85 -177 -100 -53 -140 -127 -140 -258 0 -135 44 -212 150 -266
   79 -40 104 -96 75 -168 -35 -84 -19 -184 42 -264 27 -35 111 -107 134 -115 8
   -3 3 -36 -15 -108 -49 -198 -60 -291 -60 -515 0 -152 4 -240 17 -319 65 -407
   224 -759 486 -1076 l84 -101 73 73 c41 40 375 386 743 768 l670 695 1 1058 0
   1058 -72 -6 c-119 -11 -256 -34 -368 -61 -72 -18 -105 -23 -108 -15 -8 23 -80
   107 -115 134 -80 61 -172 76 -259 43 -80 -31 -130 -8 -179 84 -54 102 -127
   140 -263 139 -44 0 -98 -7 -118 -14z m210 -161 c12 -8 31 -37 43 -65 53 -122
   211 -187 343 -140 51 18 62 19 90 7 37 -16 91 -71 104 -106 7 -18 4 -40 -9
   -80 -24 -74 -23 -127 2 -194 29 -74 77 -128 138 -155 67 -29 81 -51 81 -131 0
   -80 -14 -102 -81 -131 -61 -27 -109 -81 -138 -155 -25 -67 -26 -120 -2 -194
   22 -65 16 -88 -36 -142 -51 -52 -76 -59 -143 -37 -74 24 -127 23 -194 -2 -74
   -29 -128 -77 -155 -138 -29 -67 -51 -81 -131 -81 -80 0 -102 14 -131 81 -27
   61 -81 109 -155 138 -67 25 -120 26 -194 2 -40 -13 -62 -16 -80 -9 -35 13 -90
   67 -106 104 -12 28 -11 39 6 88 26 71 25 121 -1 190 -29 74 -77 128 -138 155
   -67 29 -81 51 -81 131 0 78 14 102 73 128 63 28 103 67 132 129 37 78 42 144
   16 217 -18 51 -19 62 -7 90 16 38 71 91 108 105 20 8 39 5 82 -10 71 -25 121
   -24 190 2 74 29 128 77 155 138 29 67 51 81 131 81 43 0 73 -5 88 -16z m1112
   -1258 l0 -934 -656 -681 c-633 -658 -657 -681 -676 -665 -39 36 -162 234 -223
   359 -215 441 -261 937 -132 1405 22 81 25 85 61 99 33 13 44 13 79 0 41 -14
   49 -23 107 -118 45 -76 122 -111 240 -111 132 0 206 40 259 140 49 91 102 117
   177 85 55 -23 142 -21 198 4 60 26 151 118 177 177 26 57 27 143 4 198 -32 75
   -6 128 86 177 99 53 139 128 139 259 0 131 -40 206 -139 259 -86 46 -114 96
   -91 165 11 33 17 38 80 57 86 26 235 57 278 58 l32 1 0 -934z"/>
   <path d="M1005 4548 c-90 -17 -204 -80 -279 -154 -171 -172 -213 -420 -107
   -638 167 -345 629 -421 897 -149 217 221 216 567 -1 786 -132 134 -320 191
   -510 155z m279 -186 c91 -42 149 -100 194 -190 35 -72 37 -81 37 -172 0 -91
   -2 -100 -37 -172 -45 -91 -103 -147 -196 -191 -61 -29 -76 -32 -162 -32 -86 0
   -101 3 -162 32 -93 44 -151 100 -196 191 -35 72 -37 81 -37 171 0 87 3 102 32
   163 40 85 97 147 172 186 78 42 122 52 213 48 61 -3 92 -11 142 -34z"/>
   <path d="M1040 4227 c-19 -6 -58 -34 -85 -62 -71 -70 -91 -145 -63 -239 16
   -55 99 -138 154 -154 94 -28 169 -8 239 63 98 97 101 222 8 325 -65 72 -158
   97 -253 67z m135 -172 c50 -49 15 -135 -55 -135 -41 0 -80 39 -80 80 0 41 39
   80 80 80 19 0 40 -9 55 -25z"/>
   <path d="M4557 4962 c-26 -26 -47 -52 -47 -57 0 -5 39 -49 87 -97 l88 -88
   -985 -1 c-542 -1 -1002 -1 -1022 0 l-38 3 0 -1041 0 -1041 1041 0 1040 0 -6
   73 c-45 517 -249 970 -604 1339 -174 182 -366 323 -601 440 l-134 68 654 0
   655 0 -88 -88 c-48 -48 -87 -91 -87 -95 0 -9 98 -107 107 -107 5 0 91 83 193
   185 l185 185 -185 185 c-102 102 -190 185 -195 185 -6 0 -32 -22 -58 -48z
   m-1541 -457 c680 -165 1220 -660 1439 -1318 43 -128 85 -308 85 -361 l0 -26
   -870 0 -870 0 0 871 0 872 59 -7 c32 -4 103 -18 157 -31z"/>
   <path d="M3360 4000 c0 -67 -3 -80 -16 -80 -77 0 -189 -88 -212 -166 -28 -94
   -8 -169 63 -239 63 -64 100 -75 244 -75 105 0 113 -1 136 -25 16 -15 25 -36
   25 -55 0 -19 -9 -40 -25 -55 -23 -24 -31 -25 -135 -25 -104 0 -112 1 -135 25
   -14 13 -25 31 -25 40 0 12 -15 15 -80 15 -67 0 -80 -3 -80 -16 0 -100 124
   -224 224 -224 13 0 16 -13 16 -80 l0 -80 80 0 80 0 0 80 c0 67 3 80 16 80 77
   0 189 88 212 166 28 94 8 169 -63 239 -63 64 -100 75 -244 75 -105 0 -113 1
   -136 25 -16 15 -25 36 -25 55 0 19 9 40 25 55 23 24 31 25 135 25 104 0 112
   -1 135 -25 14 -13 25 -31 25 -40 0 -12 15 -15 80 -15 67 0 80 3 80 16 0 76
   -80 182 -157 208 -26 9 -55 16 -65 16 -16 0 -18 9 -18 80 l0 80 -80 0 -80 0 0
   -80z"/>
   <path d="M2080 1928 c-289 -303 -619 -650 -735 -771 l-209 -220 44 -39 c107
   -93 357 -244 540 -326 19 -8 -141 -11 -630 -11 l-655 -1 88 88 c48 48 87 92
   87 98 0 13 -92 104 -105 104 -6 0 -93 -83 -195 -185 l-185 -185 188 -187 187
   -188 57 58 58 57 -90 90 -90 90 985 1 c542 1 1039 0 1105 -1 243 -3 474 33
   738 116 16 5 17 -6 17 -135 l0 -141 80 0 80 0 0 -80 0 -80 80 0 80 0 0 80 0
   80 80 0 80 0 0 263 0 262 103 77 c56 43 128 101 160 129 l57 51 0 -71 0 -71
   80 0 80 0 0 80 0 80 80 0 80 0 0 195 0 195 50 88 c98 172 182 403 224 614 20
   98 46 291 46 339 0 5 -443 9 -1057 9 l-1058 0 -525 -552z m2456 330 c-16 -138
   -127 -508 -134 -445 -3 27 -4 27 -83 27 l-79 0 0 80 0 80 -80 0 -80 0 0 -80 0
   -80 -80 0 -80 0 0 -374 0 -374 -77 -65 -78 -65 -3 39 -3 39 -80 0 -79 0 0 80
   0 80 -80 0 -80 0 0 -80 0 -80 -80 0 -80 0 0 -173 0 -174 -77 -26 c-223 -76
   -390 -102 -653 -101 -163 1 -228 5 -320 22 -285 52 -551 158 -776 308 l-84 55
   207 218 c115 119 408 427 652 684 l445 467 934 0 935 0 -7 -62z m-296 -818 l0
   -240 -80 0 -80 0 0 240 0 240 80 0 80 0 0 -240z m-640 -800 l0 -240 -80 0 -80
   0 0 240 0 240 80 0 80 0 0 -240z"/>
   <path d="M2800 2080 l0 -80 -80 0 -80 0 0 -400 0 -400 80 0 80 0 0 -80 0 -80
   80 0 80 0 0 80 0 80 80 0 80 0 0 400 0 400 -80 0 -80 0 0 80 0 80 -80 0 -80 0
   0 -80z m160 -480 l0 -240 -80 0 -80 0 0 240 0 240 80 0 80 0 0 -240z"/>
   <path d="M2160 1600 l0 -80 -80 0 -80 0 0 -320 0 -320 80 0 80 0 0 -80 0 -80
   80 0 80 0 0 80 0 80 80 0 80 0 0 320 0 320 -80 0 -80 0 0 80 0 80 -80 0 -80 0
   0 -80z m160 -400 l0 -160 -80 0 -80 0 0 160 0 160 80 0 80 0 0 -160z"/>
   <path d="M4720 1360 l0 -80 -80 0 -80 0 0 -400 0 -400 80 0 80 0 0 -80 0 -80
   80 0 80 0 0 80 0 80 80 0 80 0 0 400 0 400 -80 0 -80 0 0 80 0 80 -80 0 -80 0
   0 -80z m160 -480 l0 -240 -80 0 -80 0 0 240 0 240 80 0 80 0 0 -240z"/>
   </g>
   </svg>
)}
 export default ProfitMargin;
