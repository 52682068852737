
import React , { useState,useEffect,SyntheticEvent} from 'react';
import {Grid,
  TextField,
  Box,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Container,
  CardContent} from '@material-ui/core';
  import { RootState } from 'redux/store';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import {setStartDate, setEndDate, setSeason} from 'redux/dropDown'


const formControl = {
  margin: 2,
  minWidth: 160,
  maxWidth: 800,

}
const DatePickerCSS ={
  width: "80%",
  maxWidth:50,
  marginRight:'auto'
  }
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  }};

export default function DateComponent() {

 const DateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
 const [seasonStart,setSeasonStart] = React.useState<Date|null>(new Date())
 const [seasonCritical,setSeasonCritical] = React.useState<Date|null>(new Date())
 const [customSeason, setCustomSeason] = React.useState('Saint Valentine')

 const dispatcher = useDispatch()

const state = useSelector((state: RootState) => state.dropDown.duration)
const status = JSON.parse(JSON.stringify(state));


React.useEffect(() => {

  dispatcher(setStartDate(seasonStart)),
  dispatcher(setEndDate(seasonCritical)),
  dispatcher(setSeason(customSeason))
  },[seasonStart,seasonCritical,customSeason]);


const handleStartDateChange =(date : Date | null) =>{
  setSeasonStart(date)
}

const handleEndDateChange =(date: Date| null) =>{
  setSeasonCritical(date)
}

const handleChange =(event: SelectChangeEvent) =>{
  setCustomSeason(event.target.value as string)
}

 return (
  <Box sx={{pt:2}}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label = "Start Date"
            minDate = {new Date(2022, 0, 1)}
            maxDate ={new Date()}
            value={seasonStart}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField sx={{mr:2,width: '170px'}}{...params} />}
            >
            </DatePicker>
          </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label = "Critical Date"
                    minDate = {new Date(2022, 0, 1)}
                    maxDate={new Date()}
                    value={seasonCritical}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField sx={{width: '170px', mr:2}} {...params} />}
                    >
                  </DatePicker>
                </LocalizationProvider>
        <Select
          style={{ width: 150 }}
          labelId="season"
          id="season select"
          value={customSeason}

          onChange={handleChange}
        >
          <MenuItem value={'Saint Valentine'}>Saint Valentine</MenuItem>
          <MenuItem value={'Easter'}>Easter</MenuItem>
          <MenuItem value={'Summer'}>Summer</MenuItem>
          <MenuItem value={'Halloween'}>Halloween</MenuItem>
          <MenuItem value={'Christmas'}>Christmas</MenuItem>

        </Select>
        </Box>
 )
}







