import React from 'react'
import { Bar} from 'react-chartjs-2';
import { trackPromise } from 'react-promise-tracker';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme,
  Grid
} from '@material-ui/core';

import axios from 'axios';
import { useEffect,useState } from 'react';

const SalesByDistricts = ({dayShow,weekShow,monthShow,threeMonthsShow}) => {
  const theme = useTheme();
  const [result,setResult] = useState({'sales':{'labels':[],'datasets':[]}})
  const [percentage,setPercentage] = useState(new Array(6).fill({'district1':0,'district2':0,'district5':0,
                                                'district22':0,'district23':0, 'district24':0,
                                                'district25':0,'district26':0}))

  useEffect(() => {
    trackPromise(
    axios.get("/api/districtSales/periodic_districts_sales",
    {
      params: {
        daily:dayShow,
        weekly:weekShow ,
        monthly:monthShow,
        threeMonths: threeMonthsShow

      }})
    .then(res => {
      setResult(res.data);
      setPercentage(res.data.percentage)
    }));
    },[weekShow,monthShow,threeMonthsShow]);

  const [open, setOpen] = React.useState(true);

  const options = {
    animation: false,
    cutoutPercentage: 70,
    layout: { padding: 0 },
    legend: {
      display: true
    },
    maintainAspectRatio: false,
    responsive: true

  };

  const devices = [
    {
      title: '1',
      value2019: percentage[0].district1,
      value2020: percentage[1].district1,
      value2021: percentage[2].district1,
      value2022: percentage[3].district1,
      value2023: percentage[4].district1,
      value2024: percentage[5].district1
    },
    {
      title: '2',
      value2019: percentage[0].district2,
      value2020: percentage[1].district2,
      value2021: percentage[2].district2,
      value2022: percentage[3].district2,
      value2023: percentage[4].district2,
      value2024: percentage[5].district2
    },
    {
      title: '5',
      value2019: percentage[0].district5,
      value2020: percentage[1].district5,
      value2021: percentage[2].district5,
      value2022: percentage[3].district5,
      value2023: percentage[4].district5,
      value2024: percentage[5].district5,

    },
    {
      title: '22',
      value2019: percentage[0].district22,
      value2020: percentage[1].district22,
      value2021: percentage[2].district22,
      value2022: percentage[3].district22,
      value2023: percentage[4].district22,
      value2024: percentage[5].district22

    },
    {
      title: '23',
      value2019: percentage[0].district23,
      value2020: percentage[1].district23,
      value2021: percentage[2].district23,
      value2022: percentage[3].district23,
      value2023: percentage[4].district23,
      value2024: percentage[5].district23
    },
    {
      title: '24',
      value2019: percentage[0].district24,
      value2020: percentage[1].district24,
      value2021: percentage[2].district24,
      value2022: percentage[3].district24,
      value2023: percentage[4].district24,
      value2024: percentage[5].district24
    },
    {
      title: '25',
      value2019: percentage[0].district25,
      value2020: percentage[1].district25,
      value2021: percentage[2].district25,
      value2022: percentage[3].district25,
      value2023: percentage[4].district25,
      value2024: percentage[5].district25,

    },
    {
      title: '26',
      value2019: percentage[0].district26,
      value2020: percentage[1].district26,
      value2021: percentage[2].district26,
      value2022: percentage[3].district26,
      value2023: percentage[4].district26,
      value2024: percentage[5].district26
    }
  ];

  return (
    <Card >
      <CardHeader title="Sales By Districts" >
      </CardHeader>

      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 500,
            position: 'relative'
          }}
        >

          <Bar
            data={result.sales}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >

          {devices.map(({
            color,
            title,
            value2019,
            value2020,
            value2021,
            value2022,
            value2023,
            value2024
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >

              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
               color='#0D830F'
                variant="h4"
              >
                {value2019}
                %
              </Typography>
              <Typography
                color='red'
                variant="h4"
              >
                {value2020}
                %
              </Typography>
              <Typography
                color='#100985'
                variant="h4"
              >
                {value2021}
                %
              </Typography>
              <Typography
                color='#86086D'
                variant="h4"
              >
                {value2022}
                %
              </Typography>
              <Typography
                color='#eead0e'
                variant="h4"
              >
                {value2023}
                %
              </Typography>
              <Typography
                color='#15EDF4'
                variant="h4"
              >
                {value2024}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default SalesByDistricts;
