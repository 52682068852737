import { createSlice } from "@reduxjs/toolkit";

export const editSkusSlice = createSlice({
    name: "editSkus",
    initialState: {
      seasonSkus: [],
      isLoading: false,
      error: false,
    },
    reducers: {
      startPosting: state => {
        state.isLoading = true;
      },
      hasError: (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      },
      endPosting: state => {
        state.isLoading = false;
      },
      skusEdited: (state, action) => {
        state.seasonSkus = action.payload;
      },
    }
  });

  export const {startPosting,endPosting,hasError,skusEdited} = editSkusSlice.actions;
  export default editSkusSlice.reducer;