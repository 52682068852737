import React from 'react';

const ProfitGreen = () => {
return (



    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="20.000000pt" height="20.000000pt" viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet">

   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
   fill="#FFFFFF" stroke="none">
   <path d="M2816 5037 c-48 -27 -75 -62 -86 -113 -5 -21 -10 -211 -10 -421 l0
   -382 -383 -3 -384 -3 -36 -25 c-20 -13 -45 -41 -56 -61 -20 -35 -21 -52 -21
   -442 l0 -406 -382 -3 c-427 -3 -420 -2 -475 -75 l-28 -36 -3 -419 -3 -418
   -380 0 c-366 0 -383 -1 -418 -21 -20 -11 -48 -36 -61 -56 l-25 -37 -3 -919 -2
   -920 -25 -28 c-13 -16 -27 -38 -30 -50 -18 -57 35 -144 93 -151 42 -5 400 18
   567 37 433 48 873 137 1305 265 307 91 761 262 808 303 25 23 29 63 7 94 -28
   40 -55 37 -217 -28 -424 -169 -830 -293 -1246 -380 -192 -39 -610 -104 -619
   -96 -1 2 41 14 95 26 152 34 464 126 659 192 823 282 1558 698 2198 1247 259
   222 670 641 690 702 16 51 -3 84 -116 203 l-111 117 426 0 426 0 0 -447 0
   -446 -22 19 c-13 10 -58 51 -100 91 -77 72 -119 94 -166 85 -13 -3 -67 -43
   -120 -91 -389 -347 -918 -705 -1413 -956 -80 -41 -152 -81 -159 -90 -19 -23
   -16 -71 5 -95 33 -37 71 -26 271 79 529 276 971 576 1383 939 l60 53 113 -106
   c62 -59 127 -113 144 -122 41 -20 93 -11 125 23 l24 26 3 544 c2 478 1 549
   -13 578 -30 62 -16 61 -596 61 l-527 0 -27 -25 c-15 -14 -31 -36 -36 -49 -19
   -49 0 -80 132 -220 71 -74 128 -139 127 -143 -6 -15 -252 -279 -331 -355 l-82
   -78 -5 585 c-3 322 -9 591 -13 598 -27 42 -104 33 -126 -14 -8 -18 -11 -211
   -11 -668 l0 -643 -126 -104 c-159 -131 -357 -280 -499 -375 l-110 -74 -3 1777
   c-1 978 0 1788 3 1801 l5 22 363 -2 362 -3 5 -723 5 -724 24 -19 c32 -26 73
   -24 99 4 l22 23 0 731 0 730 -23 43 c-16 30 -37 51 -67 67 -43 23 -45 23 -429
   23 -381 0 -387 -1 -425 -23z m-96 -2446 l0 -1379 -122 -72 c-68 -39 -231 -125
   -363 -191 l-240 -119 -3 1557 c-1 857 0 1564 3 1571 3 9 86 12 365 12 l360 0
   0 -1379z m-885 -696 l0 -1130 -145 -58 c-149 -60 -549 -197 -575 -197 -13 0
   -15 162 -15 1254 0 994 3 1255 13 1259 6 3 172 5 367 4 l355 -2 0 -1130z
   m-885 -624 l0 -809 -192 -46 c-185 -44 -494 -106 -530 -106 -17 0 -18 48 -18
   878 0 483 3 882 7 885 3 4 170 7 370 7 l363 0 0 -809z"/>
   </g>
   </svg>
)}
 export default ProfitGreen;
