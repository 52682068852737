import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import Budget from 'components/dashboard/TotalSales';
import BudgetRates from 'components/dashboard/BudgetRates';
import SalesByProvince from 'components/dashboard//SalesByProvince';
import SalesByDistricts from 'components/dashboard//SalesByDistricts';
import PeriodicDailySales from 'components/dashboard/PeriodicSalesByDay';

import {Bars} from 'react-loader-spinner';
import Backdrop from '@material-ui/core/Backdrop';
import IdleTimeout from 'components/IdleTimeout/IdleTimeout';
import DateMenu from 'components/Card/CardHeader';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { dailyDurationSales } from 'components/dashboard/TotalSales';

const PresetSales = () => {
  const [dayShow, setdayShow] = React.useState(true);
  const [weekShow, setWeekShow] = React.useState(false);
  const [monthShow, setMonthShow] = React.useState(false);
  const [threeMonthsShow, setThreeMonthsShow] = React.useState(false);

  const promiseCompleted = useSelector((state : RootState) => state.sales)
  const rate = useSelector((state : RootState) => state.budgetRates)

  const [open, setOpen] = React.useState(true);

  const [dailyTotalSales, setDailyTotalSales] = React.useState({})

  const durationGet =(dayShow : boolean ,weekShow: boolean, monthShow: boolean, threeMonthsShow: boolean) =>{
    setdayShow(dayShow)
    setWeekShow(weekShow)
    setMonthShow(monthShow)
    setThreeMonthsShow(threeMonthsShow)
}
console.log(rate)

const GetTotalSales = (TotalSales : dailyDurationSales) :any => {
    setDailyTotalSales(TotalSales)
}
    const navigate = useNavigate()
    const redirect = () => {
      navigate('/app/dashboard')
    }

  const notLoaded  = (promiseCompleted.totalSales  &&
    promiseCompleted.totalTransactions &&
    promiseCompleted.totalItems &&
    promiseCompleted.averageItermsPerTransaction &&
    promiseCompleted.rates) || rate.isLoading

  return(
  <>
    <Helmet>
      <title> Sales Report| Nutriart Stores</title>
    </Helmet>
    <IdleTimeout />
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={10}
            sm={10}
            xl={10}
            xs={10}
          >

            {
            notLoaded &&
              <Backdrop style={{zIndex: 2}} open={open} >
                <Bars  color='#862D23' height="100" width="100" />
              </Backdrop>
              }
              </Grid>
              <Grid
              item
              lg={2}
              sm={2}
              xl={2}
              xs={2}
            >
              <ButtonGroup size="medium" aria-label=" outlined button group">
                  <Button onClick={redirect}>Custom Duration</Button>
                </ButtonGroup>
            </Grid>

            <Grid
            container
            spacing={2}
            paddingTop={2}
            paddingLeft={3}
          >

          <Grid
              item
              xs>

            <Budget
              avgItemTr='1'
              text='SALES'
              isSales= {1}
              dayShow ={dayShow}
              weekShow ={weekShow}
              monthShow={monthShow}
              threeMonthsShow={threeMonthsShow}
              GetTotalSales={GetTotalSales}
              dataToAsk={'totalSales'}
              />
            </Grid>

          <Grid
              item
              xs
            >

          < Budget avgItemTr='3'
              text='TRANSACTIONS'
              isSales={2}
              dayShow ={dayShow}
              weekShow ={weekShow} monthShow={monthShow}
              threeMonthsShow={threeMonthsShow}
              dataToAsk={'totalTransactions'}
               />
          </Grid>

            <Grid
              item
            xs
            >

          < Budget avgItemTr='2'
              text='ITEMS'
              isSales={2}
              dayShow ={dayShow}
              weekShow ={weekShow}
              monthShow={monthShow}
              threeMonthsShow={threeMonthsShow}
              dataToAsk={'totalItems'}
             />
          </Grid>

            <Grid
              item
              xs
            >

          < Budget avgItemTr='4'
              text='AVG ITEMS/TRANSACTION'
              isSales={2} dayShow ={dayShow}
              weekShow ={weekShow} monthShow={monthShow}
              threeMonthsShow={threeMonthsShow}
              dataToAsk={'averageItemsPerTransaction'}
             />
          </Grid>

            <Grid
              item
              xs
            >
              <DateMenu durationGet={durationGet} showDates ={true}/>
            </Grid>


            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
          >
          {!dayShow &&
          <PeriodicDailySales avgItemTr='1' dayShow ={dayShow} weekShow ={weekShow} monthShow={monthShow}
              threeMonthsShow={threeMonthsShow} totalSales={dailyTotalSales}/>  }
            </Grid>

          <Grid
              item
              lg={6}
              md={6}
              xl={6}
              xs={6}
            >

          <SalesByProvince  dayShow ={dayShow} weekShow ={weekShow} monthShow={monthShow}
              threeMonthsShow={threeMonthsShow} />
            </Grid>

          <Grid
              item
              lg={6}
              md={6}
              xl={6}
              xs={6}
            >
            <SalesByDistricts  dayShow ={dayShow} weekShow ={weekShow} monthShow={monthShow}
              threeMonthsShow={threeMonthsShow} />
        </Grid>

            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >

          <BudgetRates dayShow ={dayShow} weekShow ={weekShow} monthShow={monthShow} threeMonthsShow={threeMonthsShow}
            />

          </Grid>




          </Grid>
        </Container>
      </Box>
  </>
)
    };

export default PresetSales;