import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from "@material-ui/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { TextareaAutosize } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import FlagIcon from '@mui/icons-material/Flag';
import {FaQuestion,FaComment} from 'react-icons/fa'
import { createTheme} from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { addRow, removeRow, setRows } from 'redux/suspiciousRow';


const theme = createTheme();
const useStyles = makeStyles(theme => ({
    row: {
      width: 700,
      backgroundColor: 'grey'
    },
    cell_long: {
      width: 160
    },
    disabledButton: {
      backgroundColor: '#cfcfcf',
  }
  }));

function Row({key,row,result,id}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState(row.status);
  const [tempStatus, setTempStatus] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  //const [statusOptions, setStatusOptions] = React.useState(row.statusOptions);
  const [commentValue, setCommentValue] = React.useState(row.comment);
  const dispatcher = useDispatch();

    const handleClose =() =>{
    setDialogOpen(false)
  }

  const onChange = (e, row ) => {
    const value = e.target.value;
    setCommentValue(value)
     //setSuspicious(newRows)
    //props.sendDataToParent(false,newRows)
  };

  const saveStatus =(e,row) =>{
    setDialogOpen(false)
    setStatus(tempStatus)
   const rowToupdate = result.find(element => element.id == row.id)
   dispatcher(addRow({id:id, row: rowToupdate, comment:rowToupdate.comment,status:tempStatus }))
  }

  const saveComment =(comment,row) =>{
   const rowToupdate = result.find(element => element.id == row.id)
  dispatcher(addRow({id:id, row: rowToupdate, comment:comment,status:rowToupdate.status }))
  }

  const openDialog=(e) =>{
    setTempStatus(e.currentTarget.value)
    setDialogOpen(true)
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="left">{row.transactionId}</TableCell>
        <TableCell align="left">{row.date}</TableCell>
        <TableCell align="left">{row.total}</TableCell>
        <TableCell align="left">{row.store}</TableCell>
        <TableCell align="left"> <TextareaAutosize
                                    aria-label="comment"
                                    value ={commentValue}
                                    onChange = {(e) =>onChange(e,row)}
                                    style={{ width: 250,height:60 }}
                                  />
                                  <Button id="comment" sx={{paddingBottom:2}} onClick={() =>saveComment(commentValue,row)}>
                                  <FaComment />
                                  </Button></TableCell>

        <TableCell align="left">{status}</TableCell>

        <TableCell align="left">
          { id==0 ?
            (
              <>
                <Tooltip title="cleared">
                  <span>
                     <Button value="Cleared" disabled={!row.statusOptions.clearedEnabled} onClick={(e) => openDialog(e)}>
                       <VerifiedIcon />
                     </Button>
                  </span>
                </Tooltip>
                <Tooltip title="suspicious">
                  <span>
                    <Button value="Suspicious" disabled = {!row.statusOptions.suspiciousEnabled} classes={{disabled:classes.disabledButton}}
                      onClick={ (e) =>openDialog(e)}>
                      <FaQuestion />
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title="fraud">
                  <span>
                    <Button value="Fraud" disabled = {!row.statusOptions.fraudEnabled} onClick={(e) => openDialog(e)}>
                       <FlagIcon/>
                    </Button>
                  </span>
                </Tooltip>
                </>
            ) :
            (
            <>
              <Tooltip title="cleared">
                <span>
                   <Button value="Cleared" disabled={!row.statusOptions.clearedEnabled} onClick={(e) => openDialog(e)}>
                     <VerifiedIcon />
                   </Button>
                </span>
              </Tooltip>
              <Tooltip title="suspicious">
                <span>
                  <Button value="Suspicious" disabled = {!row.statusOptions.suspiciousEnabled} classes={{disabled:classes.disabledButton}}
                    onClick={ (e) =>openDialog(e)}>
                    <FaQuestion />
                  </Button>
                </span>
              </Tooltip>
              </>
            )
          }

        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Transaction Information
              </Typography>
              <Table size="medium" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Time</TableCell>
                    <TableCell align="left">Cashier</TableCell>
                    <TableCell align ="left">Contact</TableCell>
                    <TableCell align ="left">Flagged</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>

                    <TableRow key={row.date}>
                      <TableCell align="left">{row.time}</TableCell>
                      <TableCell align="left">{row.cashierName}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.reason}</TableCell>

                    </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ margin: 1, marginTop:3}}>
              <Typography variant="h6" gutterBottom component="div">
                Transaction Detail
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Item</TableCell>
                    <TableCell align="left">Id</TableCell>
                    <TableCell align ="left">Price</TableCell>
                    <TableCell align="left">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((detailRow) => (
                    <TableRow key={detailRow.date}>
                      <TableCell align="left">{detailRow.itemName}</TableCell>
                      <TableCell align="left">{detailRow.itemId}</TableCell>
                      <TableCell align="left">{detailRow.itemPrice}</TableCell>
                      <TableCell align="left">
                        {detailRow.description}
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Change Status"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to change the status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={(e) => saveStatus(e,row)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    transactionId: PropTypes.number.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    time: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        itemPrice: PropTypes.number.isRequired,
        itemName: PropTypes.string.isRequired,

      }),
    ).isRequired,
    store: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
};

export default function CollapsibleTable({id}) {
  const [updateTable, setUpdateTable] = React.useState(false);
  //const [result, setResult] = React.useState([]);
  const Rows = useSelector(state => state.suspiciousRow)
  return (
   <React.Fragment>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align ="left">Transaction Id</TableCell>
            <TableCell align="left">Date</TableCell>

            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">Store</TableCell>

            <TableCell align="left"> Comment</TableCell>
            <TableCell align="left"> Status </TableCell>
            <TableCell align="left"> Change To</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Rows[id].rows.map((row) => (
            <Row key={row.transactionId} row ={row} result={Rows[id].rows} id={id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </React.Fragment>
  );
}