
import React , { useState,useEffect,SyntheticEvent} from 'react';
import {Grid,
  TextField,
  Box,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Container,
  CardContent} from '@material-ui/core';
  import { RootState } from 'redux/store';

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Input from '@material-ui/core/Input';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import { useDispatch } from 'react-redux';
import {setSalesParams} from 'redux/searchOptions';
import { useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';


const formControl = {
  margin: 2,
  minWidth: 160,
  maxWidth: 800,

}
const DatePickerCSS ={
  width: "80%",
  maxWidth:50,
  marginRight:'auto'
  }
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  }};

export default function Searchoptions() {
 const [options,setOptions] =useState([])
 const [storeEnabled, setStoreEnabled] = useState(true)
 const [submitEnabled, setSubmitEnabled] = useState(false);
 const DateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
 const dispatcher = useDispatch()

const newState  = useSelector((state: RootState) => state.salesParams.values)
const status = JSON.parse(JSON.stringify(newState));
 console.log(newState)
  const paymentTypes =  ['Cash',
                         'Debit',
                         'Visa',
                         'Master',
                         'American Express',
                         'Gift Card'
                        ]

  const districts = ['1','2','5','22','23','24','25','26']
  const provinces = ['MB','NB','NL','NS','ON','QC','SK']
  const isAllPaymentSelected =  paymentTypes.length > 0 && newState.paymentMethod.length === paymentTypes.length;
  const isAllDistrictSelected = districts.length > 0 &&  newState.districts.length === districts.length;
  const isAllProvinceSelected = provinces.length > 0 &&  newState.provinces.length === provinces.length;
  const isAllStoresSelected =  options.length > 0 && newState.selectedStores.length === options.length;

 useEffect(() => {
      axios.get('/api/storesDetail')
        .then(res => {
          setOptions(res.data);
        });
      },[]);

const handleStartDateChange =(date : Date|null) =>{
  status.startDate = date?.toISOString()
  dispatcher(setSalesParams(status))

}

const handleEndDateChange =(date: Date|null) =>{
  status.endDate = date?.toISOString()
  dispatcher(setSalesParams(status))
}

const handleChange = (event: SelectChangeEvent<unknown>) => {
  const value = event.target.value as string;
  setSubmitEnabled(true)
  if (value[value.length - 1] === "all") {
    status.selectedStores = (newState.selectedStores.length === options.length ? [] : options);
    status.isAllStoresSelected = true
    dispatcher(setSalesParams(status))
    return;
  }
  status.selectedStores = value
  status.isAllStoresSelected = false
  dispatcher(setSalesParams(status))
}

const handleDistrict = (event: SelectChangeEvent<unknown>) => {
  const value = event.target.value as string;
  setSubmitEnabled(true)
  if (value[value.length - 1] === "all") {
    status.districts = (newState.districts.length === districts.length ? [] : districts);
    dispatcher(setSalesParams(status));
    return;
  }
  status.districts = value
  dispatcher(setSalesParams(status))
};

const handleProvince = (event:SelectChangeEvent<unknown>) => {
  const value = event.target.value as string;
  if (value[value.length - 1] === "all") {
    status.provinces = (newState.provinces.length === provinces.length ? [] : provinces);
    dispatcher(setSalesParams(status));
    return;
  }
  status.provinces = value
  dispatcher(setSalesParams(status))
  setSubmitEnabled(true)
};

function handleSubmit(event:SyntheticEvent) {
  event.preventDefault();
  status.salesShow = true;
  dispatcher(setSalesParams(status))
}

const [salesOption, setSalesOption] = useState('Store');
const [salesType, setSalesType] = useState('store');

const handleSort= (event:SelectChangeEvent<unknown>) =>{
  status.salesType = event.target.value
  dispatcher(setSalesParams(status))
}

const handleRadio = (event:SelectChangeEvent<unknown>) => {
    setSalesOption(event.target.value as string);
    if (event.target.value == 'Province'){
      status.provinceEnabled = true
      status.districtEnabled = false
      setStoreEnabled(false)
    }
    if (event.target.value == 'District'){
      status.provinceEnabled = false
      status.districtEnabled = true
      setStoreEnabled(false)
    }
    if (event.target.value == 'Store'){
      status.provinceEnabled = false
      status.districtEnabled = false
      setStoreEnabled(true)
    }
    status.selectedStores.pop()
    status.districts.pop()
    status.provinces.pop()
    dispatcher(setSalesParams(status))
  };
 return (
  <Container maxWidth={false}>

<form  noValidate onSubmit={handleSubmit}>
<Box pt={3} pb={3}>
  <Card>
    <CardContent>
      <Box  >
        <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={3}
              xs={3}
            >

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label = "Start Date"
            minDate = {new Date(2019, 0, 1)}
            maxDate ={new Date()}
            value={new Date(newState.startDate).toLocaleDateString()}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
            >
            </DatePicker>

          </LocalizationProvider>
          </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label = "End Date"
                    minDate = {new Date(2019, 0, 1)}
                    maxDate={new Date()}
                    value={new Date(newState.endDate).toLocaleDateString()}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    >
                  </DatePicker>
                </LocalizationProvider>

          </Grid>
              <Grid
                item
                md={3}
                xs={4}
              >
              <FormControl component="fieldset">
                  <FormLabel component="legend">Sales Type:</FormLabel>
                    <RadioGroup row aria-label="salesSort" name="salesSort" value={salesType} onChange={handleSort}>
                      <FormControlLabel value="store" control={<Radio />} label="Store" />
                      <FormControlLabel value="commercial" control={<Radio />} label="Commercial" />
                      <FormControlLabel value="online" control={<Radio />} label="Online" />
                    </RadioGroup>
              </FormControl>
            </Grid>
              <Grid
                item
                md={3}
                xs={4}
              >
              <FormControl component="fieldset">
                  <FormLabel component="legend">Sales by:</FormLabel>
                    <RadioGroup row aria-label="searchOption" name="searchOption" value={salesOption} onChange={handleRadio}>
                      <FormControlLabel value="Store" control={<Radio />} label="Store" />
                      <FormControlLabel value="District" control={<Radio />} label="District" />
                      <FormControlLabel value="Province" control={<Radio />} label="Province" />
                    </RadioGroup>
              </FormControl>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          >
            <Box pt={5}>
            <FormControl sx={{ margin: 2,
                               minWidth: 160,
                               maxWidth: 800}} >
              <InputLabel id="all-stores-mutiple-checkbox-label">Stores</InputLabel>
                <Select
                  labelId="all-stores-mutiple-checkbox-label"
                  id="allstores-mutiple-checkbox"
                 sx ={{
                  marginTop: 2,
                  width:600
                 }}
                  multiple
                  value={newState.selectedStores}
                  disabled = {!storeEnabled}
                  onChange={handleChange}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      value="all"
                  >
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllStoresSelected}
                          indeterminate={
                            newState.selectedStores.length > 0 && newState.selectedStores.length < options.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Select All"
                      />
                    </MenuItem>
                  {options.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={newState.selectedStores.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
              </Box>
        </Grid>
        <Grid
            item
            md={2}
            xs={6}
            >
              <Box pt={5}>
              <FormControl
                    sx= {{ margin: 2,
                           minWidth: 160,
                          maxWidth: 800}}
                          disabled = {!newState.districtEnabled}>
              <InputLabel id="payment-mutiple-checkbox-label">Districts</InputLabel>
                <Select
                  labelId="districts-mutiple-checkbox-label"
                  id="districts-mutiple-checkbox"
                  sx ={{ marginTop: 2,
                         minWidth:150}}
                  multiple
                  value={newState.districts}
                  onChange={handleDistrict}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                   <MenuItem
                      value="all"
                   >
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllDistrictSelected}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Select All"
                      />
                    </MenuItem>
                  {districts.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={newState.districts.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
              </Box>
          </Grid>
          <Grid
            item
            md={3}
            xs={3}
            >
              <Box pt={5}>
              <FormControl sx ={formControl} disabled={!newState.provinceEnabled}>
              <InputLabel id="province-mutiple-checkbox-label">Province</InputLabel>
                <Select
                  labelId="province-mutiple-checkbox-label"
                  id="province-mutiple-checkbox"
                 sx ={{ marginTop: 2,
                  minWidth:150}}
                  multiple
                  value={newState.provinces}
                  onChange={handleProvince}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                   <MenuItem
                      value="all"
                   >
                      <ListItemIcon>
                        <Checkbox
                          checked={isAllProvinceSelected}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Select All"
                      />
                    </MenuItem>
                  {provinces.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={newState.provinces.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
              </Box>
          </Grid>

          <Box pt={5}>
            <Grid item style={{ marginTop: 32}}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!submitEnabled}
                        >
                          Submit
                        </Button>
                      </Grid>
          </Box>
        </Grid>
      </Box>
    </CardContent>
  </Card>
  </Box>
</form>

</Container>
 )
}



