import React, {useState,useEffect, useRef} from 'react';
import { CardContent,Container,Grid,Card,Box,
         TextField,
         Typography } from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import {  Line } from 'react-chartjs-2';

import NumberOfStores from 'components/Card/GeneralCard';
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch, useSelector } from 'react-redux';
import {setSubmitted} from 'redux/productParams';
import {updateProductloader} from 'redux/loaders';
import { setChartData } from 'redux/productParams';
import {Bars} from 'react-loader-spinner';
import Backdrop from '@material-ui/core/Backdrop';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const StoreProductSales = ({startDate,endDate,productsName})=>{

  const choices = useSelector(state => state.productSales)
  const multipleSelect = useSelector(state => state.selectedStores)
  const loader = useSelector(state => state.loaders.productLoader)

  const durationToDisplay = useSelector(state => state.dropDown.duration.slice )
  const submitPressed = useSelector(state => state.productSales.submitted )
  const dispatcher = useDispatch()
  const [storeSummary, setStoreSummary] = React.useState('');
  const [storeSummaryList, setStoreSummaryList] = React.useState([]);
  const [productSummary, setProductSummary] = React.useState('');
  const [productSummaryList, setProductSummaryList] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState('');
  const [totalQuantity, setTotalQuantity] = React.useState('');
  const [durationChanged, setDurationChanged] = React.useState(false);
  const [storesChanged, setStoresChanged] = React.useState(false);

  const IfMounted = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };

  const didMounted = IfMounted()

  useEffect(() => {
    if(!didMounted && storesChanged){
    setStoreSummary(multipleSelect.stores.items[0])
    setProductSummary(choices.products[0])
  }
    setProductSummaryList(choices.products)
    setStoreSummaryList(multipleSelect.stores.items)
    if(multipleSelect.stores.perItems){
      setStoreSummaryList(['All',...multipleSelect.stores.items])
    }
    else {
      if (multipleSelect.stores.allItemsSelected){
        setStoreSummaryList(['All',...multipleSelect.stores.allItems])

      }
    }
    setStoresChanged(false)
  },[choices.chartData])

  useEffect(() => {
    if(!didMounted ){
    setDurationChanged(true)
    }
  },[durationToDisplay])

  useEffect(() => {

    setStoresChanged(true)

  },[multipleSelect.stores.items, choices.products])

  useEffect(() => {
    if(!didMounted) {
      const totalValues = findProductInArray(choices.chartData.total_sales, storeSummary,productSummary)
      if (totalValues.length >0 ){
        setTotalAmount(totalValues[0].item_price);
        setTotalQuantity(totalValues[0].quantity)
        // setTransactions(result.data);
      }
      else {
        setTotalAmount(0);
        setTotalQuantity(0)
      }
    }
  },[productSummary,storeSummary, choices.chartData])

  useEffect(() => {
    const loadData = async () => {
    dispatcher(updateProductloader(true))
    const result = await axios
      .get("/api/productStoreSales/stores_products_sales",
      {
        params: {
          startDate:startDate,
          endDate:endDate,
          products:productsName,
          storeId: multipleSelect.stores.items,
          allStores: multipleSelect.stores.allItemsSelected,
          perStore:  multipleSelect.stores.perItems,
          resample : durationToDisplay
        },
        paramsSerializer: params => {
          return qs.stringify(params,{arrayFormat: 'repeat'})
        }
      })
      dispatcher(updateProductloader(false))
      dispatcher(setSubmitted(false))
      dispatcher(setChartData(result.data))
      setDurationChanged(false)
      // setTransactions(result.data);
    }


    loadData()
  }, [submitPressed,durationToDisplay]);
  console.log(choices.chartData)

  const findProductInArray =(array, store, product) =>{
    if (store !='All'){
    const number = parseInt(store.substring(0,store.indexOf('-')))
    const arr = array.filter(obj => obj.store_number === number && obj.sap_id === product)
    return arr
     }
    else if (array.length ==1 ){
      const arr = array.filter(obj => obj.sap_id === product)
      return arr
    }
    else{
      const arr = array.filter(obj => obj.sap_id === product && !('store_number' in obj))
      return arr
    }

  }

  const handleChange = (event) => {
    setStoreSummary(event.target.value);
  };

  const handleProductChange = (event) => {
    setProductSummary(event.target.value);
  };

  return (
    <Box sx={{marginRight:3 }}>
      <Grid item xs={12} sm={12} md={12 }>
       {   loader &&
            <Backdrop style={{zIndex: 2}} open={open} >
              <Bars type="Watch" color='#862D23' height="100" width="100" />
            </Backdrop>
            }

    </Grid>
    <Grid
        container
        item
      >
      <Grid item xs={12} sm={12} md={12 }>
      <Card sx={{marginLeft:3, marginTop: 2}}>
      <CardContent>
      <Grid container sx={{ pt:2, pb:4}} >
      <Grid item xs>
      <Typography variant="h6" ml={3} pt={4}> Summary</Typography>
      </Grid>
      <Grid item xs>
      <FormControl variant="standard" sx={{ m: 3, minWidth: 120 }}>
      <Select
          labelId="product list"
          id="product-list-select"
          value={productSummary}
          onChange={handleProductChange}
          label="Age"
        >

          {productSummaryList.map(item => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </Select>
        <FormHelperText>Product </FormHelperText>
        </FormControl>
        </Grid>

      <Grid item xs>
      <FormControl variant="standard" sx={{ m: 3, minWidth: 120 }}>
      <Select
          labelId="store list"
          id="store-list-select"
          value={storeSummary}
          onChange={handleChange}
          label="stores"
        >
          {storeSummaryList.map((item) => {
              return  <MenuItem value={item}>{item}</MenuItem>} ) };
          </Select>
          <FormHelperText>Store </FormHelperText>
        </FormControl>
        </Grid>

      <Grid item xs sx={{ m: 3}}>
      <TextField
        id="total-quantity"
        helperText="Total Quantity"
        value = {totalQuantity}
        variant="standard"

      />
      </Grid>
      <Grid item xs sx={{ m: 3}}>
      <TextField
        id="total-amount"
        helperText="Total Amount"
        defaultValue="total amount"
        value = {totalAmount}
        variant="standard"
      />
      </Grid>
      </Grid>

      </CardContent>
      </Card>
      </Grid>
      </Grid>



    <Grid
      item
      xs={12} sm={12} md={12 }
    >

    <Box pt={5}>
   <Card>
        < NumberOfStores title ={'Sold Amount'} contentText= {['Daily','Weekly','Monthly']}/>
       <CardContent>
       <Grid item xs={12} sm={12} md={12 }>
   < Line data={choices.chartData.amount} options={{
            title:{
              display:true,
              text:'Sales',
              fontSize:16
            },
            legend:{
              display:true,
              position:'top'
            }
          }}/>
     </Grid>
     <Box sx={{pt:3}}>
<Card>
  <CardContent  >
<Grid item xs={12} sm={12} md={12 }>
<Typography sx={{ fontSize: 16 }} gutterBottom>
          Sold Quantity
        </Typography>
< Line data={choices.chartData.quantity} options={{
            title:{
              display:true,
              text:'Sales',
              fontSize:16
            },
            legend:{
              display:true,
              position:'top'
            }
          }}/>
   </Grid>
   </CardContent>
   </Card>
   </Box>

   </CardContent>

   </Card>

   </Box>
   </Grid>



   </Box>
  );
}
export default StoreProductSales;
