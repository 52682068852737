import useIdleTimer from "react-idle-timer";
import React from 'react';
import { useNavigate } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
export default function(){
    const idleTimer = React.useRef(null)
    const [open,setOpen] = React.useState(false)

      let navigate= useNavigate()
      function onIdle (){
        setOpen(true)
      }

      function logout (){
        navigate('/')
      }

      const onActive= () => {
        setOpen(false)
      };
      const handleClose = () => {
        setOpen(false);
      };

      return (
        <>
          <useIdleTimer
            ref={idleTimer}
            onActive={onActive}
            onIdle={onIdle}
            debounce={250}
            timeout={12000000}
          />
           <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="idle-timeout">{"Idle Timeout"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="idle-dialog-description">
                   You were inactive for 5 minutes, logout or stay?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={logout} >
                    logout
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                    stay
                </Button>
                </DialogActions>
            </Dialog>
        </>
      );
    }
