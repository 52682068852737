import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { makeStyles } from "@material-ui/styles";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();
const useStyles = makeStyles(theme => ({
    row: {
      width: 700,
      backgroundColor: 'grey'
    },
    cell_long: {
      width: 160
    },
    disabledButton: {
      backgroundColor: '#cfcfcf',
  }
  }));

function Row(props) {
  const classes = useStyles();
  const { row } = props;
  const [open, setOpen] = React.useState(false);
   return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.transactionId}</TableCell>
        <TableCell align="left">{row.date}</TableCell>
        <TableCell align="left">{row.total}</TableCell>
        <TableCell align="left">{row.store}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Transaction Information
              </Typography>
              <Table size="medium" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Time</TableCell>
                    <TableCell align="left">Cashier</TableCell>
                    <TableCell align ="left">Contact</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={row.date}>
                      <TableCell align="left">{row.time}</TableCell>
                      <TableCell align="left">{row.cashierName}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ margin: 1, marginTop:3}}>
              <Typography variant="h6" gutterBottom component="div">
                Transaction Detail
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Item</TableCell>
                    <TableCell align="left">Id</TableCell>
                    <TableCell align ="left">Weight</TableCell>
                    <TableCell align="left">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.details.map((detailRow) => (
                    <TableRow key={detailRow.itemId}>
                      <TableCell align="left">{detailRow.name}</TableCell>
                      <TableCell align="left">{detailRow.itemId}</TableCell>
                      <TableCell align="left">{detailRow.weight}</TableCell>
                      <TableCell align="left">{detailRow.price}</TableCell>
                      <TableCell align="left">
                        {detailRow.description}
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    transactionId: PropTypes.number.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    time: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        itemPrice: PropTypes.number.isRequired,
        itemName: PropTypes.string.isRequired,

      }),
    ).isRequired,
    store: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
};


export default function ReportTable(props) {

    return (
   <React.Fragment>

    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align ="left">Transaction Id</TableCell>
            <TableCell align="left">Date</TableCell>

            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">Store</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.result.map((row) => (
            <Row key={row.transactionId}  row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </React.Fragment>
  );
}