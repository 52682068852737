
import {Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Container}
  from '@material-ui/core'

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Input from '@material-ui/core/Input';
import { useDispatch, useSelector } from 'react-redux';
import { setItems,setAllSelected, reset, perItemsState} from 'redux/dropDownStores';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300
    },
  },
};

export default function StoresDropDown({id,componentType, width}) {
const dispatcher = useDispatch()
const componentParams = useSelector(state => state.stores)
const handleChange = (event) => {
  const value = event.target.value;
  if (value[value.length - 1] === "all") {
    dispatcher(setItems({id: id , Items:componentParams[id].items.length === componentParams[id].itemsList.length ? [] : componentParams[id].itemsList}))
    dispatcher(setAllSelected({id: id,
       allItemsSelected: componentParams[id].items.length === componentParams[id].itemsList.length ? false : true}))

    dispatcher(perItemsState({id: id,
    perItems: componentParams[id].items.length === componentParams[id].itemsList.length ? false : true}))
    return;
  }
  else{
    dispatcher(setItems({id:id, Items :value}))
  }
  if(componentType === 'districts'  && value.length ==0 ){
    dispatcher(reset({id: id}))
    dispatcher(reset({id:id, allItemsSelected: false}))
  }
};

 return (
  <Box sx ={{ml:8}}>
       <FormControl >
              <InputLabel id="stores-mutiple-checkbox-label">{componentType}</InputLabel>
                <Select
                  labelId="stores-mutiple-checkbox-label"
                  id="stores-mutiple-checkbox"
                 sx ={{
                  marginTop: 8,
                  marginBottom: 4,
                  width:{width}
                 }}
                  multiple
                  value={componentParams[id].items}
                  disabled = {componentParams[id].disabled}
                  onChange={handleChange}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  { componentParams[id].itemsList.length >0 &&
                  <MenuItem
                      value="all"
                  >
                      <ListItemIcon>
                        <Checkbox
                          checked={componentParams[id].allItemsSelected}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Select All"
                      />
                    </MenuItem> }
                    {componentParams[id].itemsList.map((item) => (
                    <MenuItem key={item} value={item}>
                        <Checkbox checked={componentParams[id].items.indexOf(item) > -1} />
                        <ListItemText primary={item} />
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
</Box>
 )
}



