import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent
} from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useDispatch, useSelector } from 'react-redux';

import ProductsListForm from 'components/product/ProductListSearch';

import StoreProductSales from 'components/Sales/StoreProductSales';
import DistrictProductSales from 'components/Sales/DistrictProductSales'
import ProvinceProductSales from 'components/Sales/ProvinceProductSales'
import ProductsSearchForm from 'components/product/ProductSearch';
import {useState} from 'react';

import {setAllSelected} from 'redux/dropDownStores'
import { useEffect } from 'react';

const ProductList = () => {
  const [startDate, setStartDate] =useState(new Date());
  const [endDate, setEndDate] =useState(new Date());
  const [storesSelected, setStoresSelected] =useState(null);
  const [productsSelected, setProductsSelected] =useState(null);
  const [salesShow, setSalesShow] =useState(false);
  const [storeSales, setStoreSales] =useState(false);
  const [districtSales, setDistrictSales] =useState(false);
  const [provinceSales, setProvinceSales] =useState(false);
  const dispatcher = useDispatch()

  const sendDataToParent = (startDate,endDate, storesOrDistrictOrProv, productsId,
                            districtEnabled,provinceEnabled) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setStoresSelected(storesOrDistrictOrProv);
    setProductsSelected(productsId)
    setSalesShow(true)
    setStoreSales(!(districtEnabled ||provinceEnabled))
    setDistrictSales(districtEnabled)
    setProvinceSales(provinceEnabled)
  };

const [selectEnabled, setSelectEnabled] = useState(true);


useEffect( () =>{
  dispatcher(setAllSelected({type:'stores',allItemsSelected: true}))
}, []);


const [searchOption, setSearchOption] = useState('Select');

  const handleRadio = (event) => {
    setSearchOption(event.target.value);
    if (event.target.value == 'Search'){
      setSelectEnabled(false)
    }
    if (event.target.value == 'Select'){
      setSelectEnabled(true)

    }

  };

  return (
  <>
    <Helmet>
      <title> Product sales|Nutriart</title>
    </Helmet>
    <Box ml={3} pt={1} pr={3}>
  <Card>
    <CardContent>
      <Box pt={1} pb={1}>
        <Grid
            container
            xs={11}
            md={11}
            spacing={1}
          >

            <Grid
              item
              md={11}
              xs={11}
            >
           <FormControl component="fieldset">
                  <FormLabel component="legend">Product Sales by:</FormLabel>
                    <RadioGroup row aria-label="searchOption" name="searchOption" value={searchOption} onChange={handleRadio}>
                      <FormControlLabel value="Search" control={<Radio />} label="Search products" />
                      <FormControlLabel value="Select" control={<Radio />} label="Select from list" />

                    </RadioGroup>
              </FormControl>
          </Grid>
        </Grid>
      </Box>
    </CardContent>
  </Card>

  </Box>
  <Box pt={2} >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
           {selectEnabled && <ProductsListForm sendDataToParent={sendDataToParent} /> }
           {!selectEnabled && <ProductsSearchForm sendDataToParent={sendDataToParent} /> }
          </Grid>

            {salesShow &&  storeSales && <StoreProductSales startDate={startDate}
              endDate={endDate} storeId={storesSelected} productsName={productsSelected}/>}
            {salesShow &&  districtSales && <DistrictProductSales startDate={startDate} endDate={endDate}
              districtId={storesSelected} productsName={productsSelected} DistrictDefaultStore={[]}/>}
            {salesShow &&  provinceSales && <ProvinceProductSales startDate={startDate} endDate={endDate}
              province={storesSelected} productsName={productsSelected} provinceDefaultStore={[]} />}
         </Box>
  </>
  );
    }

export default ProductList;
