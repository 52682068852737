import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface multipleSelectProperties {
    items: string[],
    itemsList: string[],
    allItems: string [],
    allItemsSelected: boolean,
    perItems: boolean,
    disabled: boolean,
    submitted: boolean,
    reloadItemsList: boolean
}

const initialState: multipleSelectProperties = {
    items: [],
    itemsList: [],
    allItems: [],
    allItemsSelected: false,
    perItems: false,
    disabled: true,
    submitted: false,
    reloadItemsList: false
  }

  const initialStates = new Array(3).fill(initialState)


export const DropdownGenericSlice = (sliceName: string) => createSlice({
    //name:'selectedStores',
    name : sliceName,
    initialState: initialStates,
    reducers:{
        setItems :(state, { payload }: PayloadAction<{ id: number, Items: string[] }>) => {
            const { id, Items } = payload;
              state[id].items = Items;
            },
        setItemsList :(state, { payload }: PayloadAction<{ id: number, ItemsList: string[] }>) => {
            const { id, ItemsList } = payload;
              state[id].itemsList = ItemsList;
            },
        setAllSelected:(state, { payload }: PayloadAction<{ id: number, allItemsSelected: boolean }>) => {
            const { id, allItemsSelected } = payload;
            state[id].allItemsSelected = allItemsSelected;
            },
        setAllItems:(state, { payload }: PayloadAction<{ id: number, allItems: string[] }>) => {
            const { id, allItems} = payload;
            state[id].allItems = allItems;
            },
        disable:(state, { payload }: PayloadAction<{ id: number, disabled: boolean }>) => {
            const { id, disabled} = payload;
            state[id].disabled = disabled;
            },
        reset :(state, { payload }: PayloadAction<{ id: number }>) => {
            state[payload.id].items = [];
            },
        resetItemsList :(state, { payload }: PayloadAction<{ id: number }>) => {
            state[payload.id].itemsList = [];
            },
        reloadItemsList:(state, { payload }: PayloadAction<{ id: number, reloadItemsList: boolean }>) => {
            const { id, reloadItemsList} = payload;
            state[id].disabled = reloadItemsList;
            },
        perItemsState :(state, { payload }: PayloadAction<{ id: number, perItems: boolean }>) => {
            const { id, perItems} = payload;
            state[id].perItems = perItems;            }
    }
})
{/*

*/}
//export default storesDropdownSlice.reducer

export default DropdownGenericSlice

