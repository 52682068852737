import React, {useState,useEffect} from 'react';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Input from '@material-ui/core/Input';
import qs from 'qs';


import { Line } from 'react-chartjs-2';
import {Grid,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Container,
  CardContent,
Card} from '@material-ui/core'

import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme();
const formControl ={
  margin: 1,
  minWidth: 120,
  maxWidth: 200,
}
const DatePicker ={
  width: "80%",
  minWidth:50,
  marginRight:'auto'
  }

const   selectEmpty ={
    marginTop: 2,
    minWidth:120
  }

const   selectMultiple ={
    marginTop: 2,
    minWidth:200
  }



const DistrictSales = ({startDate,endDate,districtId, DistrictDefaultStore})=>{

  const [districtStores,setDistrictStores]= useState([])
  const [transactions,setTransactions]= useState([])
  const [districtSales,setDistrictSales]= useState([])
  const [storeSales,setStoreSales]= useState([])
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [storePaymentMethod, setStorePaymentMethod] = useState([]);
  const [allStoresSelected, setAllStoresSelected] = useState(false);
  const [districtFlag, setDistrictFlag] = useState(true);

  const paymentTypes =  ['Cash',
                         'Debit',
                         'Visa',
                         'Master',
                         'American Express',
                         'Gift Card'
                        ]

  const isAllStorePaymentSelected =  paymentTypes.length > 0 && storePaymentMethod.length === paymentTypes.length;
  const isAllDistrictStoresSelected =  transactions.length > 0 && districtStores.length === transactions.length;
  const isAllPaymentSelected =  paymentTypes.length > 0 && paymentMethod.length === paymentTypes.length;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };
  useEffect(() => {
        axios.get("/api/districtStores/",
        {
          params: {
            districtId:districtId
          },
          paramsSerializer: params => {
            return qs.stringify(params,{arrayFormat: 'repeat'})
          }
        })
        .then(res => {
          setTransactions(res.data);
        });
      },[districtId]);

  useEffect(() => {
      axios.get("/api/districtSales/",
      {
        params: {
          districtId:districtId,
          startDate:startDate,
          endDate:endDate,
          paymentType:paymentMethod
        },
        paramsSerializer: params => {
          return qs.stringify(params,{arrayFormat: 'repeat'})
        }
      }).then(res => {
        setDistrictSales(res.data);
      });
  }, [startDate,endDate,districtId,paymentMethod]);

  useEffect(async () => {
    const result = await axios.get("/api/purchase/",
    {
      params: {
        storeId:districtStores,
        startDate:startDate,
        endDate:endDate,
        paymentType:storePaymentMethod,
        allStores: allStoresSelected,
        districtFlag:districtFlag
      },
      paramsSerializer: params => {
        return qs.stringify(params,{arrayFormat: 'repeat'})
      }
    })
      setStoreSales(result.data);
  },[districtStores,storePaymentMethod,startDate,endDate]);

  const handleDistrictStores = (event) => {
    const value = event.target.value;
      if (value[value.length - 1] === "all") {
        setDistrictStores(districtStores.length === transactions.length ? [] : transactions);
        setAllStoresSelected(true)
        return;
      }
      setDistrictStores(value);
      setAllStoresSelected(false)
  };

  const handlePaymentType = (event) => {
    const value = event.target.value;
      if (value[value.length - 1] === "all") {
        setPaymentMethod(paymentMethod.length === paymentTypes.length ? [] : paymentTypes);
        return;
      }
      setPaymentMethod(value);
  };

  const handleStorePaymentType = (event) => {
    const value = event.target.value;
      if (value[value.length - 1] === "all") {
        setStorePaymentMethod(storePaymentMethod.length === paymentTypes.length ? [] : paymentTypes);
        return;
      }
      setStorePaymentMethod(value);
  };

  return (
    <Container maxWidth={false}>
    <Grid
    container
      spacing={1}
    >
      <Grid
        item
        md={12}
        xs={12}
      >

        <Box ml={3}>

          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Box pt={1} pb={3} >

            <FormControl sx ={formControl} >
              <InputLabel id="payment-mutiple-checkbox-label">Payment type</InputLabel>
                <Select
                  labelId="paymentMethod-mutiple-checkbox-label"
                  id="paymentType-mutiple-checkbox"
                  sx ={selectMultiple}
                  multiple
                  value={paymentMethod}
                  onChange={handlePaymentType}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      value="all"
                  >
                      <ListItemIcon>
                        <Checkbox

                          checked={isAllPaymentSelected}
                          indeterminate={
                            paymentMethod.length > 0 && paymentMethod.length < paymentTypes.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText

                        primary="Select All"
                      />
                    </MenuItem>
                  {paymentTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={paymentMethod.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
             </Box>

            <Grid
                item
                md={12}
                xs={12}
              >
               < Line data={districtSales.sales} options={{
                title:{
                  display:true,
                  text:'DistrictSales',
                  fontSize:16
                },
                legend:{
                  display:true,
                  position:'top'
                }
              }}/>
            </Grid>
    </CardContent>
      </Card>
        </Box>
          </Grid>
            </Grid>
            <Box pt={3} pl={3}>
            <Grid
                item
                md={12}
                xs={12}
              >
    <Card>

    <CardContent>
    <Box pt={3} pl={3}>
            <Grid
    container
      spacing={1}
    >

              <Grid
              item
              md={6}
              xs={6}
            >
              <FormControl sx ={formControl} >
              <InputLabel id="district-stores-mutiple-checkbox-label">District Stores</InputLabel>
                <Select
                  labelId="district-stores-mutiple-checkbox-label"
                  id="districtstores-mutiple-checkbox"
                  sx ={selectMultiple}
                  multiple
                  value={districtStores}
                  onChange={handleDistrictStores}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      value="all"
                  >
                      <ListItemIcon>
                        <Checkbox

                          checked={isAllDistrictStoresSelected}

                        />
                      </ListItemIcon>
                      <ListItemText

                        primary="Select All"
                      />
                    </MenuItem>
                  {transactions.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={districtStores.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                </Grid>

             <Box pt={1} pb={3} >

              <Grid
              item
              md={3}
              xs={3}
            >
               <FormControl sx={formControl} >
              <InputLabel id="payment-stores-checkbox-label">Payment type</InputLabel>
                <Select
                  labelId="payment-mutiple-checkbox-label"
                  id="paymentStores-mutiple-checkbox"
                  sx={selectMultiple}
                  multiple
                  value={storePaymentMethod}
                  onChange={ handleStorePaymentType}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                      value="all"

                  >
                      <ListItemIcon>
                        <Checkbox

                          checked={isAllStorePaymentSelected}

                        />
                      </ListItemIcon>
                      <ListItemText

                      />
                    </MenuItem>
                  {paymentTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={storePaymentMethod.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
                </Grid>

             </Box>

            <Grid
                item
                md={12}
                xs={12}
              >
              <Box pt={2}>

               < Line data={storeSales} options={{
                title:{
                  display:true,
                  text:'District Store Sales',
                  fontSize:16
                },
                legend:{
                  display:true,
                  position:'top'
                }
              }}/>

              </Box>
              </Grid>

          </Grid>
         </Box>
         </CardContent>
         </Card>
        </Grid>
        </Box>
    </Container>

  );
}
export default DistrictSales;
