import { Helmet } from 'react-helmet';
import React from 'react'
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import Budget from '../components/dashboard/TotalSales';
import StoresProfitRank from '../components/profit/StoreProfit';


import {Bars} from 'react-loader-spinner';
import Backdrop from '@material-ui/core/Backdrop';
import IdleTimeout from '../components/IdleTimeout/IdleTimeout';
import DateMenu from '../components/Card/CardHeader';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {useNavigate} from 'react-router-dom';

const SalesProfit = () => {
  const [profitLoaded, setProfitLoaded] = React.useState(false)
  const [dayShow, setdayShow] = React.useState(true);
  const [weekShow, setWeekShow] = React.useState(false);
  const [monthShow, setMonthShow] = React.useState(false);
  const [threeMonthsShow, setThreeMonthsShow] = React.useState(false);


  function callbackFunction(promiseInProgress) {
    setProfitLoaded(promiseInProgress)
  }

  const [open, setOpen] = React.useState(true);
  const [dailyTotalSales, setDailyTotalSales] = React.useState({})

  const durationGet =(dayShow,weekShow, monthShow, threeMonthsShow) =>{
    setdayShow(dayShow)
    setWeekShow(weekShow)
    setMonthShow(monthShow)
    setThreeMonthsShow(threeMonthsShow)
}

const GetTotalSales = (TotalSales) => {
    setDailyTotalSales(TotalSales)
}
    let navigate = useNavigate()
    const redirect = () => {
      navigate('/app/dashboard')
    }
  return(
  <>
    <Helmet>
      <title> Profit Report| Nutriart Stores</title>
    </Helmet>
    <IdleTimeout />
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
           <Grid
            item
            lg={10}
            sm={10}
            xl={10}
            xs={10}
          >

             {
           profitLoaded &&
           <Backdrop style={{zIndex: 2}} open={open} >
            <Bars type="Watch" color='#862D23' height="100" width="100" />
           </Backdrop>
            }
            </Grid>


            <Grid
            item
            lg={2}
            sm={2}
            xl={2}
            xs={2}
          >
            <ButtonGroup size="medium" aria-label=" outlined button group">
                <Button onClick={redirect}>Custom Duration</Button>
               </ButtonGroup>
          </Grid>

         <Grid
            item
            lg={2}
            sm={2}
            xl={2}
            xs={2}
          >
          <Budget  avgItemTr='10' text='Profit' isSales= {3} dayShow ={dayShow} weekShow ={weekShow} monthShow={monthShow}
            threeMonthsShow={threeMonthsShow}  GetTotalSales={GetTotalSales} />
          </Grid>

          <Grid
            item
            lg={2}
            sm={2}
            xl={2}
            xs={2}
          >
          <Budget  avgItemTr='11' text='Net Profit Margin' isSales= {4} dayShow ={dayShow} weekShow ={weekShow} monthShow={monthShow}
            threeMonthsShow={threeMonthsShow}  GetTotalSales={GetTotalSales} />
          </Grid>

          <Grid
            item
            lg={2}
            sm={2}
            xl={2}
            xs={2}
          >
            <Card  >
      <CardHeader title='Formula'>

      </CardHeader>

      <Divider />
     <CardContent>

     <>

          <Typography
            color='black'
            variant="h6"
            sx={{mb:2}}
          >
           Profit = Revenu - Costs
          </Typography>

          <Typography
            color="black"
            variant="h6"
          >
           Net Profit Margin =

          </Typography>

          <Typography
            color="black"
            variant="h6"
            sx={{mb:3.5}}
          >
           Revenu - Cost / Revenu

          </Typography>


          </>
       </CardContent>
    </Card>
          </Grid>

          <Grid
            item
            lg={2}
            sm={2}
            xl={2}
            xs={2}
          >
             <DateMenu durationGet={durationGet} showDates ={true}/>
          </Grid>

          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >
          <StoresProfitRank  title={'Stores Profit'} storesOrProductsOrcosts={0} dayShow ={dayShow} weekShow ={weekShow} monthShow={monthShow}
            threeMonthsShow={threeMonthsShow}  GetTotalSales={GetTotalSales}
            callbackFunction={callbackFunction} />
          </Grid>

          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          >

          <StoresProfitRank  title={'Sold Products Profit'} storesOrProductsOrcosts={1}
               dayShow ={dayShow} weekShow ={weekShow} monthShow={monthShow}
               threeMonthsShow={threeMonthsShow}  GetTotalSales={GetTotalSales}
               callbackFunction={callbackFunction} />
          </Grid>


        </Grid>


      </Container>
    </Box>
  </>
)
    };

export default SalesProfit;