import { createSlice } from "@reduxjs/toolkit";

export const ModalSlice = createSlice({
    name: "modalEnable",
    initialState: {
      enabled: false
    },
    reducers: {
      enable: state => {
        state.enabled = true;
      },
      disable: state => {
        state.enabled = false;
      }
    }
  });

  export const {enable,disable} = ModalSlice.actions;
  export default ModalSlice.reducer;