import React from 'react';
import {
    Button,
    Grid,
    Container
    } from '@material-ui/core'
import axios from 'axios';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {disable} from 'redux/modal';
import TransferList from '../Lists/LeftRight';
import Backdrop from '@material-ui/core/Backdrop';
import {Bars} from 'react-loader-spinner';
import {startPosting,endPosting,hasError} from 'redux/skuEdit';
import {dialogShow} from 'redux/dialog'

function AddSku(season) {
  const modalOpen = useSelector(state => state.modalEnable.enabled)
  const dispatcher = useDispatch()
  const editedSkus = useSelector(state=> state.editSkus.seasonSkus)

  const handleClose =() =>{
   dispatcher(disable())
  }

  const isloading = useSelector(state=> state.editSkus.isLoading)


  const postSkus = (data) =>async dispatch => {
    dispatcher(startPosting());
      try{
            const res = await axios.post("/api/productSales/edit_season_skus/",
                                          {skus:data,
                                           season:season['season']});
            dispatch(endPosting());

          } catch (e) {
          dispatcher(hasError(e.message));
        }
        dispatcher(dialogShow())

     }

  const [menuItems, setMenuItems] = React.useState([])
  const [seasonSkus, setSeasonSkus] = React.useState([])
  const [open, setOpen] = React.useState(true)


  const fetchData = () => async dispatch =>{
    dispatcher(startPosting());
    try{
    const product_skus = await axios.all(
      [axios.get('/api/productsList'),
      axios.get('/api/productSales/season_skus',
            {
              params: {
                        season:season
                      }
            })
    ]).then(
      axios.spread((allProducts, seasonProducts) => {
        setMenuItems(allProducts.data);
        setSeasonSkus(seasonProducts.data);
        dispatcher(endPosting())
      })
    )} catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatcher(fetchData())
  },[dispatcher]);

  return (
  <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
        <Grid sx={{ zIndex: 2000, pt: 12 }} item xs={12}>
          {
            isloading &&
              <Backdrop open={open} >
                <Bars type="Watch" color='#862D23' height="100" width="100" />
              </Backdrop>
              }
        </Grid>

        <Grid item xs={12}>
        <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                sx = {{minHeight:'300px'}}
                open={modalOpen}
                onClose={handleClose}>

        <DialogTitle> Edit {season['season'].substring(season['season'].indexOf('-')+1)} Skus</DialogTitle>
        <DialogContent>
        <TransferList menuItems={menuItems} seasonSkus ={seasonSkus}/>

        </DialogContent>
        <DialogActions>
          <Button onClick={postSkus(editedSkus)}>Ok</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>

</Grid>
</Container>
)
}

export default AddSku