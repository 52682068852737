import { createSlice } from "@reduxjs/toolkit";

export const budgetRateSlice = createSlice({
    name: "budgetRates",
    initialState: {
      budget: {rate:{}, sale:{}},
      isLoading: false,
      error: false,
    },
    reducers: {
      startLoading: state => {
        state.isLoading = true;
      },
      hasError: (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      },
      endLoading: state => {
        state.isLoading = false;
      },
      ratesSuccess: (state, action) => {
        state.budget.rate = action.payload.rate;
        state.budget.sale = action.payload.sale;
        state.isLoading = false;
      },
    }
  });

  export const {startLoading,endLoading,hasError,ratesSuccess} = budgetRateSlice.actions;
  export default budgetRateSlice.reducer;