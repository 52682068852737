import {Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme
   } from '@material-ui/core';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import React from 'react';
import NumberOfStores from '../Card/GeneralCard';

import { usePromiseTracker } from "react-promise-tracker";

const StoresProfitRank = ({dayShow,weekShow,monthShow, threeMonthsShow,title,
                           storesOrProductsOrcosts,callbackFunction}) => {
const [stores,setStores] = React.useState(0)
const [showAllStores,setShowAllStores] = React.useState(0)


const sendDataToParent = (numberOfStores, showAllStores) => {
setStores(numberOfStores);
setShowAllStores(showAllStores);
};

  const theme = useTheme();
  const [result, setResult]= React.useState([])
  const { promiseInProgress } = usePromiseTracker();
  const stores_profit_url = ["/api/purchase/stores_profit",
                              "/api/purchase/rank_product_sales"
                            ]
  const [url,setUrl] = React.useState(stores_profit_url[storesOrProductsOrcosts])

  React.useEffect(() => {
    trackPromise(
    axios.get(url,
    {
      params: {
        daily:dayShow,
        weekly:weekShow ,
        monthly:monthShow,
        threeMonths: threeMonthsShow,
        preset: true,
        StoresToReport:stores,
        ReportAll: showAllStores
      }})
    .then(res => {
      setResult(res.data);
      console.log(res.data)
    }));
    },[weekShow,monthShow,threeMonthsShow,stores,showAllStores]);

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: true},
    maintainAspectRatio: false,
    responsive: true,

    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };
  callbackFunction(promiseInProgress)
  return (
    <>

         { storesOrProductsOrcosts === 0 ?
          <Card >
          <NumberOfStores title ={title} sendDataToParent={sendDataToParent}/>
           <Divider />
           <CardContent>
             <Box
               sx={{
                 height: 600,
                 position: 'relative',
                 marginBottom:5
               }}
             >
          <Bar
            data={result}
            options={options}
          />

                   </Box>

          </CardContent>
        </Card> :
      <>
         <Card >
         <NumberOfStores title ={title} sendDataToParent={sendDataToParent}/>
          <Divider />
          <CardContent>
            <Box
              sx={{
                height: 600,
                position: 'relative',
                marginBottom:5
              }}
            >
         <Bar
           data={result.soldProducts}
           options={options}
         />

                  </Box>

         </CardContent>
       </Card>

      <Card >
      <CardHeader title='Products Cost'>

      </CardHeader>

      <CardContent>
        <Box
            sx={{
              height: 600,
              position: 'relative',
              marginBottom:5
            }}
          >
        <Bar
          data={result.purchaseCosts}
          options={options}
        />

      </Box>
      </CardContent>
      </Card>

</>
        }


    </>
  );
};

export default StoresProfitRank;
