import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';

import Menu from "@material-ui/core/Menu";
import Button from '@material-ui/core/Button';

import React from 'react'
import { useEffect } from 'react';
import axios from 'axios';

const DateMenu = ({durationGet,showDates}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dayShow, setDayShow] = React.useState(true);
  const [weekShow, setWeekShow] = React.useState(false);
  const [monthShow, setMonthShow] = React.useState(false);
  const [threeMonthsShow, setThreeMonthsShow] = React.useState(false);
  const [cardText, setCardText] = React.useState('Day')
  const [updatedDate, setUpdatedDate] = React.useState({'updated_date':new Date()})

  useEffect(() => {
    axios.get("/api/purchase/last_update")
      .then(res => {
        setUpdatedDate(res.data);
      });
    },[]);

    const handleClick= (event) => {
      setAnchorEl(event.currentTarget);
    }

    const handleDay= () => {
      setDayShow(true);
      setWeekShow(false);
      setThreeMonthsShow(false);
      setMonthShow(false);
      setAnchorEl(null);
      setCardText('Day')
      }

    const handleMonth= () => {
      setDayShow(false);
      setWeekShow(false);
      setThreeMonthsShow(false);
      setMonthShow(true);
      setAnchorEl(null);
      setCardText('Month')
      }


    const handleWeek= () => {
      setDayShow(false);
      setWeekShow(true);
      setThreeMonthsShow(false);
      setMonthShow(false);
      setAnchorEl(null);
      setCardText('Week')
      }
    const handleThreeMonths= () => {
      setDayShow(false);
      setWeekShow(false);
      setMonthShow(false);
      setThreeMonthsShow(true);
      setAnchorEl(null);
      setCardText('Three Months')
      }

      const handleSixMonths= () => {
        setDayShow(false);
        setWeekShow(false);
        setMonthShow(false);
        setThreeMonthsShow(false);
        setAnchorEl(null);
        setCardText('Six Months')
        }
      const LastTransactions = new Date(updatedDate.updated_date);
      const [startDate,setStartDate] = React.useState('')
      const [startDate2023,setStartDate2023] = React.useState('')
      const [startDate2022,setStartDate2022] = React.useState('')
      const [startDate2021,setStartDate2021] = React.useState('')
      const [startDate2020,setStartDate2020] = React.useState('')
      const [startDate2019,setStartDate2019] = React.useState('')

      var month = LastTransactions.getUTCMonth();
      var monthInteger = LastTransactions.getMonth()
      var year = LastTransactions.getUTCFullYear();

      var endDate = new Date(LastTransactions).toLocaleString('default', { month: 'short' }) + " " + LastTransactions.getUTCDate() + " " +
          year
      var lastYear = year -1

      var endDate2023 = new Date(LastTransactions).toLocaleString('default', { month: 'short' }) + " " + LastTransactions.getUTCDate() +" " +
      lastYear

      var endDate2022 = new Date(LastTransactions).toLocaleString('default', { month: 'short' }) + " " + LastTransactions.getUTCDate() +" " +
        (lastYear-1)

      var endDate2021 = new Date(LastTransactions).toLocaleString('default', { month: 'short' }) + " " + LastTransactions.getUTCDate() +" " +
        (lastYear -2)

      var endDate2020 = new Date(LastTransactions).toLocaleString('default', { month: 'short' }) + " " + LastTransactions.getUTCDate() +" " +
      (lastYear -3)
      var endDate2019 = new Date(LastTransactions).toLocaleString('default', { month: 'short' }) + " " + LastTransactions.getUTCDate() +" " +
      (lastYear -4)

        React.useEffect(() => {
          if (year % 100 === 0 ? year % 400 === 0 : year % 4 === 0){
            var leapYear = true
          }

           if (dayShow){
            var startMonth = month
            let startDay = LastTransactions.getUTCDate();

            startMonth =new Date(year,startMonth,startDay).toLocaleString('default', { month: 'short' });
            setStartDate(startMonth+" "+ startDay + " "+ year);

            if (!leapYear || startDay != 29) {
            lastYear= year -1
            setStartDate2023(startMonth+" "+ startDay + " "+ lastYear);
            setStartDate2022(startMonth+" "+ startDay + " "+ (lastYear-1));
            setStartDate2021(startMonth+" "+ startDay + " "+ (lastYear-2));
            setStartDate2020(startMonth+" "+ startDay + " "+ (lastYear-3));
            setStartDate2019(startMonth+" "+ startDay + " "+ (lastYear-4));
            }
            else {
              lastYear= year -1
            setStartDate2023(startMonth+" "+ (startDay -1).toString() + " "+ lastYear);
            setStartDate2022(startMonth+" "+ (startDay -1).toString()+ " "+ (lastYear-1));
            setStartDate2021(startMonth+" "+ (startDay -1).toString() + " "+ (lastYear-2));
            setStartDate2020(startMonth+" "+ (startDay -1).toString() + " "+ (lastYear-3));
            setStartDate2019(startMonth+" "+ (startDay -1).toString() + " "+ (lastYear-4));
            }

           }
          else if (weekShow){
           startMonth = month
           let startDay = LastTransactions.getUTCDate() -6
           if(startDay <= 0){
             startDay = new Date(year, month, 0).getDate() + startDay;

             startMonth = startMonth -1
             if(startMonth<0){
               year = year -1;
               startMonth = 11
             }

           }
           startMonth =new Date(year,startMonth,startDay).toLocaleString('default', { month: 'short' });
           setStartDate(startMonth+" "+ startDay + " "+ year);
           lastYear= year -1

           if (!leapYear || startDay != 29 ) {
            lastYear= year -1
            setStartDate2023(startMonth+" "+ startDay + " "+ lastYear);
            setStartDate2022(startMonth+" "+ startDay + " "+ (lastYear-1));
            setStartDate2021(startMonth+" "+ startDay + " "+ (lastYear-2));
            setStartDate2020(startMonth+" "+ startDay + " "+ (lastYear-3));
            setStartDate2019(startMonth+" "+ startDay + " "+ (lastYear-4));
            }
            else {
              lastYear= year -1
            setStartDate2023(startMonth+" "+ (startDay -1).toString() + " "+ lastYear);
            setStartDate2022(startMonth+" "+ (startDay -1).toString()+ " "+ (lastYear-1));
            setStartDate2021(startMonth+" "+ (startDay -1).toString() + " "+ (lastYear-2));
            setStartDate2020(startMonth+" "+ (startDay -1).toString() + " "+ (lastYear-3));
            setStartDate2019(startMonth+" "+ (startDay -1).toString() + " "+ (lastYear-4));
            }
          }
          else if(monthShow) {
            let startDay = LastTransactions.getUTCDate();
            startMonth = month
            if(startMonth<0){
              year = year -1;
              startMonth = 11
            }
            startMonth =new Date(year,startMonth,1).toLocaleString('default', { month: 'short' });
            setStartDate(startMonth+" "+ 1 + " "+ year);
            lastYear= year -1

            if (!leapYear || startDay != 29) {
              lastYear= year -1
              setStartDate2023(startMonth+" "+ 1 + " "+ lastYear);
              setStartDate2022(startMonth+" "+ 1 + " "+ (lastYear-1));
              setStartDate2021(startMonth+" "+ 1 + " "+ (lastYear-2));
              setStartDate2020(startMonth+" "+ 1 + " "+ (lastYear-3));
              setStartDate2019(startMonth+" "+ 1 + " "+ (lastYear-4));
              }
              else {
                lastYear= year -1
              setStartDate2023(startMonth+" "+ 1 + " "+ lastYear);
              setStartDate2022(startMonth+" "+ 1 + " "+ (lastYear-1));
              setStartDate2021(startMonth+" "+ 1 + " "+ (lastYear-2));
              setStartDate2020(startMonth+" "+ 1 + " "+ (lastYear-3));
              setStartDate2019(startMonth+" "+ 1 + " "+ (lastYear-3));
              }
          }
          else if(threeMonthsShow){
            let startDay = LastTransactions.getUTCDate();
            startMonth = month -2
            if(startMonth<0){
              year = year -1;
              startMonth = 12+ startMonth
            }
            startMonth =new Date(year,startMonth,1).toLocaleString('default', { month: 'short' });
            setStartDate(startMonth+" "+ 1 + " "+ year);
            lastYear= year -1

            if (!leapYear || startDay != 29) {
              lastYear= year -1
              setStartDate2023(startMonth+" "+ 1 + " "+ lastYear);
              setStartDate2022(startMonth+" "+ 1 + " "+ lastYear-1);
              setStartDate2021(startMonth+" "+ 1 + " "+ (lastYear-2));
              setStartDate2020(startMonth+" "+ 1 + " "+ (lastYear-3));
              setStartDate2019(startMonth+" "+ 1 + " "+ (lastYear-4));
              }
              else {
                lastYear= year -1
              setStartDate2023(startMonth+" "+ 1 + " "+ lastYear);
              setStartDate2022(startMonth+" "+ 1 + " "+ lastYear-1);
              setStartDate2021(startMonth+" "+ 1 + " "+ (lastYear-2));
              setStartDate2020(startMonth+" "+ 1 + " "+ (lastYear-3));
              setStartDate2019(startMonth+" "+ 1  + " "+ (lastYear-4));
              }
          }
          else
          {
            startMonth = month -5
            if(startMonth<=0){
              year = year -1;
              startMonth = 12+ startMonth
            }
            startMonth =new Date(year,startMonth,1).toLocaleString('default', { month: 'short' });
            setStartDate(startMonth+" "+ 1 + " "+ year);
            lastYear= year -1
            setStartDate2023(startMonth+" "+ 1 + " "+ lastYear);
            setStartDate2022(startMonth+" "+ 1 + " "+ lastYear-1);
            setStartDate2021(startMonth+" "+ 1 + " "+ (lastYear-2));
            setStartDate2020(startMonth+" "+ 1 + " "+ (lastYear-3));
            setStartDate2019(startMonth+" "+ 1 + " "+ (lastYear-4));
          }
          });

    const handleClose= (e) => {
        setAnchorEl(null);}

    durationGet(dayShow,weekShow,monthShow,threeMonthsShow)
  return (
    <>

    <Card >
      <CardHeader title='Duration'
        action={(
          <Button
            id="thisYear"
            endIcon={<ArrowDropDownIcon />}
            size="small"
            variant="text"
            onClick={handleClick}
          >
            {cardText}
          </Button>)}/>
          <Menu
            id="this-year"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem id='daily' onClick={handleDay}>Day </MenuItem>
            <MenuItem id='currentWeek' onClick={handleWeek}>Week </MenuItem>
            <MenuItem id='currentMonth' onClick={handleMonth}>Month</MenuItem>
            <MenuItem id='treeMonths' onClick={handleThreeMonths}>Three Months</MenuItem>
            <MenuItem id='sixMonths' onClick={handleSixMonths}>Six Months</MenuItem>
          </Menu>

      <Divider />
     <CardContent>
     { showDates &&
     <>

          <Typography
            color='#0D830F'
            variant="h6"
          >
           {startDate2019 +"- " + endDate2019}
          </Typography>

          <Typography
            color="red"
            variant="h6"
          >
           {startDate2020+"- " + endDate2020}

          </Typography>
          <Typography
            color="#100985"
            variant="h6"
          >
           {startDate2021 +"- " + endDate2021}
          </Typography>

          <Typography
            color='#86086D '
            variant="h6"
          >
           {startDate2022 +"- " + endDate2022}
          </Typography>

          <Typography
            color='#eead0e'
            variant="h6"
          >
           {startDate2023 +"- " + endDate2023}
          </Typography>



     <Typography
            color='#15EDF4'
            variant="subtitle2"
            style={{display: 'inline-block'}}
          >
           {startDate +"- " + endDate} <br></br>
          </Typography>

          <Typography
            color="textPrimary"
            variant="h6"
          >
           Last Transaction Time: {updatedDate.updated_time}
          </Typography>

          </> }
       </CardContent>
    </Card>
    </>
  );
};

export default DateMenu;
