import React from "react";
import Table from "@material-ui/core/Table";
import TableContainer from '@mui/material/TableContainer';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import { useEffect } from "react";
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import TextField from "@material-ui/core/TextField";

const table = {
  minWidth: 700,
  maxWidth: 1200
}

const selectTableCell ={
  width: 50,
  height:40
}
const tableCell ={
  width: 250,
  height: 40
}
const editCell ={
  width: 150,
  height: 40
}
const input ={
  width: 70,
  height: 40
}
const smallCell ={
  width: 40,
  height: 40
}

const CustomTableCell = ({ row, editable, name, onChange, width}) => {
  const [errorMessage, setErrorMessage] = React.useState();
  React.useEffect(() => {
    // Set errorMessage only if text is Empty or negative
    if (isNaN(+row[name]) || +row[name]<0  || row[name]=='') {
      setErrorMessage(
        "Not a valid number"
      );
    }
  }, [row[name]]);

  React.useEffect(() => {
    // Set empty erroMessage only if text is a number between 0 and 100
    // and errorMessage is not empty.
    // avoids setting empty errorMessage if the errorMessage is already empty
    if (!isNaN(+row[name])  && +row[name]>= 0 && row[name] != '' && errorMessage) {
      setErrorMessage("");
    }
  }, [row[name], errorMessage]);


  const { isEditMode } = row;
  var sxTouse;

  width == '1' ? sxTouse= tableCell : sxTouse= selectTableCell
  return (
    <TableCell align="left" sx={sxTouse}>
      {isEditMode && editable ? (
        <TextField
          required
          value={row[name]}
          variant = "standard"
          name={name}
          error = {isNaN(+row[name]) || +row[name]<0 ||
            row[name] ==''}
          helperText={errorMessage}
          onChange={e => onChange(e, -1,row)}
          sx={input}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};
export default function EditableTable({data, rowToUpdate,storeToUpdate,rowToDelete,headerCells}) {
  const [rows, setRows] = React.useState(data);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [errorText, setErrorText] = React.useState();

  const openDialog=(e,rows,row,storeRow) =>{
    if(storeRow === -1){
    onToggleEditMode(row.id,-1)
    rowToUpdate(row)
  }
    else{
      onToggleEditMode(row.id,storeRow)
      storeToUpdate(rows,row.id,storeRow)
    }
  }

  const saveStatus =(e,row)=>{
    setDialogOpen(false)
  }

  const onDelete =(row_id) =>{
    rowToDelete(row_id)
  }

  useEffect(() => {
    setRows(data)
     },[data]);

  const [previous, setPrevious] = React.useState({});
  const onToggleEditMode = (sku,storeRow) => {
    if (storeRow === -1){
    setRows(state => {
      return rows.map(row => {
        if (row.id === sku) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  }else{
      const updatedRows= rows.map(row => {
        if (row.id === sku) {
        const  storeEdit = row.store_data.map(store => {
            if (store.id === storeRow) {

              return { ...store, isEditMode: !store.isEditMode };
            }
            return store;
          });
          row['store_data'] = storeEdit
        }
        return row;
    });
    setRows(updatedRows)
  }
  };

  const onCollapse = id => {
    setRows(state => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, isCollapsed: !row.isCollapsed};
        }
        return row;
      });
    });
  };

  const onChange = (e, storeRow,row) => {
    const value = e.target.value;
    if (storeRow === -1){
      if (!previous[row.id]) {
        setPrevious(state => ({ ...state, [row.id]: row }));
      }
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  }
  else{
    if (isNaN(+value) || +value<0  || value =='') {
      setErrorText("Not a valid number");
          }

    if (!isNaN(+value)  && +value>= 0  && value !='' && errorText) {
      setErrorText("");
    }

    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row['store_data'] }));
       }
      const updatedRows= rows.map(rowBefore => {
        if (rowBefore.id === row.id) {
        const  storeEdit = rowBefore.store_data.map(store => {
            if (store.id === storeRow) {
              return { ...store, ['forecast manual #']: value};
            }
            return store;
          });
          rowBefore['store_data'] = storeEdit
        }
        return rowBefore;
  });
  setRows(updatedRows)
    }
  };

  const onRevert = (id,storeRow) => {
    if ( storeRow === -1){
    const newRows = rows.map(row => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
  }
   else{
    const newRows = rows.map(row => {
      if (row.id === id) {
        if (previous[id]){
          row['store_data'] = previous[id]
          return row
        }
        return row;
      }
      return row;
    });
    setRows(newRows);
   }

    setPrevious(state => {
      delete state[id];
      return state;
    });
};

  const handleClose =() =>{
    setDialogOpen(false)
  }

  return (
    <Box sx={{ width: "100%" }}>
    <Paper  sx={{ width: "100%", mb: 2 }}>
    <TableContainer>
      <Table aria-label="caption table">
        <caption>Seasonal product quantity forecast</caption>
        <TableHead>
          <TableRow>
            {headerCells.map((item,index) =>
            <TableCell align="left"  padding="normal"> {item}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>

          {rows.map(row => (
             <React.Fragment>
            <TableRow key={row.id}>
               <TableCell sx ={smallCell}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => onCollapse(row.id)}
                  >
                    {row.isCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
              <CustomTableCell {...{ row, name: "id", onChange, width:'2' }} />
              <CustomTableCell {...{ row, name: "sap_id", onChange,width:'2' }} />
              <CustomTableCell {...{ row, name: "description_en", onChange,width:'1' }} />
              <CustomTableCell {...{ row ,name: "description_fr", onChange,width:'1'}} />
              <CustomTableCell {...{ row ,name: "count", onChange,width:'2'}} />
               <CustomTableCell {...{ row ,name: "sold_sum", onChange,width:'2'}} />
               <CustomTableCell {...{ row ,name: "sales_ratio", onChange,width:'2'}} />
               <CustomTableCell {...{ row ,name: "forecast_num", onChange,width:'2'}} />
               <CustomTableCell {...{ row ,name: "forecast_amount", onChange,width:'2'}} />
               <CustomTableCell {...{ row ,name: "sales_ratio_manual", onChange,width:'2'}} />
               <CustomTableCell {...{ row ,editable:true,name: "forecast_num_manual", onChange,width:'2'}} />
               <CustomTableCell {...{ row ,name: "forecast_amount_manual", onChange,width:'2'}} />

               <TableCell sx={editCell}>
                {row.isEditMode ? (
                  <>
                    <IconButton
                      aria-label="done"
                      onClick={() => onToggleEditMode(row.id,-1)}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      aria-label="revert"
                      onClick={() => onRevert(row.id,-1)}
                    >
                      <RevertIcon />
                    </IconButton>
                    <IconButton value="Save" disabled={false} onClick={(e) => openDialog(e,rows,row,-1)}>
                     <SaveIcon />
                   </IconButton>
                   <IconButton
                      aria-label="delete"
                      onClick={() => onDelete(row.id)}
                    >
                      <DeleteIcon />
                      </IconButton>
                  </>
                ) : (
                  <IconButton
                    aria-label="edit"
                    onClick={() => onToggleEditMode(row.id,-1)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={row.isCollapsed} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Store product quantity
              </Typography>
              <Table size="medium" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Store</TableCell>
                    <TableCell align="left">Sold(#)</TableCell>
                    <TableCell align ="left">Sold($)</TableCell>
                    <TableCell  align ="left">SSR %</TableCell>
                    <TableCell  align ="left">SSR manual %</TableCell>
                    <TableCell  align="left">Forecast(#)</TableCell>
                    <TableCell align="left">Forecast($)</TableCell>
                    <TableCell sx={editCell} align="left">Forecast manual(#)</TableCell>
                    <TableCell align="left">Forecast manual($)</TableCell>
                    <TableCell align="left">Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {row.store_data.map((storeRow) => (
                    <TableRow key={storeRow.id}>
                      <TableCell component="th" scope="row">
                        {storeRow['store id']}
                      </TableCell>
                      <TableCell>{storeRow['sold #']}</TableCell>
                      <TableCell align="left">{storeRow['sold $']}</TableCell>
                      <TableCell sx ={editCell} align="left">{storeRow['sales %']}</TableCell>
                      <TableCell align="left" >
                          {storeRow['sales manual %']}
                        </TableCell>
                      <TableCell align="left">
                      {storeRow['forecast #']}
                      </TableCell>
                      <TableCell align="left">{storeRow['forecast $']}</TableCell>
                      <TableCell align="left">
                        {storeRow.isEditMode ? (
                            <TextField
                              value={storeRow['forecast manual #']}
                              variant = 'standard'
                              error ={isNaN(+storeRow['forecast manual #']) || +storeRow['forecast manual #']<0 ||
                                       storeRow['forecast manual #']==''}
                              helperText={errorText}
                              name='forecast manual #'
                              onChange={e => onChange(e, storeRow.id,row)}
                              sx={input}
                            />
                          ) : (
                            storeRow['forecast manual #']
                          )}
                          </TableCell>
                      <TableCell align="left">{storeRow['forecast manual $']}</TableCell>
                      <TableCell sx={editCell}>
                {storeRow.isEditMode ? (
                  <>
                    <IconButton
                      aria-label="done"
                      onClick={() => onToggleEditMode(row.id,storeRow.id,rows)}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      aria-label="revert"
                      onClick={() => onRevert(row.id)}
                    >
                      <RevertIcon />
                    </IconButton>
                    <IconButton value="Save" disabled={false} onClick={(e) => openDialog(e,rows,row,storeRow)}>
                     <SaveIcon />
                   </IconButton>
                  </>
                ) : (
                  <IconButton
                    aria-label="edit"
                    onClick={() => onToggleEditMode(row.id,storeRow.id,rows)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                  </Table>
                  </Box>
                  </Collapse>
                  </TableCell>
                  </TableRow>
            </React.Fragment>
          ))}

        </TableBody>
      </Table>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Change Sales ratio"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Change the quantity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={(e,row) => saveStatus(e,row)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      </TableContainer>
      </Paper>
      </Box>

  );
}


