import { createSlice } from "@reduxjs/toolkit";

export const dateToString =(date: Date) =>{
  return date.toISOString()
}

export interface salesParams
{
  values:{
    startDate: string;
    endDate: string;
    salesType: string;
    selectedStores: string[];
    districtEnabled: boolean;
    districts : string[];
    provinces: string[];
    salesShow: boolean;
    provinceEnabled: boolean;
    paymentMethod: string[];
    isAllStoresSelected: boolean
  }
}

const initialState: salesParams = {
  values: {
  startDate : dateToString(new Date()),
  endDate : dateToString(new Date()),
  salesType : 'store',
  selectedStores:[],
  districtEnabled : false,
  districts:[],
  provinces: [],
  salesShow: false,
  provinceEnabled: false,
  paymentMethod : [],
  isAllStoresSelected: false
  }
}

export const salesParamsSlice = createSlice({
    name: "salesParams",
    initialState: initialState,
    reducers: {
      setSalesParams: (state, action) => {
        state.values = action.payload;
      },
    }
  });

  export const {setSalesParams} = salesParamsSlice.actions;
  export default salesParamsSlice.reducer;