import React from 'react';

const ToExcel = () => {
return (

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="32.000000pt" height="18.000000pt" viewBox="0 0 48.000000 48.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
fill="#FFFFFF" stroke="none">
<path d="M92 428 c-7 -7 -12 -37 -12 -68 0 -44 -4 -58 -20 -68 -17 -10 -20
-23 -20 -82 0 -59 3 -72 20 -82 12 -8 20 -24 20 -38 0 -14 5 -31 12 -38 17
-17 279 -17 296 0 8 8 12 56 12 151 l0 140 -49 48 -48 49 -100 0 c-64 0 -103
-4 -111 -12z m190 -65 c3 -36 5 -38 41 -41 20 -2 37 -7 37 -13 0 -5 -52 -9
-120 -9 l-120 0 0 50 0 50 79 0 80 0 3 -37z m-168 -115 c5 -10 7 -10 12 0 3 6
12 12 20 12 11 0 11 -5 2 -25 -8 -19 -8 -31 0 -50 9 -20 9 -25 -2 -25 -8 0
-17 6 -20 13 -5 10 -7 10 -12 0 -3 -7 -12 -13 -20 -13 -11 0 -11 5 -2 25 8 19
8 31 0 50 -9 20 -9 25 2 25 8 0 17 -6 20 -12z m86 -28 c0 -33 3 -40 20 -40 11
0 20 -4 20 -10 0 -5 -13 -10 -30 -10 -29 0 -30 1 -30 50 0 28 5 50 10 50 6 0
10 -18 10 -40z m118 21 c3 -9 -2 -11 -17 -6 -27 8 -27 -2 1 -24 27 -22 18 -54
-14 -49 -12 2 -23 9 -26 17 -3 9 2 11 17 6 27 -8 27 2 -1 24 -27 22 -18 54 14
49 12 -2 23 -9 26 -17z m42 -141 c0 -19 -7 -20 -120 -20 -113 0 -120 1 -120
20 0 19 7 20 120 20 113 0 120 -1 120 -20z"/>
</g>
</svg>


  )}
  export default ToExcel;