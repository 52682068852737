import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {createAsyncThunk } from '@reduxjs/toolkit';
import {salesParams} from 'redux/searchOptions';
import qs from 'qs';
import {chartData} from 'customTypings/chartJs';
import { RootState } from "./store";



type loading ={
  status : boolean;
  transactions: chartData;
}

interface salesOptions
{
    startDate: string;
    endDate: string;
    salesType: string;
    selectedStores: string[];
    districtEnabled: boolean;
    districts : string[];
    provinces: string[];
    salesShow: boolean;
    provinceEnabled: boolean;
    paymentMethod: string[];
    isAllStoresSelected: boolean

}

export type props={
  salesOptions: salesOptions,
  data: string
}

const initialState : loading ={
 status: false,
 transactions: {
  labels:[],
  datasets: [{
              label: '',
              fill: true,
              backgroundColor: '',
              data : []
             }]
 }}


export const fetchSales = createAsyncThunk(
  'salesIsLoading/fetchSales',
  async (props: props ) => {
    const {salesOptions, data} = props
    const response  = await axios.get<chartData>("/api/purchase/",
    {
      params: {
        storeId:salesOptions.selectedStores,
        startDate:salesOptions.startDate,
        endDate:salesOptions.endDate,
        paymentType:salesOptions.paymentMethod,
        allStores:salesOptions.isAllStoresSelected,
        avgItemTr: data
      },
      paramsSerializer: params => {
        return qs.stringify(params,{arrayFormat: 'repeat'})
      }
    })
    return(response.data)
  }
)

export const salesIsLoadingSlice = createSlice({
    name: "salesIsLoading",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(fetchSales.pending, (state) => {
        state.status = true;
      });

      builder.addCase(fetchSales.fulfilled,
        (state, { payload }) => {
        state.status = false;
        state.transactions = payload
      });
    }})

  export const selectStatus = (state: RootState) => state.salesIsLoading.status;
  export const transactionsStatus = (state: RootState) => state.salesIsLoading.transactions;

  export default salesIsLoadingSlice.reducer;