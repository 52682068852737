import React, {useState,useEffect} from 'react';
import { withStyles} from '@material-ui/core/styles';
import axios from 'axios';
import qs from 'qs';

import { Line } from 'react-chartjs-2';
import {Grid,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Container,
  CardContent,
Card} from '@material-ui/core'
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme();

const useStyle = makeStyles(theme=>({

formControl: {
  margin: theme.spacing(1),
  minWidth: 120,
  maxWidth: 200,

},
DatePicker: {
  width: "80%",
  minWidth:50,
  marginRight:'auto'
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth:120
  },

}))

const ProvinceProductSales = ({startDate,endDate,provinces, productsName,provinceDefaultStore})=>{

  const [provinceStores,setProvinceStores]= useState([])
  const [transactions,setTransactions]= useState([])
  const [provinceSales,setProvinceSales]= useState([])
  const [storeSales,setStoreSales]= useState([])

  useEffect(() => {
        axios.get("/api/provinceStores/",
        {
          params: {
            provinceId:provinces
          }
        })
        .then(res => {
          setTransactions(res.data);
        });
      },[provinces]);

  useEffect(() => {
      axios.get("/api/provinceProductSales/",
      {
        params: {
          province:provinces,
          productsName:productsName,
          startDate:startDate,
          endDate:endDate
        },
        paramsSerializer: params => {
          return qs.stringify(params,{arrayFormat: 'repeat'})
        }
      }).then(res => {
        setProvinceSales(res.data);
      });
  }, [startDate,endDate,provinces,productsName]);
{/*
  useEffect(() => {
     axios.get("/api/storeInDistrictProductSales/",
    {
      params: {
        storeId:provinceDefaultStore,
        productsName: productsName,
        startDate:startDate,
        endDate:endDate
      },
      paramsSerializer: params => {
        return qs.stringify(params,{arrayFormat: 'repeat'})
      }
    }).then(res =>{
      setStoreSales(res.data);
    });
  },[provinceDefaultStore]);

  useEffect(() => {
    axios.get("/api/storeInDistrictProductSales/",
   {
     params: {
       storeId:provinceStores,
       productsName: productsName,
       startDate:startDate,
       endDate:endDate
     },
     paramsSerializer: params => {
       return qs.stringify(params,{arrayFormat: 'repeat'})
     }
   }).then(res =>{
     setStoreSales(res.data);
   });
   },[provinceStores,productsName]);
*/}
  const handleProvinceStores = (event) => {
    setProvinceStores(event.target.value);
  };

  const classes = useStyle();
  return (
    <Container maxWidth={false}>
    <Grid
      container
      spacing={3}
    >

    <Grid
        item
        md={10}
        xs={10}
    >
   <Box pt={3}>
       <Card>
         <CardContent>
   < Line data={provinceSales} options={{
            title:{
              display:true,
              text:'provinceSales',
              fontSize:16
            },
            legend:{
              display:true,
              position:'top'
            }
          }}/>

           </CardContent>
         </Card>
    </Box>
    </Grid>
    <Grid
        item
        md={10}
        xs={10}
    >
     <FormControl className={classes.formControl} variant='standard' >
                <InputLabel id="province-select">Province Stores</InputLabel>
                <Select
                  labelId="province-select-label"
                  id="province-select"
                  className={classes.selectEmpty}
                  defaultValue = "[]"
                  value={provinceStores}
                  onChange={handleProvinceStores}
                >
                   <MenuItem value="">
                      <em>None</em>
                  </MenuItem>
                    {transactions.map(item => {
                      return <MenuItem value={item.store_name}>{item.store_name}</MenuItem>;
                    })}

                </Select>
                </FormControl>

    </Grid>
    <Grid
        item
        md={10}
        xs={10}
    >

   <Box pt={3}>
   <Card>
       <CardContent>

    < Line data={storeSales} options={{
             title:{
               display:true,
               text:'Product Sales in Stores',
               fontSize:16
             },
             legend:{
               display:true,
               position:'top'
             }
           }}/>
        </CardContent>
        </Card>
        </Box>
       </Grid>
</Grid>
</Container>

  );
}
export default ProvinceProductSales;
