import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';

import SearchOptions from 'components/dashboard/SearchForm';
import CommercialSales from 'components/Sales/CommercialSales';
import StoreSales from 'components/Sales/Sales';
import DistrictSales from 'components/Sales/DistrictSales'
import ProvinceSales from 'components/Sales/ProvinceSales'
import {useState} from 'react';
import IdleTimeout from 'components/IdleTimeout/IdleTimeout';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const salesData = useSelector(state => state.salesParams.values)
  return (
  <>
    <Helmet>
      <title>Dashboard |Nutriart</title>
    </Helmet>
    <IdleTimeout/>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          md={12}
          xs={12}
          spacing={3}
        >

          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <SearchOptions  />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >

            {salesData.salesShow &&  salesData.salesType == 'store'  && !(salesData.districtEnabled || salesData.provinceEnabled) &&
               <StoreSales />}
            {salesData.salesShow &&  salesData.salesType == 'store' && salesData.districtEnabled && <DistrictSales startDate={salesData.startDate}
               endDate={salesData.endDate} districtId={salesData.districts}  DistrictDefaultStore={[]}  />}
            {salesData.salesShow &&  salesData.salesType == 'store' && salesData.provinceEnabled && <ProvinceSales startDate={salesData.startDate}
                 endDate={salesData.endDate} province={salesData.provinces}  provinceDefaultStore={[]} />}

            {salesData.salesShow &&  salesData.salesType == 'commercial' && !(salesData.districtEnabled || salesData.provinceEnabled) && <CommercialSales startDate={salesData.startDate}
              endDate={salesData.endDate} storeId={salesData.selectedStores} paymentType={salesData.paymentType}
              isAllStoresSelected={salesData.isAllStoresSelected} />}
         </Grid>
        </Grid>

      </Container>
    </Box>
  </>
  );
    }

export default Dashboard;
