import { createSlice } from "@reduxjs/toolkit";
export interface dataLoaded
{
    totalSales: boolean,
    totalTransactions: boolean,
    averageItems: boolean,
    totalItems: boolean,
    averageItermsPerTransaction: boolean ,
    rates: boolean,
    rateSlices: boolean
}

const initialState : dataLoaded = {
    totalSales: false,
    totalTransactions: false,
    averageItems: false,
    averageItermsPerTransaction: false,
    totalItems: false,
    rates: false,
    rateSlices: false}

export const promiseSlice = createSlice ({
    name: 'sales',
    initialState: initialState,
    reducers:{
        callCompleted : (state,action) =>{
            state = action.payload
        }
    }
})

export const {callCompleted} = promiseSlice.actions
export default promiseSlice.reducer