import React from 'react'
import { Bar} from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';

const PeriodicDailySales =({avgItemTr, dayShow,weekShow, monthShow, threeMonthsShow, totalSales}) => {
  const options = {
    animation: false,
    cutoutPercentage: 70,
    layout: { padding: 0 },
    legend: {
      display: true
    },
    maintainAspectRatio: false,
    responsive: true

  };

  return (
    <Card >
      <CardHeader title="Total Daily Sales" >
      </CardHeader>

      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 500,
            position: 'relative'
          }}
        >

          <Bar
            data={totalSales.durationSales}
            options={options}
          />
        </Box>

      </CardContent>
    </Card>
  );
};

export default PeriodicDailySales;
