import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface loginStatus
{
    name: string
}

const initialState : loginStatus = { name :'' }
export const userSlice = createSlice({
    name: 'user',
    initialState : initialState ,
    reducers: {
        logedin: (state,  action: PayloadAction<string>)=>{
           state.name = action.payload
        }
    },
    },
);

export const {logedin} = userSlice.actions
export default userSlice.reducer