
import React , { useState,useEffect, useRef} from 'react';
import {Grid,
  TextField,
  Box,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  SvgIcon,
  InputAdornment,
  CardContent} from '@material-ui/core'
import { Search as SearchIcon } from 'react-feather';
import Alert from '@material-ui/lab/Alert';

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import StoresDropDown from '../General/StoresDropDown';
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux';
import {setSubmitted, selectedProducts, districtEnable, provinceEnable} from 'redux/productParams';
import {disable,reset,setAllSelected,setItems, setAllItems} from 'redux/dropDownStores';
import {setItemsList, resetItemsList, reloadItemsList} from 'redux/dropDownStores';
const theme = createTheme();

const useStyle = makeStyles(theme=>({
formControl: {
  margin: theme.spacing(2),
  minWidth: 160,
  maxWidth: 800,
},
DatePicker: {
  width: "80%",
  minWidth:50,
  marginRight:'auto'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth:350
  },
  selectList: {
    marginTop: theme.spacing(2),
    minWidth:150
  },

  search: {
    marginTop: theme.spacing(2),
    color: 'red'
  }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function ProductsSearchForm({sendDataToParent}) {

const [products,setProducts] =useState([])
const [productselected, setProductselected] = useState([])
const [storeselected, setItemselected] = useState([])
const [districtEnabled, setDistrictEnabled] = useState(false)
const [storeEnabled, setStoreEnabled] = useState(false)
 const [provinceEnabled, setProvinceEnabled] = useState(false)
 const [district, setDistrict] = useState('');
 const [province, setProvince] = useState('');
 const [submitEnabled, setSubmitEnabled] = useState(false);
 const [productFound, setProductFound] = useState([]);
 const [errorMessage, setErrorMessage] = useState("");

 const dispatcher = useDispatch();
 const classes = useStyle();
const params =  useSelector(state => state.productSales)
const initialParams = useSelector(state => state.stores)
const districts = [1,2,5,22,23,24,25,26]

const IfMounted = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const didMounted = IfMounted()

useEffect(() => {
    dispatcher(setItems({id:3, Items: ['All']}))
    axios.get('/api/storesDetail',
    {
      params: {
      'storeNumber':true
       }
      })
      .then(res => {

          dispatcher(setAllItems({id:3,allItems:res.data.nameNumber}))
      });
  },[]);


  useEffect(() => {
    if(initialParams.stores.reloadItemsList ){
    axios.get('/api/storesDetail',
    {
      params: {
      'storeNumber':true
       }
      })
      .then(res => {
          dispatcher(setItemsList({id:3,ItemsList:res.data.nameNumber}))
          dispatcher(setAllItems({id:3,allItems:res.data.nameNumber}))
      });
      dispatcher(reloadItemsList({id:3}))
    }
    },[initialParams.stores.reloadItemsList]);


  useEffect(() => {
    if (!didMounted && !initialParams.districts.disabled){
      const loadData = async () => {
    const result = await axios
      .get('/api/districtStoresDetail',
      {
        params: {
          districtId: initialParams.districts.items
        },
        paramsSerializer: params => {
          return qs.stringify(params,{arrayFormat: 'repeat'})
        }
      })
      dispatcher(setItemsList({id:3, ItemsList:result.data}))

    }
      loadData();
    }
    },[initialParams.districts.items]);

useEffect(() => {
  axios.get('/api/productsDetail')
    .then(res => {
      setProducts(res.data);
    });
  },[]);

const [startDate,setStartDate] = useState(new Date())
const [endDate,setEndDate] = useState(new Date())

const handleStartDateChange =(date) =>{
  setStartDate(date)}

const handleEndDateChange =(date) =>{
  setEndDate(date)
}

const handleProductChange = (event) => {
  setProductselected(event.target.value);
  const value =  event.target.value
  dispatcher(selectedProducts(value.map((item) => item.substring(0,item.indexOf(' ')))))
  setSubmitEnabled(true)
};

const handleProvince = (event) => {
  setProvince(event.target.value);
};

function handleSubmit(event) {
  event.preventDefault();
  dispatcher(setSubmitted(true));
   if (storeEnabled){
    sendDataToParent(startDate,endDate,storeselected,productselected,districtEnabled,provinceEnabled);
  }
  else if (districtEnabled){
    sendDataToParent(startDate,endDate,district,productselected,districtEnabled,provinceEnabled);
  }
  else if (provinceEnabled){
    sendDataToParent(startDate,endDate,province,productselected,districtEnabled,provinceEnabled);
  }
  else {
    sendDataToParent(startDate,endDate,storeselected,productselected,districtEnabled,provinceEnabled);
  }
}

const [salesOption, setSalesOption] = useState('All');
const [product, setProduct] = useState('');
  const handleRadio = (event) => {
    setSalesOption(event.target.value);
    dispatcher(reset({id:3}))
    dispatcher(setAllSelected({id:3,allItemsSelected: true}))
    dispatcher(disable({id:3,disabled: true}))

    if (event.target.value == 'Province'){
      dispatcher(provinceEnable(true))
      dispatcher(districtEnable(false))
      //setProvinceEnabled(true)
      //setDistrictEnabled(false)
      dispatcher(disable())
      //setStoreEnabled(false)
    }
    if (event.target.value == 'District'){
      dispatcher(setItemsList({id:3, ItemsList: districts}))
      dispatcher(resetItemsList({id:3}))
      dispatcher(reset({id:3}))
      dispatcher(setAllSelected({id:3,allItemsSelected: false}))
      dispatcher(disable({type:'districts',disabled: false}))
      setProvinceEnabled(false)
      dispatcher(disable({id:3,disabled: false}))
      //setStoreEnabled(false)
    }
    if (event.target.value == 'Store'){
      //setProvinceEnabled(false)
      //setDistrictEnabled(false)
      dispatcher(reloadItemsList({type:'stores',reloadItemsList: true}))
      dispatcher(provinceEnable(false))
      dispatcher(districtEnable(false))
      dispatcher(setAllSelected({type:'stores',allItemsSelected: false}))
      dispatcher(disable({type:'stores',disabled: false}))
      dispatcher(disable({type:'districts',disabled: true}))
      dispatcher(reset({type:'districts'}))
      //setStoreEnabled(true)
    }
    if (event.target.value == 'All'){
      //setProvinceEnabled(false)
      //setDistrictEnabled(false)
      dispatcher(provinceEnable(false))
      dispatcher(districtEnable(false))
      dispatcher({type:'stores',allStoresSelected: true})
      dispatcher(setItems({ type:'stores', Items:['All']}))
      dispatcher(reset({type:'districts'}))
      dispatcher(disable({type:'districts',disabled: true}))
      //setStoreEnabled(true)
    }
    setItemselected([])
    setDistrict('')
    setProvince('')
  };

  const handleInputChange =(event)=> {
    setProduct(event.target.value)
  }

  const handleProductSearch =(event)=> {
    setProduct(event.target.value)
    axios.get("/api/productSearch/",
      {
        params: {
          product:product
        }
      })
      .then(res => {
       const result = res.data;
       if (result.length) {
        const newList =
        productFound.filter(current =>
           current.product_id !== result[0].product_id
            )
        setProductFound(newList.concat(result))
        setErrorMessage('');
        setProduct('') ;
      }
      else{
        setProduct('') ;
        setErrorMessage('Product not found')
      }
    });

  }
console.log(productFound)
 return (
<form  noValidate onSubmit={handleSubmit}>
<Box ml={3} mr={3}>
  <Card>
    <CardContent>
      <Box pt={3} pb={3} ml={2}>
        <Grid
            container
            xs={12}
            md={12}
            spacing={1}
          >

            <Grid
              item
              md={3}
              xs={12}
            >

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker  disableToolbar variant="inline" inputVariant="outlined"
            label = "Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
            >
            </DatePicker>
          </LocalizationProvider>
          </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker disableToolbar variant="inline" inputVariant="outlined"
                    label = "End Date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    >
                  </DatePicker>
                </LocalizationProvider>

          </Grid>
        <Grid
                item
                md={6}
                xs={6}
              >
              <FormControl component="fieldset">
                  <FormLabel component="legend">Product Sales by:</FormLabel>
                    <RadioGroup row aria-label="searchOption" name="searchOption" value={salesOption} onChange={handleRadio}>
                      <FormControlLabel value="All" control={<Radio />} label="All" />
                      <FormControlLabel value="All Per-Store" control={<Radio />} label="All Per-Store" />
                      <FormControlLabel value="Store" control={<Radio />} label="Store" />
                      <FormControlLabel value="District" control={<Radio />} label="District" />
                      <FormControlLabel value="Province" control={<Radio />} label="Province" />
                    </RadioGroup>
              </FormControl>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          >
             <Box pt={3}>
            <StoresDropDown width={400} componentType={'stores'}/>
            </Box>
        </Grid>

        <Grid
          item
          md={3}
          xs={3}
          >
            <Box pt={3} >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
                }}
              fullWidth = {true}
              placeholder="Search product"
              variant="outlined"
              value = {product}
              onChange = {handleInputChange}
            />
              </Box>
            </Grid>
              <Grid
                item
                md={2}
                xs={2}
                 >
              <Box pt={5} ml={15} >
            <Button
                          variant="contained"
                          color="primary"
                          onClick={handleProductSearch}
                        >
                          Search
                        </Button>
            </Box>
            </Grid>
            <Grid
                item
                md={2}
                xs={2}
                 >
            <Box pt={4}>
            { errorMessage && <Alert severity="warning"> Product not found. </Alert> }
            </Box>
            </Grid>


          <Grid
          item
          md={12}
          xs= {12}
          >
            <Box pt={3} >
              <FormControl className={classes.formControl} >
              <InputLabel id="products-mutiple-checkbox-label">Products</InputLabel>
                <Select
                  labelId="products-mutiple-checkbox-label"
                  id="products-mutiple-checkbox"
                  sx ={{
                    marginTop: 4,
                    marginBottom: 4,
                    width:400
                   }}
                  multiple
                  fullWidth
                  value={productselected}
                  onChange={(event) => handleProductChange(event)}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {productFound.map((item) => (
                    <MenuItem key={item.product_id} value={item.product_id+' '+ item.product_name}>
                      <Checkbox checked={productselected.indexOf(item.product_id+' '+ item.product_name) > -1} />
                      <ListItemText primary={item.product_id+' '+ item.product_name} />
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
              </Box>

        </Grid>

        <Grid
            item
            md={2}
            xs={6}
            >
              <Box pt={3} pr={3}>
              <StoresDropDown width={150} componentType={'districts'}/>
              </Box>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
            >
              <Box pt={3}>
                <FormControl className={classes.formControl} variant='standard' disabled ={!params.provinceEnabled}>
                <InputLabel id="province-select">Province</InputLabel>
                <Select
                  labelId="province-select-label"
                  id="province-selectselect"
                  className={classes.selectList}
                  value={province}
                  onChange={handleProvince}
                >
                  <MenuItem value={'MB'}>MB</MenuItem>
                  <MenuItem value={'NB'}>NB</MenuItem>
                  <MenuItem value={'NL'}>NL</MenuItem>
                  <MenuItem value={'NS'}>NS</MenuItem>
                  <MenuItem value={'ON'}>ON</MenuItem>
                  <MenuItem value={'QC'}>QC</MenuItem>
                  <MenuItem value={'SK'}>SK</MenuItem>
                </Select>
                </FormControl>
              </Box>
          </Grid>
          <Box pt={3}>
            <Grid item  md={2}
            xs={6} style={{ marginTop: 22, ml: 35}}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!submitEnabled}
                        >
                          Submit
                        </Button>
                      </Grid>
          </Box>
        </Grid>
      </Box>
    </CardContent>
  </Card>
  </Box>
</form>
 )
}




