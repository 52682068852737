import { createSlice,PayloadAction } from "@reduxjs/toolkit";


export const selectBoxSlice = createSlice({
    name: 'selectBox',
    initialState:{selectedValue:[]},
    reducers :{
        selectBoxContents : (state,action) =>{
            return {
                ...state,
                selectedValue: action.payload,
              }
        }
    }
})

export const {selectBoxContents} = selectBoxSlice.actions
export default selectBoxSlice.reducer