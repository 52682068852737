import {  colors } from '@material-ui/core';
import { createTheme} from '@mui/material/styles';

import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#fcf7f7',
      main: '#540b02'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
  shadows,
  typography
});

export default theme;
