
import React , { useState,useEffect} from 'react';
import {Grid,
  TextField,
  Box,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CardContent} from '@material-ui/core'

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@mui/styles';
import StoresDropDown from '../General/StoresDropDown';
import { useDispatch, useSelector } from 'react-redux';
import {setSubmitted} from 'redux/productParams';
import {disable,reset,setAllSelected} from 'redux/dropDownStores'

const useStyle = makeStyles(theme=>({
formControl: {
  margin: theme.spacing(2),
  minWidth: 160,
  maxWidth: 800,

},
DatePicker: {
  width: "80%",
  minWidth:50,
  marginRight:'auto'
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
    minWidth:500
  },
  selectList: {
    marginTop: theme.spacing(2),
    minWidth:150
  },
  selectStores: {
    marginTop: theme.spacing(2),
    minWidth:300
  }

}))

export default function ProductsListForm({sendDataToParent}) {
 const [options,setOptions] =useState([])
 const [products,setProducts] =useState([])
 const [productselected, setProductselected] = useState([])
 const [storeselected, setStoreselected] = useState([])
 const [districtEnabled, setDistrictEnabled] = useState(false)
 const [storeEnabled, setStoreEnabled] = useState(false)
 const [provinceEnabled, setProvinceEnabled] = useState(false)
 const [district, setDistrict] = useState('');
 const [province, setProvince] = useState('');
 const [submitEnabled, setSubmitEnabled] = useState(false);

 const initialParams = useSelector(state => state.stores)

 const classes = useStyle();
 const dispatcher = useDispatch()

useEffect(() => {
  axios.get('/api/storesDetail')
    .then(res => {
      setOptions(res.data);
    });
  },[]);

useEffect(() => {
  axios.get('/api/productsDetail')
    .then(res => {
      setProducts(res.data);
    });
  },[]);

const [startDate,setStartDate] = useState(new Date())
const [endDate,setEndDate] = useState(new Date())

const handleStartDateChange =(date) =>{
  setStartDate(date)}


const handleEndDateChange =(date) =>{
  setEndDate(date)
}

 const handleChange = (event) => {
  setStoreselected(event.target.value);

};

const handleProductChange = (event) => {
  setProductselected(event.target.value);
  setSubmitEnabled(true)
};

const handleDistrict = (event) => {
  setDistrict(event.target.value);

};

const handleProvince = (event) => {
  setProvince(event.target.value);

};

const handleClosed =() => {
  setClosed(true)
}

function handleSubmit(event) {
  dispatcher(setSubmitted(true));
  event.preventDefault();
   if (storeEnabled){
    sendDataToParent(startDate,endDate,storeselected,productselected,districtEnabled,provinceEnabled);
  }
  else if (districtEnabled){
    sendDataToParent(startDate,endDate,district,productselected,districtEnabled,provinceEnabled);
  }
  else if (provinceEnabled){
    sendDataToParent(startDate,endDate,province,productselected,districtEnabled,provinceEnabled);
  }
  else {
    sendDataToParent(startDate,endDate,storeselected,productselected,districtEnabled,provinceEnabled);
  }
}

const [salesOption, setSalesOption] = useState('All');
const [more, setMore] = useState(true);
const [productsList, setProductsList] = useState([])
const [page, setPage] = useState(0)
const [closed, setClosed] = useState(false)

const fetchData = () => {
  let newItems =[]
  if(page == 0){
    newItems = products.slice(0,100);
    setPage( 100)
  }
  else{
    newItems = products.slice(page, page+250);
    setPage( page+250)
  }

  if (productsList.length >= products.length) {
  setMore(false)
  }

 setProductsList([...productsList, ...newItems])
}

React.useEffect(()=>{
  if(products.length >0){
  fetchData()
  }
},[products, closed])

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
    onScroll: (event) => {
      const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
      if (bottom && more) {
        fetchData()
      }
    }
  }
};

  const handleRadio = (event) => {
    setSalesOption(event.target.value);
    dispatcher(reset({type:'stores'}))
    dispatcher(setAllSelected({type:'stores',allItemsSelected: true}))
    dispatcher(disable({type:'stores',disabled:true}))

    setStoreselected([])
    setDistrict('')
    setProvince('')
    if (event.target.value == 'Province'){
      setProvinceEnabled(true)
      setDistrictEnabled(false)
      dispatcher(disable(true))
      //setStoreEnabled(false)
    }
    if (event.target.value == 'District'){
      setProvinceEnabled(false)
      setDistrictEnabled(true)
      dispatcher(disable(true))
      //setStoreEnabled(false)
    }
     if (event.target.value == 'Store'){
      dispatcher(setAllSelected(false))
      setProvinceEnabled(false)
      setDistrictEnabled(false)
      dispatcher(disable(false))
      //setStoreEnabled(true)
    }
  };

 return (
<form  noValidate onSubmit={handleSubmit}>
<Box ml={3} mr={3}>
  <Card>
    <CardContent>
      <Box pt={3} pb={3} >
        <Grid
            container
            xs={11}
            md={11}
            spacing={1}
          >

            <Grid
              item
              md={3}
              xs={12}
            >

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker  disableToolbar variant="inline" inputVariant="outlined"
            label = "Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
            >
            </DatePicker>
          </LocalizationProvider>
          </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker disableToolbar variant="inline" inputVariant="outlined"
                    label = "End Date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    >
                  </DatePicker>
                </LocalizationProvider>

          </Grid>
        <Grid
                item
                md={6}
                xs={4}
              >
              <FormControl component="fieldset">
                  <FormLabel component="legend">Product Sales by:</FormLabel>
                    <RadioGroup row aria-label="searchOption" name="searchOption" value={salesOption} onChange={handleRadio}>
                      <FormControlLabel value="All" control={<Radio />} label="All" />
                      <FormControlLabel value="Store" control={<Radio />} label="Store" />
                      <FormControlLabel value="District" control={<Radio />} label="District" />
                      <FormControlLabel value="Province" control={<Radio />} label="Province" />
                    </RadioGroup>
              </FormControl>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          >
            <Box pt={3}>
              <StoresDropDown componentType={'stores'} width={400}/>
              </Box>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          >
            <Box pt={3}>
            <div>

            <FormControl className={classes.formControl} >
           <InputLabel id="products-mutiple-checkbox-label">Products</InputLabel>
            <Select
              labelId="products-mutiple-checkbox-label"
              id="products-mutiple-checkbox"
              className={classes.selectEmpty}
              multiple
              fullWidth
              value={productselected}
              onChange={handleProductChange}
              onClose={handleClosed}
              input={<Input />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
                {productsList.map((item) =>
                <MenuItem key={item.product_id} value={item.product_id}>
                  <Checkbox checked={productselected.indexOf(item.product_id) > -1} />
                  <ListItemText primary={item.product_id+'-'+ item.product_name} />
                </MenuItem>
                )
                }
            </Select>
            </FormControl>
            </div>

              </Box>
        </Grid>
        <Grid
            item
            md={2}
            xs={6}
            >
              <Box pt={3}>
                <FormControl className={classes.formControl} variant='standard' disabled ={!districtEnabled}>
                <InputLabel id="disctrict-select">District</InputLabel>
                <Select
                  labelId="district-select-label"
                  id="district-selectselect"
                  className={classes.selectList}
                  value={district}
                  onChange={handleDistrict}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={22}>22</MenuItem>
                  <MenuItem value={23}>23</MenuItem>
                  <MenuItem value={24}>24</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={26}>26</MenuItem>

                </Select>
                </FormControl>
              </Box>
          </Grid>
          <Grid
            item
            md={2}
            xs={6}
            >
              <Box pt={3}>
                <FormControl className={classes.formControl} variant='standard' disabled ={!provinceEnabled}>
                <InputLabel id="province-select">Province</InputLabel>
                <Select
                  labelId="province-select-label"
                  id="province-selectselect"
                  className={classes.selectList}
                  value={province}
                  onChange={handleProvince}
                >
                  <MenuItem value={'MB'}>MB</MenuItem>
                  <MenuItem value={'NB'}>NB</MenuItem>
                  <MenuItem value={'NL'}>NL</MenuItem>
                  <MenuItem value={'NS'}>NS</MenuItem>
                  <MenuItem value={'ON'}>ON</MenuItem>
                  <MenuItem value={'QC'}>QC</MenuItem>
                  <MenuItem value={'SK'}>SK</MenuItem>
                </Select>
                </FormControl>
              </Box>
          </Grid>
          <Box pt={3}>
            <Grid item style={{ marginTop: 32}}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={!submitEnabled}
                        >
                          Submit
                        </Button>
                      </Grid>
          </Box>
        </Grid>
      </Box>
    </CardContent>
  </Card>
  </Box>
</form>
 )
}




