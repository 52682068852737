import { Helmet } from 'react-helmet';
import React from 'react';
import {Line} from 'react-chartjs-2'
import  { useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Typography,
  Button,
  TextField,
  Paper
} from '@material-ui/core';
import { Menu, MenuItem} from "@material-ui/core";
import NestedMenuItem from "../General/NestedMenu";
import { styled} from '@mui/material/styles';
import {Bars} from 'react-loader-spinner';
import Backdrop from '@material-ui/core/Backdrop';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import IdleTimeout from '../IdleTimeout/IdleTimeout';
import StoresDropDown from '../General/StoresDropDown';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {
  CardHeader
   } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import axios from 'axios'
import qs from 'qs';
import { useSelector } from 'react-redux';
import { showDialog, setSubmited, selected, dropDownItem } from 'redux/dropDown';
import { useDispatch } from 'react-redux';
import EnhancedTable from '../table/CollapsibleSelectable';
import DateComponent from 'components/General/Date';

 const TableHeader = [
  {
    id: "collapse",
    label: " "
  },
  {
    id: "id",
    label: "Id"
  },
  {
    id: "sku",
    label: "Sku"
  },

  {
    id: "unitsBefore",
    label: "Units Before"
  },
  {
    id: "totalBefore",
    label: "Total Before"
  },
  {
    id: "profitBefore",
    label: "Profit Before"
  },
  {
    id: "unitsAfter",
    label: "Units After"
  },
  {
    id: "totalAfter",
    label: "Total After"
  },
  {
    id: "profitAfter",
    label: "Profit After"
  },
  {
    id: "description",
    label: "Description"
  },
];

const collapseHeader = [
  {
    id: "storeId",
    label: "StoreId"
  },
  {
    id: "soldBefore",
    label: "Sold Before"
  },
  {
    id: "soldAmountBefore",
    label: "Sold Amount"
  },
  {
    id: "soldAverageBefore",
    label: "Average Before"
  },
  {
    id: "soldAfter",
    label: "Sold After"
  },
  {
    id: "soldAmountAfter",
    label: "Sold Amount"
  },
  {
    id: "soldAverageAfter",
    label: "Average After"
  },
  {
    id:"firstSalesBefore",
    label: "First Sale Before"
  },
  {
    id:"lastSalesBefore",
    label: "Last Sale Before"
  },
  {
    id:"firstSalesAfter",
    label: "First Sale After"
  },
  {
    id:"lastSalesAfter",
    label: "Last Sale After"
  }
 ]

 const styles = {
  dialogPaper: {
      width: '950px',

}};

const years =['2019','2020','2021','2022','2023','2024']
const submenuItems = ['Saint Valentine', 'Easter','Summer','Halloween', 'Christmas']
const currentYearSubmenuItems = ['Saint Valentine']
const SeasonalProductSales = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

    const [rows,setRows]= React.useState({'data':[],
                                         'totals':{'count_before':0,
                                                    'count_after':0,
                                                    'amount_before':0,
                                                    'amount_after':0}})
    const dateDialog = useSelector(state => state.dropDown)
    console.log(dateDialog)
    const [open, setOpen] = React.useState(true);
    const [allStoreSelected,setAllStoreSelected] = React.useState(false);
    const [storeSelected,setStoreSelected] = React.useState([]);
    const [clear, setClear] = React.useState(true)
    const [productsSelected,setProductsSelected] = React.useState([]);
    const [result,setResult]= React.useState()
    const [duration,setDuration]= React.useState('2024-Saint Valentine')
    const dispatcher = useDispatch()
    const { promiseInProgress } = usePromiseTracker();

    React.useEffect(() => {
      if (dateDialog.duration.submited || dateDialog.duration.selected ){
      trackPromise(
      axios.get('/api/productSales/seasonal_product_sales'
        ,{
            params: {
                season:dateDialog.duration.slice,
                customSeason: dateDialog.duration.season,
                startDate: dateDialog.duration.startDate,
                criticalDate: dateDialog.duration.criticalDate,
              }
        }
        )
          .then(res => {
            setRows(res.data);
          }))
          dispatcher(setSubmited(false))
          dispatcher(showDialog(false))
          dispatcher(selected(false))
        }
        },[dateDialog.duration.submited, dateDialog.duration.slice]);

        React.useEffect(() => {
            setClear(true)
            },[productsSelected]);

  const sendProductsToParent = (productsSelected) =>{
    setProductsSelected(productsSelected);
  }

  const handleSubmit = () =>{
    trackPromise(
    axios.get("/api/productSales/seasonal_product_stores_sales",
      {
         params: {
             season: duration,
             stores:storeSelected,
             products:productsSelected,
             allSelected:allStoreSelected
           },
           paramsSerializer: params => {
            return qs.stringify(params,{arrayFormat: 'repeat'})
           }
      })
      .then(res => {
        setResult(res.data);
        setClear(false)
      }));
  }
  console.log(result)

  const handleClose =() =>{
    dispatcher(showDialog(false))
   }

   const setSubmit =() =>{
    dispatcher(setSubmited(true))
   }

  const [menuPosition, setMenuPosition] = useState(null);

  const handleClick = (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  };

  const handleItemClick = (event,year) => {
    setMenuPosition(null);
    if (event.target.innerText == 'Custom Dates'){
      dispatcher(showDialog(true))
    }
    else{
      dispatcher(dropDownItem(year + '-' +event.target.innerText ))
      dispatcher(selected(true))
    }
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      fontsize: 8,
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
       color: theme.palette.primary.main
            },
    },
  }));

  return(
  <>
    <Helmet>
      <title> Sales Report| Nutriart Stores</title>
    </Helmet>
    <IdleTimeout />

      {   promiseInProgress &&
            <Backdrop style={{zIndex: 2}} open={open} >
              <Bars type="Watch" color='#862D23' height="100" width="100" />
            </Backdrop>
            }

         <Box sx={{pt:6,pl:1}}>

         <CardHeader title={'Seasonal sales'}
          action={(
              <Button
                id="thisYear"
                endIcon={<ArrowDropDownIcon />}
                size="small"
                variant="text"
                onClick={handleClick}
                >
                {'Seasons'}
              </Button>)}/>
              <StyledMenu
                open={!!menuPosition}
                onClose={() => setMenuPosition(null)}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
              >
                  <NestedMenuItem
                        label="2019"
                        parentMenuOpen={!!menuPosition}
                  >
                      {submenuItems.map((item,index) =>
                        <MenuItem key={index} value={item}
                        onClick={event => handleItemClick(event,years[0])}> {item} </MenuItem>)}
                  </NestedMenuItem>

                  <NestedMenuItem
                      label="2020"
                      value = {1}
                      parentMenuOpen={!!menuPosition}
                    >
                      {submenuItems.map((item,index) =>
                        <MenuItem key={index} value={item}
                        onClick={event => handleItemClick(event,years[1])}> {item} </MenuItem>)}
                    </NestedMenuItem>

                    <NestedMenuItem
                      label="2021"
                      value={2}
                      parentMenuOpen={!!menuPosition}
                    >
                      {submenuItems.map((item,index) =>
                        <MenuItem key={index} value={item}
                        onClick={event => handleItemClick(event,years[2])}> {item} </MenuItem>)}
                    </NestedMenuItem>
                    <NestedMenuItem
                      label="2022"
                      value={3}
                      parentMenuOpen={!!menuPosition}
                    >
                      {submenuItems.map((item,index) =>
                        <MenuItem key={index} value={item}
                        onClick={event => handleItemClick(event,years[3])}> {item} </MenuItem>)}
                    </NestedMenuItem>
                    <NestedMenuItem
                      label="2023"
                      value={4}
                      parentMenuOpen={!!menuPosition}
                    >
                      {submenuItems.map((item,index) =>
                        <MenuItem key={index} value={item}
                        onClick={event => handleItemClick(event,years[4])}> {item} </MenuItem>)}
                    </NestedMenuItem>
                    <NestedMenuItem
                      label="2024"
                      value={5}
                      parentMenuOpen={!!menuPosition}
                    >
                      {currentYearSubmenuItems.map((item,index) =>
                        <MenuItem key={index} value={item}
                        onClick={event => handleItemClick(event,years[5])}> {item} </MenuItem>)}
                    </NestedMenuItem>
                    <MenuItem key={years[4]} value={'Custom Dates'}
                        onClick={event => handleItemClick(event,years[5])}> {'Custom Dates'} </MenuItem>
              </StyledMenu>

          <Divider />
          <Box pl={6} pt={4} >

              <Dialog
                open={dateDialog.duration.showDurationDialog}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    width: "3000px",
                    maxHeight: 300
                  }
                }} >
              <DialogTitle> Season Dates</DialogTitle>
              <DialogContent>
              <DateComponent />
              </DialogContent>
              <DialogActions>
                <Button onClick={setSubmit}>Ok</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>


          <Grid container  spacing={2}>
            <Grid
              item
              md={3}
              xs={3}
              >

                <TextField
                    id="bs-sales"
                    label='Before Season Sales'
                    name="bsSales"
                    size="small"
                    value={rows.totals.amount_before}
                    />
              </Grid>
              <Grid
              item
              md={3}
              xs={3}
              >
              <TextField
                  id="bs-number"
                  label='Before Season Items'
                  name="bsNumber"
                  size="small"
                  value={rows.totals.count_before}
                  />
              </Grid>
              <Grid
              item
              md={3}
              xs={3}
              >
              <TextField
                  id="as-sales"
                  label='After Season Sales'
                  name="asSales"
                  size="small"
                  value={rows.totals.amount_after}
                  />
              </Grid>
              <Grid
              item
              md={3}
              xs={3}
              >
              <TextField
                  id="as-number"
                  label='After Season Items'
                  name="asNumber"
                  size="small"
                  value= {rows.totals.count_after}
                  />
              </Grid>
           </Grid>

        </Box >
        <Box sx={{pt:4}}>
          <Card >
          <CardContent>
            <EnhancedTable rows={rows.data} sendProductsToParent={sendProductsToParent}
                           columns={TableHeader} checkBoxEnabled = {true}
                           collapseKey= {'storeSales'} collapseHeader = {collapseHeader}/>
         </CardContent>
       </Card>
       </Box>
        </Box>

        <Box sx={{pt:6,pl:1,pr:1,pb:6}}>
        <Card>
        <CardContent>
          <Grid
            container
            spacing={2}
          >
          <Grid
              item
              md={2}
              xs={2}
            >
          <Typography sx={{pt:4,pb:4}}  variant='h5' color="black">
             Select Stores
          </Typography>
          </Grid>
          <Grid
              item
              md={7}
              xs={7}
            >
          <StoresDropDown componentType={'stores'} width={400}/>
          </Grid>
          <Grid
              item
              md={1}
              xs={1}
            >
              <Box sx={{pt:2}}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  </Box>
          </Grid>
          </Grid>
          </CardContent>
          </Card>
          { !clear &&
          <CardContent>
          <Grid
              item
              md={12}
              xs={12}
            >
          <Typography sx={{pt:2,pb:4}}  variant='h5' color="black">
             Number of Products Sold
          </Typography>
          </Grid>
        <Box
          sx={{
            height: 500,
            position: 'relative'
          }}
        >

          <Line
            data={result}
          />
        </Box>
        </CardContent>
}
        </Box>
  </>
)
    };

export default SeasonalProductSales;